import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { getAssetsRootURL } from '../utils'

import AuthContext from '../contexts/AuthContext'

import {
  Section,
  H1,
  Form,
  FormGroup,
  TextInput,
  Button,
  Link,
  SpinnerAnimatedIcon,
  LoginIcon
} from '../components'

const LoginContainer = styled(Section)`
  padding: 0 4%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Form} {
    width: 21.875rem;
    margin: 0 auto;
  }

  ${Link} {
    margin-top: .938rem;
  }

  .login-button-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StyledVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`

const Logo = styled.img`
  margin: 0 auto;
  margin-bottom: 2.5rem;
  width: 9.375rem;
  filter: invert(100%);
  user-select: none;
`

const StyledH1 = styled(H1)`
  letter-spacing: .563rem;
  text-transform: uppercase;
  font-size: 2.25rem;
  margin: 2rem 0;
  color: white;
  font-weight: normal;
`

const Error = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.errorRed};
`

const StyledLink = styled(Link)`
  margin: 0 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`

const LinkSection = styled.div`
  margin: 1.6rem;
  color: ${({ theme }) => theme.primaryColor};
`

const ErrorLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    cursor: pointer;
  }
`

// Source: https://emailregex.com/
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default ({ location }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [onError, setOnError] = useState(false)
  const [
    { loading, authenticated, error: onRequestError },
    { login }
  ] = useContext(AuthContext)
  const { from } = location.state || { from: { pathname: '/' } }

  const showError = () => {
    if (onError) {
      return (
        <Error>
          {password.length <= 0 ? <>Mot de passe requis<br /></> : null}
          {!email ? <>Email requis<br /></> : null}
          {!EMAIL_REGEX.test(email) ? <>Email format incorrect<br /></> : null}
        </Error>
      )
    } else if (
      onRequestError
      && onRequestError.status === 401
      && onRequestError.message === 'Not active'
    ) {
      return (
        <Error>
          Votre compte est créé mais pas encore actif. Veuillez vous rapprocher d'un administrateur via ce <ErrorLink target='_blank' href='https://neurocr.ai/contact'>formulaire de contact</ErrorLink>
        </Error>
      )
    } else if (onRequestError && onRequestError.status === 404) {
      return (
        <Error>
          Login et mot de passe incorrects.
        </Error>
      )
    } else if (onRequestError && onRequestError.status === 500) {
      return (
        <Error>
          Une erreur est survenue, réessayez plus tard ou contactez un administrateur via ce <ErrorLink target='_blank' href='https://neurocr.ai/contact'>formulaire de contact</ErrorLink>
        </Error>
      )
    } else if (onRequestError && onRequestError.status === 503) {
      return (
        <Error>
          L'application est en maintenance, nous faisons notre maximum pour rétablir le service au plus vite.
        </Error>
      )
    }

    return null
  }

  const handleSubmit = e => {
    setOnError(false)

    e.preventDefault()
    e.stopPropagation()

    if (password.lenght <= 0 || !email || !EMAIL_REGEX.test(email)) {
      setOnError(true)

      return false
    }

    login(email, password)
  }

  return !authenticated ? (
    <>
      <StyledVideo
        autoPlay
        loop
        muted
        id='background-video'
        poster={getAssetsRootURL(true) + '/images/video-poster.jpg'}
      >
        <source src={getAssetsRootURL(true) + '/videos/NeurOCR.mp4'} type='video/mp4' />
      </StyledVideo>
      <LoginContainer>
        <Logo style={{ filter: 'brightness(100%)', width: '20rem' }} src='images/neurOCR-logo.svg' />
        <StyledH1>Bienvenue !</StyledH1>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <TextInput
              required
              dark
              label='Email'
              name='email'
              onChange={setEmail}
              value={email}
              validator={v => !EMAIL_REGEX.test(v) ? 'Non valide' : null}
            />
          </FormGroup>
          <FormGroup>
            <TextInput
              required
              dark
              label='Mot de passe'
              name='password'
              type='password'
              onChange={setPassword}
              value={password}
            />
          </FormGroup>
          {showError()}
          <div className='login-button-container' style={{ textAlign: 'center', marginTop: '2rem' }}>
            <Button
              primary
              leftIcon={loading ? <SpinnerAnimatedIcon /> : <LoginIcon />}
              type='submit'
            >
              Connexion
            </Button>
            <LinkSection>
              <StyledLink to='/register'>Inscription</StyledLink>
              /
              <StyledLink to='/forgot-password'>Mot de passe oublié</StyledLink>
            </LinkSection>
          </div>
        </Form>
      </LoginContainer>
    </>
  ) : <Redirect to={from} />
}
