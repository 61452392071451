import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useList from '../hooks/useList'
import { makeTree, partition } from '../utils'
import {
  H4,
  Form,
  FormGroup,
  TextInput,
  Radio,
  Select,
  Option,
  Button,
  BlankButton,
  Tree,
  CloseCircleFilledIcon,
  Ul,
  Li,
  Checkbox
} from '../components'

import * as services from '../services'
import { getTemplatesList } from '../services/template'

const StyledHeadings = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 2rem;
`

const StyledSelect = styled(Select)`
  width: 100%;
  z-index: 1000;
  margin-top: 0.2rem;
`

const StyledH4 = styled(H4)`
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 0.2rem;
`

const CancelButton = styled(BlankButton)`
  color: ${({ theme }) => theme.dangerRed};
  font-size: .75rem;
`

const ResetButton = styled(BlankButton)`
  color: ${({ theme }) => theme.blue};
  font-size: .75rem;
`

const NodesPreview = styled(FormGroup)`
  text-align: left;

  height: 150px;
  overflow: scroll;
  padding: 1rem;
  font-family: Saira;
  font-weight: normal;
  font-size: .875rem;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
`

const StyledUl = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0 0 0 1rem;
`

const StyledNode = styled.li`
  ${({ selected }) => selected && `
    > ${BlankButton} { font-weight: bold; }
  `}
`

const NodeOptions = styled.div`
  display: inline-block;
`

const StyledDictionaryLi = styled(Li)`
  display: flex;
  flex-direction: column;

  button {
    color: #d60909;
  }

  div {
    justify-content: space-between;
  }
`

const StyledDictionaryLiName = styled.div`
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
`

const DictionaryOption = styled.div``

const Line = styled.div`
  border: 1px solid #d8d8d8;
  margin: 0.25rem 0;
`

const tmpId = () =>
  ('0000' + ((Math.random() * (100000 - 101) + 101) | 0)).slice(-5)

const exceptChildren = (parent, nodes) => {
  const children = onlyChildren(parent, nodes)
  return nodes.filter(n => !children.find(c => c.id == n.id))
}

const onlyChildren = (parent, nodes) => {
  const [_children, other] = partition(nodes, n => n.parent == parent.id)
  return [..._children, ..._children.map(child => onlyChildren(child, other))].flat(Infinity)
}

const ProfileForm = forwardRef(({ profile, onSubmit, onCancel }, ref) => {
  const [name, setName] = useState(profile.name)
  const [format, setFormat] = useState(profile.format)
  const [
    { items: nodes },
    {
      setItem: setNode,
      updateItem: updateNode,
      resetItems: resetNodes,
      deleteItem: deleteNode,
    }
  ] = useList()
  const [currentNode, setCurrentNode] = useState({})
  const [templateList, setTemplateList] = useState([]);
  const [deletedNodes, setDeletedNodes] = useState([])
  const [selectedDictionaryIds, setSelectedDictionaryIds] = useState([])
  const [replaceDictionariesSynonyms, setReplaceDictionariesSynonyms] = useState([])
  const [dictionaries, setDictionaries] = useState([])
  const [includeAllWords, setIncludeAllWords] = useState(false)

  useEffect(() => {
    services
      .fetchAllDictionaries()
      .then(values => {
        if (values.dictionaries) {
          setDictionaries(values.dictionaries)
        }
      });
    getTemplatesList().then((response) => {
      setTemplateList(response);
    })
  }, [])

  useEffect(() => {
    setName(profile.name)
    setFormat(profile.format)
    resetNodes(profile.nodes || [])
    setDeletedNodes([])
    setCurrentNode({})
    setIncludeAllWords(profile.includeAllDetections)
    setSelectedDictionaryIds(profile.dictionaries || [])
    setReplaceDictionariesSynonyms(profile.replaceDictionariesSynonyms || [])
  }, [profile])

  useEffect(() => {
    if (currentNode.name) {
      setSelectedDictionaryIds(currentNode.dictionaries || [])
      setReplaceDictionariesSynonyms(currentNode.replaceDictionariesSynonyms || [])
    }
  }, [currentNode])

  const handleClickCancel = () =>
    onCancel()

  const handleClickReset = () => {
    setName(profile.name)
    setFormat(profile.format)
    resetNodes(profile.nodes)
    setDeletedNodes([])
    setSelectedDictionaryIds([])
    setCurrentNode({})
  }

  const toggleCurrentNode = node => {
    setCurrentNode(currentNode.id !== node.id ? node : {})
  }

  const handleClickAddNode = () => {
    if (!currentNode.name) return

    if (currentNode.parent && ['txt', 'csv'].includes(format))
      setFormat('xml')

    let currentNodeToUpdate = currentNode;
    if (format != 'pdf' || format != 'svg') {
      currentNodeToUpdate = {
        ...currentNodeToUpdate,
        dictionaries: selectedDictionaryIds || [],
        replaceDictionariesSynonyms: replaceDictionariesSynonyms || [],
      }
    }

    setNode({ ...currentNodeToUpdate, id: tmpId() })
    setCurrentNode({})
  }

  const handleClickUpdateNode = () => {
    if (!currentNode.id) return

    if (currentNode.parent && ['txt', 'csv'].includes(format))
      setFormat('xml')

    let currentNodeToUpdate = currentNode;
    if (format != 'pdf' || format != 'svg') {
      currentNodeToUpdate = {
        ...currentNodeToUpdate,
        dictionaries: selectedDictionaryIds || [],
        replaceDictionariesSynonyms: replaceDictionariesSynonyms || [],
      }
    }
    updateNode(currentNode.id, { ...currentNodeToUpdate })
    setCurrentNode({})
    if (format != 'pdf' || format != 'svg') {
      setSelectedDictionaryIds([])
      setReplaceDictionariesSynonyms([])
    }
  }

  const handleClickDeleteNode = node => {
    setDeletedNodes([node.id, ...onlyChildren(node, nodes).map(n => n.id)])
    resetNodes(exceptChildren(node, nodes))
    deleteNode(node.id)
  }

  const handleSubmit = e => {
    e.preventDefault()
    let profilToSubmit = {
      ...profile,
      name,
      format,
      includeAllDetections: includeAllWords,
      nodes,
      _deleted: deletedNodes,
    }
    if (format === 'pdf' || format === 'svg') {
      profilToSubmit = {
        ...profile,
        name,
        format,
        includeAllDetections: includeAllWords,
        dictionaries: selectedDictionaryIds,
        replaceDictionariesSynonyms: replaceDictionariesSynonyms,
      }
    }
    onSubmit(profilToSubmit)
  }

  const renderNodes = _nodes => (
    <StyledUl>
      {_nodes.map(node => (
        <StyledNode
          key={`node-${node.id}`}
          selected={node.id === currentNode.id}
        >
          <BlankButton onClick={() => toggleCurrentNode(node)}>
            {node.name}
          </BlankButton>
          <NodeOptions>
            <BlankButton onClick={() => handleClickDeleteNode(node)}>
              <CloseCircleFilledIcon />
            </BlankButton>
          </NodeOptions>
          {node.children && renderNodes(node.children)}
        </StyledNode>
      ))}
    </StyledUl>
  )

  const nodeFullName = (node, nodes) => {
    const parent = node.parent && nodes.find(n => n.id == node.parent)
    return (parent ? nodeFullName(parent, nodes) + ' / ' : '') + node.name
  }

  const handleClickDeleteDictionary = dictionaryId => {
    setSelectedDictionaryIds(selectedDictionaryIds.filter(dictionary =>
      dictionary != dictionaryId
    ))
  }

  return (
    <Form onSubmit={handleSubmit}>
      <StyledHeadings>
        <StyledH4>
          {
            profile.id
              ? 'Modifier un profil'
              : 'Créer un profil'
          }
        </StyledH4>
        {
          profile.id
            ? <CancelButton onClick={handleClickCancel}>Annuler</CancelButton>
            : <ResetButton onClick={handleClickReset}>Réinitialiser</ResetButton>
        }
      </StyledHeadings>
      <div>
        <FormGroup>
          <TextInput ref={ref}
            label="Nom du profil"
            value={name}
            onChange={setName}
            required
          />
        </FormGroup>
        <FormGroup style={{ justifyContent: 'space-between' }}>
          {['txt', 'csv'].map(_format => (
            <Radio
              key={_format}
              name="format"
              value={_format}
              label={_format.toUpperCase()}
              checked={_format === format}
              onClick={() => setFormat(_format)}
              disabled={!!profile.id || nodes.some(n => n.parent)}
            />
          ))}
          {['xml', 'json'].map(_format => (
            <Radio
              key={_format}
              name="format"
              value={_format}
              label={_format.toUpperCase()}
              checked={_format === format}
              onClick={() => setFormat(_format)}
              disabled={!!profile.id}
            />
          ))}
          {['pdf', 'svg'].map(_format => (
            <Radio
              key={_format}
              name="format"
              value={_format}
              label={_format.toUpperCase()}
              checked={_format === format}
              onClick={() => setFormat(_format)}
              disabled={!!profile.id}
            />
          ))}
        </FormGroup>
        {
          (
            format && format != 'svg' && format != 'pdf'
            && (
              < FormGroup >
                <TextInput
                  label={currentNode.id ? "Modifier champ" : "Nouveau champ"}
                  value={currentNode.name}
                  onChange={name => setCurrentNode({ ...currentNode, name })}
                />
              </FormGroup>
            ) || (
              < FormGroup >
                <Checkbox
                  label="Inclure tout les mots"
                  checked={includeAllWords}
                  onChange={() => setIncludeAllWords(!includeAllWords)}
                />
              </FormGroup>
            )
          )
        }
        {
          format && (
            <>
              {(format != 'pdf' && format != 'svg') && !profile.nodes.find(node => currentNode.id === node.parent) && <FormGroup>
                <StyledH4>Regex:</StyledH4>
                <TextInput
                  label={
                    currentNode.id ?
                      "Modifier Regex" :
                      "Nouvelle Regex (Aucune par défaut)"
                  }
                  value={currentNode.regex}
                  onChange={regex => setCurrentNode({ ...currentNode, regex })}
                />
              </FormGroup>}
              {
                dictionaries.length > 0 && ((format === 'pdf' && format === 'svg') || !profile.nodes?.find(node => currentNode?.id === node.parent)) && (
                  <FormGroup>
                    <StyledH4>Dictionnaires:</StyledH4>
                    <StyledSelect
                      placeholder='Ajouter un dictionnaire'
                      onChange={id => {
                        setSelectedDictionaryIds([
                          ...selectedDictionaryIds,
                          id
                        ])
                      }}
                      filter={true}
                    >
                      {
                        dictionaries
                          .filter(
                            dictionary => !selectedDictionaryIds.includes(dictionary.id)
                          )
                          .map(
                            ({ id, name }) => (
                              <Option key={id} value={id}>
                                {name}
                              </Option>
                            )
                          )
                      }
                    </StyledSelect>
                    <StyledUl>
                      {
                        selectedDictionaryIds
                          .map(id => (
                            dictionaries
                              .find(dictionary => dictionary.id === id)
                          ))
                          .map((dictionary, index) => (
                            <StyledDictionaryLi key={index}>
                              <StyledDictionaryLiName>
                                {dictionary?.name || ''}
                                <BlankButton
                                  onClick={
                                    () => handleClickDeleteDictionary(dictionary.id)
                                  }
                                >
                                  <CloseCircleFilledIcon />
                                </BlankButton>
                              </StyledDictionaryLiName>
                              <DictionaryOption>
                                <Checkbox
                                  label="Remplacer les mots synonymes par leur mot de référence du dictionnaire"
                                  checked={replaceDictionariesSynonyms?.find((id) => id === dictionary.id)}
                                  onChange={(e) => {
                                    if (e) { setReplaceDictionariesSynonyms([...(replaceDictionariesSynonyms || []), dictionary.id]) } else {
                                      setReplaceDictionariesSynonyms(replaceDictionariesSynonyms.filter((id) => id != dictionary.id))
                                    }
                                  }}
                                />
                              </DictionaryOption>
                              <Line />
                            </StyledDictionaryLi>
                          ))
                      }
                    </StyledUl>

                  </FormGroup>
                )
              }
              {(format != 'pdf' && format != 'svg') && <>
                {(<>
                  {!profile.nodes.find(node => currentNode.id === node.parent) && <>
                    <StyledH4>Templates:</StyledH4>
                    <FormGroup>
                      <div style={{
                        width: '100%',
                        marginBottom: '1rem',
                        zIndex: '4'
                      }}>
                        <Select
                          style={{ width: '100%' }}
                          placeholder="Définir un template"
                          onChange={template => setCurrentNode({ ...currentNode, template: template })}
                        >
                          {
                            templateList.map(template =>
                              <Option
                                key={`template-${template.id}`}
                                value={template.id}
                                selected={currentNode && currentNode.template && template.id === currentNode.template}
                              >
                                {template.name}
                              </Option>
                            )
                          }
                        </Select>
                      </div>
                      <div style={{
                        width: '100%',
                        zIndex: '3'
                      }}>
                        <Select
                          style={{ width: '100%' }}
                          placeholder="Définir un champ du template"
                          onChange={zone => setCurrentNode({ ...currentNode, zone: zone })}
                          disabled={!currentNode.template}
                        >
                          {
                            currentNode.template && (templateList.find(template => template.id === currentNode.template).zones).map(zone =>
                              <Option
                                key={`template-${zone.id}`}
                                value={zone.id}
                                selected={currentNode && currentNode.zone && zone.id === currentNode.zone}
                              >
                                {zone.name}
                              </Option>
                            )
                          }
                        </Select>
                      </div>
                    </FormGroup>
                  </>}
                  {(format != 'pdf' && format != 'svg') && <>
                    <StyledH4>Arborescence:</StyledH4>
                    <FormGroup>
                      <Select
                        style={{ width: '100%', zIndex: '2' }}
                        placeholder="Définir parent (Aucun par défaut)"
                        onChange={parent => setCurrentNode({ ...currentNode, parent })}
                      >
                        {
                          (currentNode.id
                            ? exceptChildren(currentNode, nodes).filter(node => node.id != currentNode.id)
                            : nodes
                          ).filter(node => {
                            return !node.regex
                              && !node.template
                              && !node.zone
                              && (!node.replaceDictionariesSynonyms || node.replaceDictionariesSynonyms?.length === 0)
                              && (!node.dictionaries || node.dictionaries?.length === 0)
                          }).map(node =>
                            <Option
                              key={`node-${node.id}`}
                              value={node.id}
                              selected={node.id === currentNode.parent}
                            >
                              {nodeFullName(node, nodes)}
                            </Option>
                          )
                        }
                      </Select>
                    </FormGroup>
                  </>}
                </>)}
                <FormGroup>
                  {
                    currentNode.id
                      ? (
                        <Button
                          disabled={!currentNode.name || !currentNode.name.length}
                          primary
                          onClick={handleClickUpdateNode}
                          type="button"
                        >
                          Modifier
                        </Button>
                      ) : (
                        <Button
                          disabled={!currentNode.name || !currentNode.name.length}
                          primary
                          onClick={handleClickAddNode}
                          type="button"
                        >
                          Ajouter
                        </Button>
                      )
                  }
                </FormGroup>
                <NodesPreview>
                  <Tree>{renderNodes(makeTree(nodes))}</Tree>
                </NodesPreview>
              </>}
            </>
          )
        }

        <FormGroup>
          <Button
            primary
            disabled={!(((name?.length) && (nodes?.length)) || ((format === 'svg' || format === 'pdf') && (name?.length)))}
            type="submit"
          >
            {
              profile.id
                ? 'Modifier'
                : 'Créer'
            }
          </Button>
        </FormGroup>
      </div >
    </Form >
  )
})

ProfileForm.propTypes = {
  profile: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ProfileForm
