import axios from 'axios'

export const fetchAllProjects = params =>
  axios.get('/api/projects', { params }).then(({ data }) => data)

export const createProject = body =>
  axios.post('/api/projects', body).then(({ data }) => data)

export const updateProject = (id, body) =>
  axios.put(`/api/projects/${id}`, body).then(({ data }) => data)

export const deleteProject = id =>
  axios.delete(`/api/projects/${id}`).then(({ data }) => data)

export const reFuseProject = id =>
  axios.put(`/api/projects/${id}/refuse`).then(({ data }) => data)
