import React, { useEffect, useRef, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import {
  Icon,
  SpinnerAnimatedIcon,
  TextInput,
  Checkbox,
  Button
} from '../components'

import FabricContext from '../contexts/FabricContext'
import FabricCanvas from './FabricCanvas'
import utils from '../utils'

import * as serviceDetection from '../services/detections.js'

const ResponsiveWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;

  width: auto;
  height: auto;

  ${({ orientation, displayPanel }) => `
    top: ${orientation === 'vertical' ? '0' : '3rem'};
    left: ${displayPanel
      ? (orientation === 'vertical' ? '398px' : '350px')
      : (orientation === 'vertical' ? '3rem' : '0')
    };
  `}
`

const SelectionEditor = styled.div`
  position: absolute;
  ${({ editorCoords }) => `
    top: ${editorCoords.y + 10}px;
    left: ${editorCoords.x}px;
  `}
  border-radius: 5px;
`

const SelectionEditorElement = styled.div`
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid black;
`

const SelectionEditorElementNodes = styled(SelectionEditorElement)`
  border: 1px solid #d8d8d8;
  padding: 5px;
  max-height: 30vh;
  overflow: scroll;
`

const Loading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .05);

  ${Icon} {
    width: 3rem;
    height: 3rem;
  }
`

const PageInfo = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  font-size: .875rem;

  padding: .75rem;
  color: ${({ theme }) => theme.white};
  background-color: rgba(0, 0, 0, .75);
  border-radius: 5px;
`

const Meta = styled.div`
  margin-top: .5rem;
`

const DocumentScore = styled.span`
  margin-right: .5rem;
  padding: .25rem;
  border-radius: 5px;
  font-weight: bold;
`
// retiré car faisait planter l'explorateur à la fermeture quand un document était ouvert -> background-color: ${({ theme, value }) => theme.ocrScoreColor(value) };

const StyledTextInput = styled(TextInput)`

`

const CheckBoxTree = ({ deleteProfilNodeDetection, setDeleteProfilNodeDetection, selectedProfilesNodes, setSelectedProfilesNodes, node, profile, selectedDetection }) => {
  return <Checkbox
    key={
      'profil-' + profile.id +
      '-node-' + node.id +
      '-detection-' + selectedDetection.id
    }
    label={node.name}
    checked={
      selectedProfilesNodes?.findIndex(
        ({ profileId, nodeId, id }) => (
          profileId === profile.id &&
          nodeId === node.id &&
          selectedDetection.find(detection => id === detection.id)
        )
      ) > -1
    }
    onChange={
      selected => {
        if (selected) {
          setSelectedProfilesNodes([
            ...selectedProfilesNodes,
            ...selectedDetection.map(detection => {
              return {
                profileId: profile.id,
                nodeId: node.id,
                value: detection.value,
                id: detection.id,
                index: selectedProfilesNodes.length
              }
            })
          ].filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id &&
              t.nodeId === value.nodeId &&
              t.profileId === value.profileId
            ))
          ))
        } else {
          setSelectedProfilesNodes([
            ...selectedProfilesNodes.filter(
              ({ profileId, nodeId, id }) => !(
                profileId === profile.id &&
                nodeId === node.id &&
                selectedDetection.find(detection => id === detection.id)
              )
            )
          ])
          let profilNodeDetectionToDelete = selectedDetection.map(detection => {
            return { detectionId: detection.id, nodeId: node.id }
          })
          setDeleteProfilNodeDetection([...deleteProfilNodeDetection, ...profilNodeDetectionToDelete])
        }
      }
    }
  />
}

const Tree = ({ deleteProfilNodeDetection, setDeleteProfilNodeDetection, selectedProfilesNodes, setSelectedProfilesNodes, profile, selectedDetection, nodeList }) => {
  const [collapseTreeList, setCollapseTreeList] = React.useState([]);
  const toggleCollapseTreeList = (id, list) => {
    let result = list;
    if (list.find(item => id === item)) {
      result = list.filter((item) => item != id)
    } else {
      list.push(id); result = list;
    }
    return result;
  }
  let result = nodeList?.map((node, index) => {
    return <React.Fragment key={`${node.name}${index}`}>{
      node.children && node.children.length > 0 ?
        <ul style={{ paddingLeft: '1rem', margin: '0' }}>
          <FontAwesomeIcon
            style={{
              transform: collapseTreeList.find(item => node.id === item) ? ['rotate(180deg)'] : ['none'],
              transitionDuration: '100ms',
              cursor: 'pointer'
            }}
            onClick={() => setCollapseTreeList([...toggleCollapseTreeList(node.id, collapseTreeList)])}
            icon={faAngleUp} />{" "}
          {node.name}
          {collapseTreeList.find(item => node.id === item) && <Tree {...{ deleteProfilNodeDetection, setDeleteProfilNodeDetection, selectedProfilesNodes, setSelectedProfilesNodes, profile, selectedDetection, nodeList: node.children }} />}
        </ul> :
        <li style={{
          listStyle: 'none',
          width: 'fit-content',
          margin: '0'
        }}>{
            <CheckBoxTree {...{ deleteProfilNodeDetection, setDeleteProfilNodeDetection, selectedProfilesNodes, setSelectedProfilesNodes, node, profile, selectedDetection }} />
          }</li>
    }</React.Fragment >
  });
  return result || '';
}

export const OCRCanvas = ({
  loading,
  orientation,
  displayPanel,
  currentDocument,
  currentPage,
  selectedDetection,
  profile,
  setSelectedProfilesNodes,
  selectedProfilesNodes,
  handleDetectionValueChange,
  FetchAndDrawDetections,
  setDisplayPanelEditor,
  displayPanelEditor,
  deleteProfilNodeDetection,
  setDeleteProfilNodeDetection,
  selectNextDetection,
  ...props
}) => {
  const wrapper = useRef(null)
  const [{ canvas }] = useContext(FabricContext);
  const [focus, setFocus] = useState(true);

  const handleResize = () => {
    canvas.setDimensions({
      width: wrapper.current.offsetWidth,
      height: wrapper.current.offsetHeight,
    })
  }

  useEffect(() => {
    if (!canvas) return

    handleResize()
  }, [canvas, orientation, displayPanel])

  return (
    <ResponsiveWrapper
      ref={wrapper}
      orientation={orientation}
      displayPanel={displayPanel}
      {...props}
    >
      <div style={{
        zIndex: '1',
        position: 'absolute',
      }}>{currentPage && currentDocument && <strong>{currentDocument?.name} - Page {currentPage?.index}</strong>}</div>
      {currentPage && currentDocument && !displayPanelEditor && <Button style={{
        marginLeft: 'auto',
        rotate: '270deg',
        position: 'absolute',
        zIndex: '1',
        right: '-3rem',
        top: '3rem',
        marginTop: '3rem',
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0',
      }} onClick={() => setDisplayPanelEditor(true)}>Profil d'éxport</Button>}
      <FabricCanvas />
      {
        !displayPanel && currentDocument && currentPage &&
        <PageInfo>
          <strong>{currentDocument.name}</strong>
          <Meta>
            <DocumentScore value={currentDocument.ocrScore}>{currentDocument.ocrScore}%</DocumentScore>
            <span>{currentPage.ocrScore}% · Page {currentPage.index}</span>
          </Meta>
        </PageInfo>
      }
      {
        selectedDetection && selectedDetection.length > 0 && (
          <>
            <SelectionEditor
              editorCoords={{ x: selectedDetection[0].x, y: selectedDetection[0].y }}
            >
              {selectedDetection.length === 1 && <SelectionEditorElement>
                <StyledTextInput
                  value={(selectedDetection[0].value)}
                  buttonIcon={() => <FontAwesomeIcon icon={faCheck} />}
                  focus={focus}
                  onClick={(value) => {
                    handleDetectionValueChange([{
                      ...selectedDetection[0], ...{
                        value: value,
                      }
                    }])
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const listDetections = canvas.getObjects().filter(objectParam => {
                        return objectParam.get("type") === 'rect'
                      }).sort((a, b) => a.top - b.top)
                      const indexItemInListDetection = listDetections.findIndex(objectParam => objectParam.detection.id === selectedDetection[0]?.id);

                      handleDetectionValueChange([{
                        ...selectedDetection[0], ...{
                          value: e.target.value,
                        }
                      }], indexItemInListDetection)
                    }
                  }}
                />
              </SelectionEditorElement>}
              {
                profile && (
                  <SelectionEditorElementNodes>
                    <Tree {...{ deleteProfilNodeDetection, setDeleteProfilNodeDetection, selectedProfilesNodes, setSelectedProfilesNodes, profile, selectedDetection, nodeList: utils.makeTree(profile.nodes) }} />
                  </SelectionEditorElementNodes>
                )
              }
            </SelectionEditor>
          </>
        )
      }
      {
        loading &&
        <Loading>
          <SpinnerAnimatedIcon />
        </Loading>
      }
    </ResponsiveWrapper >
  )
}


OCRCanvas.propTypes = {
  loading: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  displayPanel: PropTypes.bool.isRequired,
  currentDocument: PropTypes.object,
  currentPage: PropTypes.object,
}

OCRCanvas.defaultProps = {
  currentDocument: null,
  currentPage: null,
}

export default OCRCanvas
