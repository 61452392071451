import axios from 'axios'

export const fetchAllDictionaries = params =>
  axios.get('/api/dictionaries', { params }).then(({ data }) => data)

export const createDictionary = body =>
  axios.post('/api/dictionaries', body).then(({ data }) => data)

export const updateDictionary = (id, body) =>
  axios.put(`/api/dictionaries/${id}`, body).then(({ data }) => data)

export const deleteDictionary = id =>
  axios.delete(`/api/dictionaries/${id}`).then(({ data }) => data)

export const createCSVDictionary = data =>
  axios
    .post(
      '/api/dictionaries/upload',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    .then(({ data }) => data)

export const downloadCSVDictionary = (id, dictionaryName) =>
  axios({
    url: `/api/dictionaries/${id}/download`,
    method: 'GET',
    responseType: 'blob'
  })
  .then(res => {
    console.log(res.data)

    const url = window.URL.createObjectURL(new Blob([res.data]))

    const link = document.createElement('a')

    link.href = url

    link.setAttribute('download', `${dictionaryName}.csv`)

    document.body.appendChild(link)

    link.click()

    link.parentNode.removeChild(link)
  })
