import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'

import {
  BlankButton,
  Icon,
  FolderEyeIcon,
  EditIcon,
  SelectIcon,
  MoveIcon,
  FitIcon,
  ZoomInIcon,
  ZoomOutIcon,
  PageUpIcon,
  PageDownIcon,
  FontIcon,
  OcrCheckIcon,
  OcrScoreHighIcon,
  OcrScoreMiddleIcon,
  OcrScoreLowIcon,
  PreviewIcon,
  GearsIcon,
  InfoIcon,
} from '../components'

const CTA = styled(BlankButton)`
  display: block;
  line-height: 0;
  color: ${({ theme }) => theme.gray3};

  ${Icon} {
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    color: ${({ theme }) => theme.white};
  }

  ${({ disabled, theme }) => disabled && `
    &:hover {
      cursor: not-allowed;
      color: ${theme.gray3};
    }
  `}

  ${({ active, disabled, color, theme }) => active && !disabled && `
    &, &:hover {
      color: ${color || theme.primaryColor};
    }
  `}
`

const Tooltip = styled.div`
  display: flex;
  align-items: center;
  min-width: 300px;
  padding: .5rem 1rem;
  border-radius: 5px;
  color: ${({ theme }) => theme.white};
  background-color ${({ theme }) => theme.black};
  line-height: 1.5rem;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid black;
    top: 50%;
    left: -8px;
    margin-top: -8px;
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }
`

const ToolIcon = styled.span`
  display: inline-block;
  width: 2rem;
  height: 2rem;

  ${Icon} {
    width: 2rem;
    height: 2rem;
    color: ${({ theme }) => theme.white};
  }
`

const Hr = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.gray4};
  border-right: 1px solid ${({ theme }) => theme.gray4};
`

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Li = styled.li`
  position: static;

  ${Tooltip} {
    position: absolute;
    z-index: 1;
    left: 3.5rem;
    margin-top: -1rem;
    visibility: hidden;
    opacity: 0;
    transition: .25s all ease;
    transition-delay: .25s;
  }

  ${CTA}:hover + ${Tooltip} {
    visibility: visible;
    opacity: 1;
    transition: .25s all ease;
    transition-delay: .5s;
  }
`

const StyledToolbar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: .5rem;
  line-height: 0;

  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};

  ${Li} { margin: .5rem 0; }
  ${Hr} { margin: .75rem .25rem; }

  ${({ orientation }) => orientation === 'horizontal' && `
    bottom: inherit;
    left: 0;
    right: 0;

    ${Li} {
      display: inline-block;
      margin: 0 .25rem;
    }

    ${Hr} {
      display: inline-block;
      width: 0;
      height: 1.5rem;
      margin: .25rem .75rem;
    }

    ${Tooltip} {
      top: 4.5rem;
      left: -.5rem;
      margin-top: 0;

      &:before {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid black;
        border-top: none;
        left: 1rem;
        top: -.5rem;
        margin-top: 0;
      }
    }
  `}
`

const Toolbar = ({
  id,
  canvasMode,
  orientation,
  displayExplorer,
  displaySettings,
  displayZones,
  displayCorrected,
  displayHighScore,
  displayLowScore,
  displayLowScoreFushia,
  displayMidScore,
  visibleValue,
  onClickExplorer,
  onClickSettings,
  onClickEdit,
  onClickSelect,
  onClickNaviguate,
  onClickFit,
  onClickZoomIn,
  onClickZoomOut,
  onClickPrevPage,
  onClickNextPage,
  onClickDisplayZones,
  onClickDisplayCorrected,
  onClickDisplayHighScore,
  onClickDisplayMidScore,
  onClickDisplayLowScore,
  onClickDisplayLowScoreFushia,
  onClickVisibleValue,
}) => {
  const theme = useContext(ThemeContext)

  const getOffset = (el) => {
    const box = el.getBoundingClientRect()

    return {
      top: box.top + window.pageYOffset - document.documentElement.clientTop,
      left: box.left + window.pageXOffset - document.documentElement.clientLeft
    }
  }

  const posTooltip = e => {
    const cta = e.currentTarget,
      tooltip = e.currentTarget.nextSibling

    tooltip.style.top = getOffset(cta).top - 55 + 'px'
  }

  return (
    <StyledToolbar id={id} orientation={orientation}>
      <Ul>
        <Li>
          <CTA
            active={displayExplorer}
            onClick={onClickExplorer}
          >
            <FolderEyeIcon />
          </CTA>
        </Li>
        <Li><Hr /></Li>
        <Li>
          <CTA
            active={canvasMode === 'select'}
            onClick={onClickSelect}
            onMouseOver={posTooltip}
          >
            <SelectIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><SelectIcon /></ToolIcon>
            <div>
              <strong>Sélectionner</strong>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            active={canvasMode === 'nav'}
            onClick={onClickNaviguate}
            onMouseOver={posTooltip}
          >
            <MoveIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><MoveIcon /></ToolIcon>
            <div>
              <strong>Naviguer</strong>
              <span>(Clic droit OU Alt + clic gauche)</span>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            active={canvasMode === 'edit'}
            onClick={onClickEdit}
            onMouseOver={posTooltip}
          >
            <EditIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><EditIcon /></ToolIcon>
            <div>
              <strong>Dessiner</strong>
              <span>(clic gauche)</span>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            onClick={onClickFit}
            onMouseOver={posTooltip}
          >
            <FitIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><FitIcon /></ToolIcon>
            <div>
              <strong>Ajuster le zoom</strong>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            onClick={onClickZoomIn}
            onMouseOver={posTooltip}
          >
            <ZoomInIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><ZoomInIcon /></ToolIcon>
            <div>
              <strong>Zoom +</strong>
              <span>(Molette haut)</span>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            onClick={onClickZoomOut}
            onMouseOver={posTooltip}
          >
            <ZoomOutIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><ZoomOutIcon /></ToolIcon>
            <div>
              <strong>Zoom -</strong>
              <span>(Molette bas)</span>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            onClick={onClickPrevPage}
            onMouseOver={posTooltip}
          >
            <PageUpIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><PageUpIcon /></ToolIcon>
            <div>
              <strong>Page précédente</strong>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            onClick={onClickNextPage}
            onMouseOver={posTooltip}
          >
            <PageDownIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><PageDownIcon /></ToolIcon>
            <div>
              <strong>Page suivante</strong>
            </div>
          </Tooltip>
        </Li>
        <Li><Hr /></Li>
        <Li>
          <CTA
            active={visibleValue}
            onClick={onClickVisibleValue}
            onMouseOver={posTooltip}
          >
            <InfoIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><InfoIcon /></ToolIcon>
            <div>
              <strong>Afficher / masquer les valeurs des zones de texte</strong>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            active={displayZones}
            onClick={onClickDisplayZones}
            onMouseOver={posTooltip}
          >
            <FontIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><FontIcon /></ToolIcon>
            <div>
              <strong>Afficher / masquer les zones de texte</strong>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            active={displayCorrected}
            disabled={!displayZones}
            onClick={onClickDisplayCorrected}
            onMouseOver={posTooltip}
          >
            <OcrCheckIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><OcrCheckIcon /></ToolIcon>
            <div>
              <strong>Afficher / masquer les zones corrigées</strong>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            active={displayHighScore}
            disabled={!displayZones}
            onClick={onClickDisplayHighScore}
            color={theme.blue5}
            onMouseOver={posTooltip}
          >
            <OcrScoreHighIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><OcrScoreHighIcon /></ToolIcon>
            <div>
              <strong>Afficher / masquer les zones océrisées avec qualité {">"} 75%</strong>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            active={displayMidScore}
            disabled={!displayZones}
            onClick={onClickDisplayMidScore}
            color={theme.orange}
            onMouseOver={posTooltip}
          >
            <OcrScoreMiddleIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><OcrScoreMiddleIcon /></ToolIcon>
            <div>
              <strong>Afficher / masquer les zones océrisées avec qualité entre 50% et 75%</strong>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            active={displayLowScoreFushia}
            disabled={!displayZones}
            onClick={onClickDisplayLowScoreFushia}
            color={theme.fushia}
            onMouseOver={posTooltip}
          >
            <OcrScoreLowIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><OcrScoreLowIcon /></ToolIcon>
            <div>
              <strong>Afficher / masquer les zones océrisées fusionnées</strong>
            </div>
          </Tooltip>
        </Li>
        <Li>
          <CTA
            active={displayLowScore}
            disabled={!displayZones}
            onClick={onClickDisplayLowScore}
            color={theme.red}
            onMouseOver={posTooltip}
          >
            <OcrScoreLowIcon />
          </CTA>
          <Tooltip>
            <ToolIcon><OcrScoreLowIcon /></ToolIcon>
            <div>
              <strong>Afficher / masquer les zones océrisées avec qualité {"<"} 50%</strong>
            </div>
          </Tooltip>
        </Li>
        <Li><Hr /></Li>
        <Li><CTA disabled><PreviewIcon /></CTA></Li>
        <Li><CTA active={displaySettings} onClick={onClickSettings}><GearsIcon /></CTA></Li>
      </Ul>
    </StyledToolbar>
  )
}

Toolbar.propTypes = {
  canvasMode: PropTypes.oneOf(['select', 'edit', 'nav']).isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  displayExplorer: PropTypes.bool.isRequired,
  displayZones: PropTypes.bool.isRequired,
  displayCorrected: PropTypes.bool.isRequired,
  displayHighScore: PropTypes.bool.isRequired,
  displayMidScore: PropTypes.bool.isRequired,
  displayLowScore: PropTypes.bool.isRequired,
  onClickOrientation: PropTypes.func.isRequired,
  onClickExplorer: PropTypes.func.isRequired,
  onClickSelect: PropTypes.func.isRequired,
  onClickNaviguate: PropTypes.func.isRequired,
  onClickZoomIn: PropTypes.func.isRequired,
  onClickZoomOut: PropTypes.func.isRequired,
  onClickDisplayZones: PropTypes.func.isRequired,
  onClickDisplayCorrected: PropTypes.func.isRequired,
  onClickDisplayHighScore: PropTypes.func.isRequired,
  onClickDisplayMidScore: PropTypes.func.isRequired,
  onClickDisplayLowScore: PropTypes.func.isRequired,
}

export default Toolbar
