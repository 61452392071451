// Inspired by https://github.com/saninmersion/react-context-fabricjs

import React, { useCallback, createContext, useState } from 'react'
import { fabric } from 'fabric'

const context = createContext([])
const { Provider } = context

const cursors = {
  select: 'default',
  nav: 'move',
  edit: 'crosshair'
}

export const FabricProvider = ({ children }) => {
  const [canvas, setCanvas] = useState(null)
  const [activeObject, _setActiveObject] = useState(null)
  const [canvasMode, _setCanvasMode] = useState('select') // ['select', 'nav', 'edit']

  const initCanvas = useCallback((el, options = {}) => {
    const canvasOptions = {
      // preserveObjectStacking: true,
      selection: canvasMode === 'select',
      defaultCursor: 'default',
      hoverCursor: 'move',
      fireRightClick: true,
      stopContextMenu: true,
      ...options,
      backgroundColor: 'transparent',
    }
    let c = new fabric.Canvas(el, canvasOptions);
    c.renderTop();
    c.renderAll()
    setCanvas(c)
  }, [])

  const setActiveObject = object => {
    if (canvas) canvas.setActiveObject(object)
    _setActiveObject(object)
  }

  const setCanvasMode = mode => {
    if (canvas) canvas.selection = mode === 'select'
    canvas.set({ defaultCursor: cursors[mode] || 'default' })
    _setCanvasMode(mode)
  }

  const resetCanvas = mode => {
    if (canvas) canvas.clear()
    setCanvasMode(mode)
  }

  return (
    <Provider value={[
      { canvas, activeObject, canvasMode },
      { initCanvas, setActiveObject, setCanvasMode, resetCanvas }
    ]}>
      {children}
    </Provider>
  )
}

export default context
