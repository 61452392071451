import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import strftime from 'strftime'
import * as services from '../services'

import {
  H4,
  P,
  BlankButton,
  Icon,
  FileIcon,
  CrossIcon,
  TriangleRightIcon,
  SpinnerAnimatedIcon,
  Select,
  Option,
} from '../components'

import QuerySearchInput from '../modules/QuerySearchInput'
import { fetchDocument } from '../services'

const CloseButton = styled(BlankButton)`
  position: absolute;
  right: .75rem;
  color: ${({ theme }) => theme.primaryColor};

  ${Icon} {
    width: .75rem;
    height: .75rem;
  }
`

const StyledExplorer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  width: 350px;
  height: 100%;
  padding: .75rem .75rem .75rem 1.5rem;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.black};

  ${({ active }) => !active && `
    display: none;
  `}

  ${({ orientation }) => `
    top: ${orientation === 'vertical' ? '0' : '3rem'};
    left: ${orientation === 'vertical' ? '3rem' : '0'};
  `}

    &:hover {
  width: auto;
  }
`

const StyledH4 = styled(H4)`
  padding: 0 .5rem;
  margin-bottom: 1rem;
`

const StyledSelect = styled(Select)`
  width: 100%;
  margin: 1rem 0;
`

const BlankUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

const Loading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  ${Icon} {
    width: 1.5rem;
    height: 1.5rem;
  }

  > ${P} {
    margin: 2rem auto;
    font-style: italic;
  }
`

const Projects = styled(BlankUl)`
  padding-left: 1rem;
  list-style: none;
  flex: 1;
  overflow: scroll;
`

const StyledProject = styled.li``

const ProjectToggler = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: .5rem;
  cursor: pointer;

  > div {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > ${Icon} {
    margin-right: 1rem;
    ${({ collapsed }) => !collapsed && `transform: rotate(90deg);`}
  }
`
const ProjectName = styled.strong`
  display: block;
  margin-bottom: .25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 .25rem;
  background-color: ${({ theme, active }) => active ? theme.red : 'transparent'};
`
const ProjectInfos = styled.div`
  font-size: .75rem;
  color: ${({ theme }) => theme.dustyGray};
`

const Documents = styled(BlankUl)`
  padding-left: 1rem;

  ${({ collapsed }) => collapsed && `
    display: none;
  `}
`

const StyledDocument = styled.li`
  margin-bottom: 1rem;
`

const DocumentToggler = styled(ProjectToggler)`
  > ${Icon} { width: .75rem; height: .75rem; }
`

const DocumentName = styled(ProjectName).attrs({
  as: 'span'
})`
  padding: 0 .25rem;
  background-color: ${({ theme, active }) => active ? theme.red : 'transparent'};
`

const DocumentInfos = styled.div`
  font-size: .75rem;
  color: ${({ theme }) => theme.dustyGray};
`

const Pages = styled(Documents)`
  padding-left: 1.75rem;
`

const Page = styled.li`
  padding: 0.25rem;
  padding-bottom: 0;
  padding-left: 0.5rem;
  background-color: ${({ theme, active, load }) => active && !load ? theme.red : 'transparent'};
  font-weight: ${({ active, load }) => active && !load ? 'bold' : 'normal'};
`

const PageLink = styled(BlankButton)`
  color: ${({ theme }) => theme.white};
  font-size: .85rem;
`

const Score = styled.span`
  margin-right: .5rem;
  padding: .25rem;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme, value }) => theme.scoreColor(value / 100)};
  border-radius: 4px;
`

const PageList = ({ currentPage, project, document, page, index, onClickPage }) => {

  const [counter, setCounter] = React.useState(50);
  const [currentDocumentLoaded, setCurrentDocumentLoaded] = React.useState(null);
  const [currentDocumentReloading, setCurrentDocumentReloading] = React.useState(false);

  React.useEffect(() => {
    if (page.fuseStatus != "done"
      && (currentDocumentLoaded === null
        || currentDocumentLoaded?.pages[index].fuseStatus != "done")
      && currentDocumentReloading === false
      && counter > 0) {
      const timer = setTimeout(() => {
        setCurrentDocumentReloading(true)
        fetchDocument(document.id).then((response => {
          if (response.document.pages[index].fuseStatus === "done") {
            setCounter(0)
            setCurrentDocumentReloading(false)
            setCurrentDocumentLoaded({ ...response.document })
          } else {
            setCurrentDocumentReloading(false)
            setCounter(counter - 1)
          }
        }))
      }, 1000);
      return () => clearTimeout(timer);
    };
  }, [counter, currentDocumentLoaded, currentDocumentReloading]);

  return <>
    {
      <Page key={`page-${page.id}`}
        active={currentPage
          && currentPage.id == page.id
          && (currentDocumentLoaded?.pages[index].fuseStatus === "done" || page.fuseStatus === "done")}
        load={currentDocumentLoaded?.pages[index].fuseStatus != "done" && page.fuseStatus != "done"} >

        <PageLink onClick={e => {
          if (currentDocumentLoaded?.pages[index].fuseStatus === "done" || page.fuseStatus === "done") {
            onClickPage(project, currentDocumentLoaded || document, currentDocumentLoaded?.pages[index] || page, e);
          } else {
            fetchDocument(document.id).then((response => {
              if (response.document.pages[index].fuseStatus === "done") {
                setCounter(0)
                setCurrentDocumentLoaded({ ...response.document })
              }
            }));
          }
        }}>
          {page.fuseStatus != "done" && (currentDocumentLoaded?.pages[index].fuseStatus != "done" || currentDocumentLoaded?.pages[index].fuseStatus == null) && <SpinnerAnimatedIcon />}{' '}
          <FileIcon />
          {Math.round((currentDocumentLoaded?.pages[index] || page).score)}% - Page {(currentDocumentLoaded?.pages[index] || page).index}
        </PageLink>
      </Page>
    }
  </>
}

const Document = ({ currentPage, project, document, onClickPage }) => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <StyledDocument>
      <DocumentToggler onClick={() => setCollapsed(!collapsed)} collapsed={collapsed}>
        <TriangleRightIcon />
        <div>
          <DocumentName active={currentPage && document && document.pages.find(page => page.id === currentPage.id)}>{document.name}</DocumentName>
          <DocumentInfos>
            <Score value={document.score}>{Math.round(document.score)}%</Score>
            {strftime('%d/%m/%y', new Date(document.createdAt))}
            {' Nombres de page : ' + document.pages.length}
          </DocumentInfos>
        </div>
      </DocumentToggler>
      <Pages collapsed={collapsed}>
        {document.pages.map((page, index) => <PageList key={`${page.id}_${index}`} {...{ currentPage, project, document, page, index, onClickPage }}></PageList>)}
      </Pages>
    </StyledDocument >
  )
}

const Project = ({ currentPage, project, onClickPage, order }) => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <StyledProject key={`project-${project.id}`}>
      {
        project.id &&
        <>
          <ProjectToggler
            onClick={() => setCollapsed(!collapsed)}
            collapsed={collapsed}
          >
            <TriangleRightIcon />
            <div>
              <ProjectName active={project && currentPage && project.documents?.find((document) => {
                return document.id === currentPage.document
              })}>
                {project.name || 'Non définis'}
              </ProjectName>
              <ProjectInfos>
                <Score>
                  {project.documents && project.documents.length > 0 && Math.round(project.documents?.reduce((v, d) => v + d.score, 0) / project.documents?.length) + '%'}
                </Score>
                {project.nbDocuments} documents
              </ProjectInfos>
            </div>
          </ProjectToggler>
        </>
      }
      {<DocumentsExplorer {...{ collapsed, currentPage, project, onClickPage, order, setCollapsed }} />}
    </StyledProject>
  )
}

const DocumentsExplorer = ({ collapsed, currentPage, project, onClickPage, order, setCollapsed }) => {
  const [loading, setLoading] = useState(false)

  React.useEffect(() => {
    setCollapsed(!(project.documents && project.documents.length > 0))
  }, [])

  React.useEffect(() => {
    if (!collapsed && project.documents === undefined && !loading) {
      setLoading(true)
      services.fetchDocumentByAProject(project.id).then((response) => {
        const documentsOfProject = response.documents
        documentsOfProject.map(documentItem => {
          return { ...documentItem, project: null }
        })
        project.documents = documentsOfProject;
        setLoading(false)
      })
      if (collapsed) {
        project.documents = undefined
      }
    }
  }, [collapsed])

  return <Documents collapsed={collapsed}>
    {project.documents === undefined && loading && 'Chargement...'}
    {
      !loading && (project.documents && project.documents.length > 0 ? project.documents
        .sort((a, b) => a[order] > b[order] ? -1 : 1) : []).map((document, index) =>
          <Document
            active={currentPage?.document === document?.id}
            key={`document-${document.id}-${index}`}
            currentPage={currentPage}
            project={project}
            document={document}
            onClickPage={onClickPage}
          />
        )
    }
  </Documents>
}

const OCRExplorer = ({
  id,
  loading,
  active,
  orientation,
  projects,
  currentPage,
  onClickPage,
  onClickClose,
  setProjects
}) => {
  const [query, setQuery] = useState({})
  const [order, setOrder] = useState('name')

  const _projects = projects.map(project => {
    const ret = query.name
      ? project.name?.toLowerCase().includes(query.name.toLowerCase())
      : true

    if (ret) return project

    const _documents = project.documents?.filter(document =>
      document.name.toLowerCase().includes(query.name.toLowerCase())) || []

    return ({ ...project, documents: _documents })
  })

  return (
    <StyledExplorer id={id}
      active={active}
      orientation={orientation}
    >
      <StyledH4>Explorateur</StyledH4>
      <QuerySearchInput onChange={setQuery} help={false} />
      <StyledSelect dark placeholder="Trier par ..." onChange={setOrder}>
        <Option value="name" selected={order === 'name'}>Trier par Nom</Option>
        <Option value="score" selected={order === 'score'}>Trier par Score</Option>
      </StyledSelect>
      {
        loading
          ? <Loading>
            <SpinnerAnimatedIcon />
            <P>Chargement des projets en cours</P>
          </Loading>
          : <Projects>
            {console.log('projects : ',
              _projects
            )}
            {_projects
              .sort((a, b) => {
                if (order === 'score' && a.documents && b.documents) {
                  let scoreA = a.documents.reduce((v, d) => v + d.score, 0) / a.documents.length
                  let scoreB = b.documents.reduce((v, d) => v + d.score, 0) / b.documents.length
                  return scoreA - scoreB
                }

                return a[order] < b[order] ? -1 : 1
              })
              .map((project, index) =>
                <Project
                  key={`project-${project.id}-${index}`}
                  currentPage={currentPage}
                  project={project}
                  onClickPage={onClickPage}
                  order={order}
                  setProjects={setProjects}
                />
              )}
          </Projects>
      }
    </StyledExplorer>
  )
}

OCRExplorer.propTypes = {
  loading: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickPage: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
  currentPage: PropTypes.object,
}

OCRExplorer.defaultProps = {
  currentPage: null,
}

export default OCRExplorer