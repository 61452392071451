import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'

import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavbarCollapse,
  NavbarNav,
  NavItem,
  NavLink,
  BurgerMenuIcon
} from '../components'

import Login from './Login'

const StyledBurgerMenuIcon = styled(BurgerMenuIcon)`
  color: ${({ theme }) => theme.primaryColor};
  width: 58px;
  height: 14px;
`

export default () => {
  const [{ loading, authenticated }] = useContext(AuthContext)
  const [collapsed, setCollapsed] = useState(true)
  const el = useRef(null)
  const location = useLocation()
  let isOnManage = false

  const handleClick = e => {
    if (el.current && el.current.contains(e.target))
      setCollapsed(!collapsed)
    else
      setCollapsed(true)
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () =>
      document.removeEventListener('click', handleClick, true);
  }, [collapsed])

  if (location.pathname.startsWith('/manage'))
    isOnManage = true

  return (
    (loading || !authenticated)
      ? null
      : (
        <Navbar>
          <NavbarBrand to="/home">
            <img src="/images/logo.svg" width="112px" height="40px" />
          </NavbarBrand>
          <NavbarToggler ref={el}>
            <StyledBurgerMenuIcon />
          </NavbarToggler>
          <NavbarCollapse collapsed={collapsed}>
            <NavbarNav>
              <NavItem>
                <NavLink to="/import">Importer</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/manage/projects"
                  className={isOnManage ? 'active' : null}
                >
                  Gérer
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/ocr">Qualifier</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/export">Exporter</NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink to="/cor">Corréler</NavLink>
              </NavItem> */}
            </NavbarNav>
            <Login />
          </NavbarCollapse>
        </Navbar>
      )
  )
}
