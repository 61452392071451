import React, { useContext } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'

import {
  NavLink,
  Link,
  Text,
  Icon,
  AccountIcon,
  HelpCircleFilledIcon,
  SubNavToggler,
  NavItem,
  SubNav,
  SubNavItem,
  SubNavLink,
  NavbarNav
} from '../components'

const StyledLogin = styled.div`
  display: flex;
`

const Status = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: .5rem;
`

const Name = styled.span`
  ${Text}
  color: #fff;
  line-height: 1;
  font-weight: bold;
`

const StyledLink = styled(Link)`
  font-size: .6875rem;
`

const ButtonIcon = styled.button`
  background: transparent;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  line-height: 0;
  padding: .5rem;
  display: flex;
  align-items: center;

  &:hover, &:active, &:focus {
    outline: none;
  }

  ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

export default () => {
  const [{ user }] = useContext(AuthContext)
  const location = useLocation()

  return user ?
    <NavbarNav>
      <NavItem>
        <SubNavToggler active={true}>
          <StyledLogin>
            <Status>
              <Name>{user.firstname} {user.lastname}</Name>
              <StyledLink to="/logout">Déconnexion</StyledLink>
            </Status>
            <ButtonIcon
              isActive={() => location.pathname.startsWith('/account')}
              as={NavLink}
              to='/account/me'
            >
              <AccountIcon />
            </ButtonIcon>
            <ButtonIcon
              isActive={() => location.pathname.startsWith('/help')}
              as={NavLink}
              to='/help'
            >
              <HelpCircleFilledIcon />
            </ButtonIcon>
          </StyledLogin>
        </SubNavToggler>
        <SubNav stick='right'>
          <SubNavItem>
            <SubNavLink to="/account/me">
              Informations personnelles
            </SubNavLink>
          </SubNavItem>
          {
            user.type === 'admin' &&
            <SubNavItem>
              <SubNavLink to="/account/settings">
                Paramètres avancés
              </SubNavLink>
            </SubNavItem>
          }
          {
            ['admin', 'manager'].includes(user.type) &&
            <SubNavItem>
              <SubNavLink to="/teams">
                Gestion des équipes
              </SubNavLink>
            </SubNavItem>
          }
          {
            ['admin', 'manager'].includes(user.type) &&
            <SubNavItem>
              <SubNavLink to="/account/users">
                Gestion des utilisateurs
              </SubNavLink>
            </SubNavItem>
          }
          <SubNavItem>
            <SubNavLink to="/help">
              Aide et support
            </SubNavLink>
          </SubNavItem>
        </SubNav>
      </NavItem>
    </NavbarNav>
    : null
}
