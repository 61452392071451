/*
 * ex:
 *   partition([0, 1, 2, 3, ...], v => v % 2)
 *   -> [[0, 2, ...], [1, 3, ...]]
 */
const partition = (array, predicate) =>
  array.reduce(([pass, fail], e) =>
    predicate(e) ? [[...pass, e], fail] : [pass, [...fail, e]]
    , [[], []])

/*
 * ex:
 *   makeTree([{
 *     id: 'tmp123',
 *     parent: null,
 *   }, {
 *     id: 'tmp456',
 *     parent: null,
 *   }, {
 *     id: 'tmp789',
 *     parent: 'tmp123',
 *   }])
 *   -> [{
 *     id: 'tmp123',
 *     parent: null,
 *     children: [{
 *       id: 'tmp789',
 *       parent: 'tmp123',
 *     }]
 *   }, {
 *     id: 'tmp456',
 *     parent: null,
 *   }]
 */
const makeTree = (nodes, parent = null) => {
  const [parents, children] = partition(nodes, n => n.parent == parent)
  return parents.map(p => ({ ...p, children: makeTree(children, '' + p.id) }))
}

const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
)

const makeKey = length => {
  let result = ''
  const characters = (
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  )

  for (let i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * characters.length))

  return result
}

const getAssetsRootURL = (onHttps) => {
  if (process.env.ON_CLOUD === '1')
    return 'http' + (onHttps ? 's' : '') + '://' + process.env.CDN_ASSETS_HOST
  return ''
}

module.exports = {
  partition,
  makeTree,
  sleep,
  makeKey,
  getAssetsRootURL
}
