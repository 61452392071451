// Inspired by https://github.com/saninmersion/react-context-fabricjs

import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react'
import styled from 'styled-components'

import FabricContext from '../contexts/FabricContext'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  cursor: move;
  color: red;
  & div > canvas#fabric-canvas {
    background-color: #D3D3D3;
  }
`

export default ({ className }) => {
  const wrapper = useRef(null)
  const el = useRef(null)
  const [{ canvas, canvasMode }, { initCanvas, setActiveObject }] = useContext(FabricContext)

  useLayoutEffect(() => {
    initCanvas(el.current, {
      width: wrapper.current.offsetWidth,
      height: wrapper.current.offsetHeight,
    })
  }, [el, initCanvas])

  const updateActiveObject = useCallback((e) => {
    if (!e) return

    setActiveObject(canvas.getActiveObject())
    canvas.renderAll()
  }, [canvas, setActiveObject])

  const handleMouseDown = useCallback(function (opt) {
    const e = opt.e

    if (e.altKey === true || e.button === 2) {
      this.isDragging = true
      this.selection = false
      this.lastPosX = e.clientX
      this.lastPosY = e.clientY
    }
  }, [canvas])

  const handleMouseMove = useCallback(function (opt) {
    const e = opt.e

    if (this.isDragging) {
      var vpt = this.viewportTransform
      vpt[4] += e.clientX - this.lastPosX
      vpt[5] += e.clientY - this.lastPosY
      this.lastPosX = e.clientX
      this.lastPosY = e.clientY
    }
    canvas.getObjects().map(object => {
      object.setCoords()
    })
    canvas.calcOffset()
    canvas.renderAll()
  })

  const handleMouseUp = useCallback(function (opt) {
    this.setViewportTransform(this.viewportTransform)
    this.isDragging = false
    this.selection = canvasMode === 'select'
  })

  const handleMouseWheel = useCallback(function (opt) {
    const e = opt.e

    e.preventDefault()
    e.stopPropagation()

    var delta = e.deltaY
    var zoom = canvas.getZoom()
    zoom *= 0.999 ** delta
    if (zoom > 5) zoom = 5
    if (zoom < 0.1) zoom = 0.1
    canvas.zoomToPoint({ x: e.offsetX, y: e.offsetY }, zoom)
    canvas.getObjects().map(object => {
      object.setCoords()
    })
    canvas.renderAll()
    canvas.calcOffset()
  })

  const handleResize = () => {
    canvas.setDimensions({
      width: wrapper.current.offsetWidth,
      height: wrapper.current.offsetHeight,
    })
  }

  useEffect(() => {
    if (!canvas) return

    canvas.on('mouse:down', handleMouseDown)
    canvas.on('mouse:move', handleMouseMove)
    canvas.on('mouse:up', handleMouseUp)
    canvas.on('mouse:wheel', handleMouseWheel)
    window.addEventListener('resize', handleResize)

    return () => {
      canvas.off('mouse:down', handleMouseDown)
      canvas.off('mouse:move', handleMouseMove)
      canvas.off('mouse:up', handleMouseUp)
      canvas.off('mouse:wheel')
      window.removeEventListener('resize', handleResize)
    }
  }, [canvas, canvasMode, updateActiveObject])

  return (
    <Wrapper ref={wrapper} className={`${className} test`}>
      <canvas
        ref={el}
        id="fabric-canvas"
      />
    </Wrapper>
  )
}
