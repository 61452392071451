import { fabric } from 'fabric'

export const TemplateCanvasServices = {
    initCanvas: (width, height) => {
        return new fabric.Canvas('canvas', {
            width: width,
            height: height,
            defaultCursor: 'default',
            hoverCursor: 'move',
            fireRightClick: true,
            fireMiddleClick: true,
            stopContextMenu: true,
            backgroundColor: '#D3D3D3',
        })
    },
    setDocument: (currentPageToDisplay, canvas, callBack) => {
        const grid = 50;
        canvas.remove(...canvas.getObjects());
        fabric.Image.fromURL(`/api/pages/${currentPageToDisplay.id}.${currentPageToDisplay.ext}`, function (img) {
            canvas.setBackgroundImage(img);
            for (var i = 0; i < (canvas.backgroundImage.width / grid); i++) {
                canvas.add(new fabric.Line([i * grid, 0, i * grid, canvas.backgroundImage.height], { strokeWidth: 0.5, type: 'line', stroke: '#ccc', selectable: false }));
                canvas.add(new fabric.Line([0, i * grid, canvas.backgroundImage.width, i * grid], { strokeWidth: 0.5, type: 'line', stroke: '#ccc', selectable: false }))
            }
            canvas.renderAll();
            callBack();
        });
    },
    DrawZones: (currentTemplateView, canvas, currentPage, currentTemplate) => {
        if (currentTemplateView.zones.length > 0) {
            currentTemplateView.zones.map((zone) => {
                if (zone && zone.page === currentPage) {
                    let currentObject = canvas.getObjects().find(object => object?.zone?.id === zone.id);
                    if (currentObject) {
                        currentObject.set({
                            left: zone.x * canvas.backgroundImage.width,
                            top: zone.y * canvas.backgroundImage.height,
                            right: (zone.x + zone.width) * canvas.backgroundImage.width,
                            bottom: (zone.y + zone.height) * canvas.backgroundImage.height,
                        })
                        currentObject.setCoords()
                    } else {
                        canvas.add(new fabric.Rect({
                            zone,
                            left: zone.x * canvas.backgroundImage.width,
                            top: zone.y * canvas.backgroundImage.height,
                            width: zone.width * canvas.backgroundImage.width,
                            height: zone.height * canvas.backgroundImage.height,
                            stroke: '#00f',
                            _stroke: '#00f',
                            strokeWidth: 2,
                            borderScaleFactor: 5,
                            strokeUniform: true,
                            noScaleCache: false,
                            ownCaching: false,
                            fill: 'transparent',
                            lockUniScaling: false,
                            selectable: currentTemplate ? true : false
                        }))
                    }
                }
            })
            let oldObject = canvas.getObjects().filter(object => object?.zone)
            oldObject = oldObject.filter(object => !currentTemplateView.zones.find(zone => object.zone.id === zone.id));
            oldObject.map(object => {
                canvas.remove(object);
            })
        }
    },
    objectMoved: (e, currentTemplateView, updateTemplateRequest, currentCanvas) => {
        let object = e.target;
        const templateToUpdate = currentTemplateView;

        if (object.zone) {
            templateToUpdate.zones = templateToUpdate.zones.map(zone => {
                let result = zone;
                if (result.id === object.zone.id) {
                    result = {
                        ...result,
                        x: object.aCoords.tl.x / currentCanvas.backgroundImage.width,
                        y: object.aCoords.tl.y / currentCanvas.backgroundImage.height
                    }
                }
                return result
            })
            updateTemplateRequest(templateToUpdate.id, templateToUpdate)
        }
    },
    objectSclaed: (e, currentTemplateView, updateTemplateRequest, canvas) => {
        let object = e.target;
        const templateToUpdate = currentTemplateView;
        let zoome = canvas.getZoom();

        if (object.zone) {
            templateToUpdate.zones = templateToUpdate.zones.map(zone => {
                let result = zone;
                if (result.id === object.zone.id) {
                    result = {
                        ...result,
                        x: object.aCoords.tl.x / canvas.backgroundImage.width,
                        y: object.aCoords.tl.y / canvas.backgroundImage.height,
                        width: (object.aCoords.tr.x - object.aCoords.tl.x) / canvas.backgroundImage.width,
                        height: (object.aCoords.bl.y - object.aCoords.tl.y) / canvas.backgroundImage.height
                    }
                }
                return result
            })
            updateTemplateRequest(templateToUpdate.id, templateToUpdate)
        }
    },
    handleMouseDown: (e, canvas, setCurrentZone, isEdition) => {
        if (e.button === 1) {
            if (e.target?.zone && isEdition) {
                if (canvas.getActiveObject()?.zone?.id === e.target?.zone.id) {
                    canvas.discardActiveObject().requestRenderAll();
                }
                canvas.setActiveObject(e.target);
                setCurrentZone(e.target.zone);
            } else {
                setCurrentZone(null);
                canvas.discardActiveObject().requestRenderAll();
            }
        }

    },
    handleMouseWheel: (opt, canvas) => {
        const e = opt.e

        e.preventDefault()
        e.stopPropagation()

        var delta = e.deltaY
        var zoom = canvas.getZoom()
        zoom *= 0.999 ** delta
        if (zoom > 5) zoom = 5
        if (zoom < 0.01) zoom = 0.01
        canvas.zoomToPoint({ x: e.offsetX, y: e.offsetY }, zoom);
    }
}