import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'

import Me from './Me'
import Settings from './Settings'
import Users from './Users'
import AuthContext from '../contexts/AuthContext'
import { Tabs } from '../components'

const StyledTabs = styled(Tabs)`
  width: 100%;
  height: 100%;
`

export default (props) => {
  const [{ user }] = useContext(AuthContext)
  const history = useHistory()
  const { resource } = useParams()

  let panes = [
    {
      key: 'me',
      title: 'Informations personnelles',
      content: <Me {...props} />,
    },
  ]

  if (user.type === 'manager' || user.type === 'admin')
    panes = [
      ...panes,
      {
        key: 'users',
        title: 'Gestion des utilisateurs',
        content: <Users {...props} />,
      },
    ]

  if (user.type === 'admin')
    panes = [
      ...panes,
      {
        key: 'settings',
        title: 'Paramètres avancés',
        content: <Settings {...props} />,
      },
    ]

  return (
    <StyledTabs
      currentTab={resource}
      panes={panes}
      onChange={v => history.push(`/account/${v}`)}
    />
  )
}
