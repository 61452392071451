import React, { useState } from 'react';
import styled from 'styled-components'

import {
    Table,
    THead,
    TBody,
    Tr,
    Th,
    Td,
    EditIcon,
    CloseCircleFilledIcon,
    BlankButton,
    Button,
    PlusIcon,
} from '../../components'

const StyledTable = styled(Table)`
  width: 100%;
`

const StyledTr = styled(Tr)`
  ${({ highlighted }) => highlighted && `
    font-weight: bold;
  `}
`

const LTh = styled(Th)`
  width: 100%;
`

const EditButton = styled(BlankButton)`
  color: ${({ theme }) => theme.primaryColor};
`

const DeleteButton = styled(BlankButton)`
  color: ${({ theme }) => theme.dangerRed};
`

const TemplateList = ({
    currentTemplateView,
    updateTemplateRequest,
    templateList,
    setCurrentTemplate,
    setCurrentTemplateView,
    deleteTemplate,
    setTemplateList
}) => {

    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState(null)

    const toggleOrder = by => {
        if (orderBy === by) return setOrder(order === 'asc' ? 'desc' : 'asc')

        setOrderBy(by)
        setOrder('desc')
    }

    return <div>
        <div style={{
            display: 'table', marginBottom: '1rem', marginLeft: 'auto', marginRight: 'auto'
        }}>
            <Button
                onClick={() => {
                    updateTemplateRequest(null, {
                        name: 'Nouveau template',
                        zones: [],
                        document: null
                    })
                }}
                as="label"
                primary
                leftIcon={<PlusIcon />}
            >
                Créer un template
            </Button>
        </div>
        <StyledTable>
            <THead>
                <Tr>
                    <LTh
                        onClickOrder={() => toggleOrder('name')}
                        order={orderBy === 'name' ? order : null}
                    >
                        Nom
                    </LTh>
                    <Th
                        onClickOrder={() => toggleOrder('document')}
                        order={orderBy === 'document' ? order : null}
                    >
                        Document
                    </Th>
                    <Th><EditIcon /></Th>
                    <Th><CloseCircleFilledIcon /></Th>
                </Tr>
            </THead>
            <TBody>
                {templateList.sort(
                    (a, b) => (
                        orderBy
                            ? (order === 'asc'
                                ? (a[orderBy] > b[orderBy] ? -1 : 1)
                                : (a[orderBy] < b[orderBy] ? -1 : 1))
                            : 0
                    )).map(template => (
                        <StyledTr
                            key={template.id}
                            highlighted={currentTemplateView && template.id === currentTemplateView.id}
                            onClick={() => { setCurrentTemplateView(template) }}>
                            <Td>{template.name}</Td>
                            {template.document && <Td
                                title={template.document.name}>{
                                    template.document.name.length > 20 ?
                                        `${template.document.name.slice(0, 21)}...`
                                        : template.document.name}</Td>
                            }
                            {!template.document && <Td>Aucun document</Td>}
                            <Td>
                                <EditButton onClick={() => {
                                    setCurrentTemplate(template); setCurrentTemplateView(template);
                                }}>
                                    <EditIcon />
                                </EditButton>
                            </Td>
                            <Td>
                                <DeleteButton onClick={e => {
                                    deleteTemplate(template.id).then((response) => { setTemplateList(response) })
                                }}>
                                    <CloseCircleFilledIcon />
                                </DeleteButton>
                            </Td>
                        </StyledTr>
                    ))}
            </TBody>
        </StyledTable>
    </div>
}

export default TemplateList;