import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import { Img } from './design-system/Media'
import { A } from './design-system/CTAs'
import { P } from './design-system/Typography'

const isOnDarkPage = () => (
  [
    '/login',
    '/register',
    '/forgot-password',
    '/reset-password',
    '/home',
    '/help'
  ].includes(useLocation().pathname)
)

const StyledFooter = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem 2rem;
  background-color: ${({ theme }) => isOnDarkPage() ? 'transparent' : theme.black
  };

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

StyledFooter.propTypes = {
  theme: PropTypes.shape({
    black: PropTypes.string
  })
}

const StyledLogoImg = styled(Img)`
  width: 120px;
`

const Copyright = styled.div`
  flex: 1;
  text-align: center;
  color: ${({ theme }) => theme.white};
  margin: 1rem auto;

  @media (min-width: 768px) {
    margin: 0;
    padding-right: 120px;
  }
`

Copyright.propTypes = {
  theme: PropTypes.shape({
    white: PropTypes.string
  })
}

const StyledMeshImg = styled(Img)`
  visibility: ${() => isOnDarkPage() ? 'hidden' : 'visible'
  };
  position: absolute;
  right: 0;
  top: 0;
`

const StyledA = styled(A)`
  font-size: .875rem;
  &:hover {
    text-decoration: underline;
  }
`

export const Footer = () =>
  <StyledFooter>
    <StyledLogoImg src="/images/nn-logo.svg" />
    <Copyright>
      <P>© NeuroFactory Digitize est une marque déposée de © Neuronalys - Tous droits réservés</P>
      <StyledA href="http://www.neuronalys.ai">www.neuronalys.ai</StyledA>
    </Copyright>
    <StyledMeshImg src="/images/mesh.svg" />
  </StyledFooter>
