import React, { useState, useEffect } from 'react';
import styled from 'styled-components'

import {
    Table,
    THead,
    TBody,
    Tr,
    Th,
    Td,
    TdForm,
    TextInput,
    FormGroup,
    CloseCircleFilledIcon,
    BlankButton,
    Button,
    Select,
    Option,
    PlusIcon,
} from '../../components'
import { fetchAllDocuments } from '../../services';

const StyledTable = styled(Table)`
  width: 100%;
`

const StyledTr = styled(Tr)`
  ${({ highlighted }) => highlighted && `
    font-weight: bold;
  `}
`

const LTh = styled(Th)`
  width: 100%;
`

const DeleteButton = styled(BlankButton)`
  color: ${({ theme }) => theme.dangerRed};
`

const TemplateZoneList = ({ currentZone, setCurrentZone, template, setCurrentTemplate, updateTemplate, currentPage, setCurrentPage }) => {

    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState(null)
    const [documentList, setDocumentList] = useState([])
    const [projectList, setProjectList] = useState([])
    const [currentProject, seCcurrentProject] = useState()

    useEffect(() => {
        fetchAllDocuments().then((response) => {
            const projectsListResponse = [];
            response.documents.map(document => {

                if (document.project && !projectsListResponse.find(project => project.id === document.project.id)) {
                    projectsListResponse.push(document.project)
                }
            })
            setProjectList(projectsListResponse);
            setDocumentList(response.documents);
        })
    }, [])

    useEffect(() => {
        if (template && documentList && template.document) {
            seCcurrentProject(documentList.find(document => document.id === template.document.id)?.project || null)
        }
    }, [template, documentList])

    const toggleOrder = by => {
        if (orderBy === by) return setOrder(order === 'asc' ? 'desc' : 'asc')

        setOrderBy(by)
        setOrder('desc')
    }

    return <div>
        <div style={{
            marginBottom: '1rem', marginLeft: 'auto', marginRight: 'auto'
        }}>

            <Button onClick={() => { setCurrentTemplate(null) }} style={{
                marginBottom: '1rem', marginLeft: 'auto', marginRight: 'auto'
            }}> {'<'} Retour aux templates</Button>
            <br />
            <FormGroup>
                <div style={{
                    zIndex: '2', width: '-webkit-fill-available'
                }}>
                    <TextInput
                        style={{
                            zIndex: '2', width: '-webkit-fill-available'
                        }}
                        label="Nom du template"
                        value={template.name}
                        onClick={value => { updateTemplate(template.id || template._id || null, { ...template, name: value }) }}
                        SelfForm={true}
                        required
                    />
                </div>
                <div
                    style={{
                        zIndex: '2', width: '-webkit-fill-available'
                    }}>
                    <br />
                    <div style={{
                        marginLeft: '1rem', marginRight: 'auto'
                    }}>Projet :</div>
                    <Select
                        label="Projet du document"
                        placeholder="Choisissez un projet"
                        onChange={(project) => { seCcurrentProject(project) }}
                        style={{
                            marginTop: '1rem', marginBottom: '1rem', marginLeft: 'auto', marginRight: 'auto', minWidth: '100%'
                        }}
                    >
                        {projectList.map(project => (
                            <Option key={project.id} value={project} selected={currentProject?.id === project.id}>
                                {project.name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div
                    style={{
                        zIndex: '1', width: '-webkit-fill-available'
                    }}>
                    <div style={{
                        marginLeft: '1rem', marginRight: 'auto'
                    }}>Document :</div>
                    <Select
                        label="Document"
                        placeholder="Choisissez document"
                        onChange={(document) => { updateTemplate(template.id || template._id || null, { ...template, document: document }) }}
                        style={{
                            marginTop: '1rem', marginBottom: '1rem', marginLeft: 'auto', marginRight: 'auto', minWidth: '100%'
                        }}
                        disable={!currentProject && documentList}
                    >
                        {
                            currentProject &&
                            documentList &&
                            documentList
                                .filter(document => document.project?.id === currentProject.id)
                                .map(document => (
                                    <Option
                                        key={document.id}
                                        value={document}
                                        selected={
                                            documentList
                                            && template.document
                                            && template.document.id === document.id}
                                    >
                                        {document.name}
                                    </Option>
                                ))
                        }
                    </Select>
                </div>

            </FormGroup>
            <Button
                disable={!template.document}
                style={{
                    display: 'block',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    width: 'fit-content'
                }}
                onClick={() => {
                    let templateToUpdate = template;
                    template.zones = [...template.zones, {
                        name: 'nouvelle zone',
                        height: 0.10,
                        page: currentPage || 0,
                        rotation: 0,
                        width: 0.10,
                        x: 0,
                        y: 0,
                    }]
                    updateTemplate(templateToUpdate.id, templateToUpdate)
                }}
                as="label"
                primary
                leftIcon={<PlusIcon />}
            >
                Créer une zone
            </Button>
        </div>
        <StyledTable>
            <THead>
                <Tr>
                    <LTh
                        onClickOrder={() => toggleOrder('name')}
                        order={orderBy === 'name' ? order : null}
                    >
                        Nom
                    </LTh>
                    <Th
                        onClickOrder={() => toggleOrder('page')}
                        order={orderBy === 'page' ? order : null}
                    >
                        Page
                    </Th>
                    <Th><CloseCircleFilledIcon /></Th>
                </Tr>
            </THead>
            <TBody>
                {template.zones.sort(
                    (a, b) => (
                        orderBy
                            ? (order === 'asc'
                                ? (a[orderBy] > b[orderBy] ? -1 : 1)
                                : (a[orderBy] < b[orderBy] ? -1 : 1))
                            : 0
                    )).map((zoneTab, index) => (
                        zoneTab && <StyledTr
                            key={zoneTab.id}
                            highlighted={currentZone && zoneTab.id === currentZone.id}
                            onClick={() => { setCurrentZone(zoneTab); setCurrentPage(zoneTab.page) }}>
                            <TdForm value={zoneTab.name} onSubmit={value => {
                                let templateToUpdate = template;
                                templateToUpdate.zones[index].name = value;
                                updateTemplate(template.id || template._id || null, templateToUpdate)
                            }} />
                            <Td>
                                {zoneTab.page + 1}
                            </Td>
                            <Td>
                                <DeleteButton
                                    onClick={e => {
                                        updateTemplate(
                                            template.id || template._id || null,
                                            { ...template, zones: template.zones.filter(zone => { return zone.id != zoneTab.id }) }
                                        )
                                    }}>
                                    <CloseCircleFilledIcon />
                                </DeleteButton>
                            </Td>
                        </StyledTr>
                    ))}
            </TBody>
        </StyledTable>
    </div >
}

export default TemplateZoneList;