import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import * as services from '../services'
import useList from '../hooks/useList'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faCopy } from '@fortawesome/free-solid-svg-icons'

import {
  Section,
  Columns,
  H1,
  Button,
  P,
  Link,
  BlankButton,
  Ul,
  CollapsedLi,
  Icon,
  H4,
  FolderIcon,
  PlusIcon,
  CheckIcon,
  CloseIcon,
  CloseCircleFilledIcon,
  EditIcon,
  AddCircleFilledIcon,
  SpinnerAnimatedIcon,
  BookIcon,
  Alphabet,
  Form,
  TextInput,
  Pagination,
  ConfirmationModal,
  Input
} from '../components'

import QuerySearchInput from '../modules/QuerySearchInput'

const StyledPage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledSection = styled(Section)`
  flex: 1;
  padding: 0 4%;
`

const StyledColumns = styled(Columns)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Projects = styled.div`
  margin: 2rem .5rem 1rem;
  flex: 1;
`

const ProjectName = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 1.25rem;

  > strong {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const ProjectInfos = styled.span`
  font-size: .75rem;
  color: ${({ theme }) => theme.dustyGray};
`

const ProjectIconWrapper = styled.span`
  line-height: 0;
  margin-right: .5rem;

  &, ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const AddDictionaryButton = styled(BlankButton)`
  color: ${({ theme }) => theme.primaryColor};
  margin-right: .4rem;
`

const StyledDictionaryLi = styled.li`
  font-family: Saira;
  display: flex;
  justify-content: space-between;

  ${Icon} { margin-right: .25rem; }

  margin: .5rem 0;
`

const DictionaryWord = styled.div`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledUploadInput = styled(Input)`
  display: none;
`

const StyledDictionaryForm = styled(Form)`
  margin: 1rem 0;
`

const StyledDictionaryLabel = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryColor};
`

const DictionaryLiOptions = styled.div`
  display: flex;
`

const ProjectLabel = ({ project, dictionaries, onClickAdd }) =>
  <>
    <ProjectName>
      <ProjectIconWrapper><FolderIcon /></ProjectIconWrapper>
      <strong>{project.name}</strong>
      <AddDictionaryButton onClick={onClickAdd}>
        <PlusIcon />
      </AddDictionaryButton>
    </ProjectName>
    <ProjectInfos>{dictionaries.length} dictionnaires associés</ProjectInfos>
  </>

const DictionaryLi = ({ dictionary, onClick, onClickDelete }) =>
  <StyledDictionaryLi>
    <BlankButton onClick={onClick}>
      <BookIcon /> {dictionary.name}
    </BlankButton>
    <DictionaryLiOptions>
      <StyledButton onClick={() =>
        services
          .downloadCSVDictionary(dictionary.id, dictionary.name)
          .then(() => console.log('hello'))
      }>
        <FontAwesomeIcon
          style={{ fontSize: '0.88rem', padding: '0 0.25rem' }}
          icon={faDownload}
        />
      </StyledButton>
      <StyledButton danger onClick={onClickDelete}>
        <CloseCircleFilledIcon />
      </StyledButton>
    </DictionaryLiOptions>
  </StyledDictionaryLi>

const ProjectLi = ({
  project,
  dictionaries,
  onClickAddDictionary,
  onClickDictionary,
  onClickDeleteDictionary,
  onClickUploadDictionary,
  onClickFuseDictionary
}) => {
  const [collapsed, setHidden] = useState(true)

  const handleClickAddDictionary = e => {
    e.stopPropagation()
    onClickAddDictionary(project)
  }

  const handleClickDictionary = (e, dictionary) => {
    e.stopPropagation()
    onClickDictionary(dictionary)
  }

  const handleClickDeleteDictionary = (e, dictionary) => {
    e.stopPropagation()
    onClickDeleteDictionary(dictionary)
  }

  const handleClickFuseDictionary = (e, projectId) => {
    e.stopPropagation()
    onClickFuseDictionary(projectId)
  }

  const label =
    <ProjectLabel
      project={project}
      dictionaries={dictionaries}
      onClickAdd={e => {
        setHidden(false)
        handleClickAddDictionary(e)
      }}
    />

  return (
    <CollapsedLi
      key={`project-${project.id}`}
      label={label}
      collapsed={collapsed}
      onClick={() => setHidden(!collapsed)}
    >
      <>
        <Ul>
          {
            dictionaries
              .filter(dictionary => dictionary.projects.includes(project.id))
              .map(dictionary =>
                <DictionaryLi
                  key={`dictionary-${dictionary.id}`}
                  dictionary={dictionary}
                  onClick={e => handleClickDictionary(e, dictionary)}
                  onClickDelete={e => handleClickDeleteDictionary(e, dictionary)}
                />
              )
          }
        </Ul>
        <StyledDictionaryForm>
          <StyledDictionaryLabel htmlFor={'upload-' + project.id}>
            <PlusIcon />
            &nbsp;
            Ajouter un dictionnaire (format .csv)
          </StyledDictionaryLabel>
          <StyledUploadInput
            id={'upload-' + project.id}
            disabled={false}
            label="Ajouter un dictionnaire"
            primary
            accept=".csv"
            onChange={
              event => {
                event.preventDefault()

                const upload = event.target.files[0]

                onClickUploadDictionary(project, upload)

                event.target.value = null
              }
            }
            type="file"
          />
        </StyledDictionaryForm>
        {
          project.canFuseDictionary && (
            <Button
              style={{ width: '100%', margin: '0.5rem 0' }}
              primary
              onClick={e => handleClickFuseDictionary(e, project.id)}
            >
              Relancer l'analyse par dictionnaire
            </Button>
          )
        }
      </>
    </CollapsedLi>
  )
}

const StyledOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const StyledDictionary = styled.div`
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
`

const StyledForm = styled.form`
  display: flex;
  align-items: center;
`

const StyledName = styled.strong`
  flex: 1;
  display: inline-flex;
  align-items: center;
`

const StyledInput = styled.input`
  width: 100%;
  padding-left: 0;
  border: 0;
  line-height: 1.5;
  border-bottom: 1px dashed ${({ theme, readOnly }) => !readOnly ? theme.gray : 'transparent'};

  font-family: Blinker;
  font-size: 1.5rem;
  font-weight: bold;
  text-overflow: ellipsis;

  &:focus { outline: 0; }
`

const BookIconWrapper = styled.span`
  line-height: 0;
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
`

const StyledButton = styled(BlankButton)`
  color: ${({ theme, primary, danger }) =>
    (primary && theme.primaryColor) ||
    (danger && theme.dangerRed)
  };

  ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const DictionaryForm = ({ dictionary, onChange }) => {
  const [name, setName] = useState(dictionary.name)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setName(dictionary.name)
  }, [dictionary])

  const handleCancelEdit = e => {
    setName(dictionary.name)
    setEditing(false)
  }

  const handleChange = e =>
    setName(e.target.value)

  const handleSubmit = e => {
    e.preventDefault()
    onChange({ ...dictionary, name })
    setEditing(false)
  }

  return (
    <StyledDictionary>
      <StyledForm as={editing ? 'form' : 'div'} onSubmit={handleSubmit}>
        <StyledName>
          <BookIconWrapper>
            <BookIcon />
          </BookIconWrapper>
          <StyledInput type="text" value={name} onChange={handleChange} readOnly={!editing} />
        </StyledName>
        {
          editing
            ? <>
              <StyledButton primary type="submit">
                <CheckIcon />
              </StyledButton>
              <StyledButton danger onClick={handleCancelEdit}>
                <CloseIcon />
              </StyledButton>
            </>
            : <StyledButton primary onClick={() => setEditing(true)}>
              <EditIcon />
            </StyledButton>
        }
      </StyledForm>
    </StyledDictionary>
  )
}

const StyledWordsUl = styled(Ul)`
  margin: 1rem 0;
`

const StyledWordLi = styled.li`
  @media (min-width: 768px) {
    width: 31.33%;
    display: inline-block;
    vertical-align: top;
    margin: 1%;
  }
`

const StyledWordEditButton = styled(StyledButton)`
  font-size: 1rem;

  ${Icon} { width: 1rem; height: 1rem; }

  padding: .2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledWordDescForm = styled(StyledForm)`
  flex-direction: column;
  align-items: flex-start;
`

const StyledWordDescription = styled(P)`
  font-size: 1rem;
  resize: none;
  outline: none;
  margin: .5rem 0;
`

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Line = styled.div`
  border: 1px solid #d8d8d8;
  margin: 0.25rem 0;
`

const StyledSynonymUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const StyledSynonymLi = styled.li`
  display: flex;
  justify-content: space-between;
`

const StyledSynonymLiOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const WordLi = ({
  word,
  projects,
  dictionaries,
  resetProjects,
  setWord,
  onChange,
  onClickDelete,
  synonyms,
  currentDictionary
}) => {
  const [newSynonymValue, setNewSynonymValue] = useState('')
  const [description, setDescription] = useState(word.description)
  const [editing, setEditing] = useState(false)

  const handleCancelEdit = e => {
    setDescription(word.description)
    setEditing(false)
  }

  const handleChange = e =>
    setDescription(e.target.value)

  const handleSubmit = e => {
    e.preventDefault()
    onChange({ ...word, description })
    setEditing(false)
  }

  const handleClickAddSynonym = () => {
    if (!newSynonymValue) return

    const dictionaryProjects = (
      dictionaries
        .find(d => d.id === currentDictionary.id)
        .projects
    ) || []

    services
      .createWord({
        value: newSynonymValue,
        isSynonymOf: word.id,
        dictionary: currentDictionary.id
      })
      .then(({ word: synonym }) => {
        setWord(synonym)
        setNewSynonymValue('')
        resetProjects(
          projects.map(p => {
            if (dictionaryProjects.includes(p.id)) {
              return {
                ...p,
                canFuseDictionary: true
              }
            }

            return p
          })
        )
      })
  }

  return (
    <StyledWordLi key={word.id}>
      <DictionaryWord>
        {word.value}
        <StyledWordEditButton
          onClick={() => navigator.clipboard.writeText(word.value)}
        >
          <FontAwesomeIcon
            style={{ fontSize: '0.8rem', padding: '0 0.5rem' }}
            icon={faCopy}
          />
        </StyledWordEditButton>
      </DictionaryWord>
      {
        editing
          ? <StyledWordDescForm onSubmit={handleSubmit}>
            <StyledWordDescription as="textarea" value={description} onChange={handleChange} rows={3} />
            <StyledWordEditButton primary type="submit">
              <CheckIcon /> Enregistrer
            </StyledWordEditButton>
            <StyledWordEditButton danger onClick={handleCancelEdit}>
              <CloseIcon /> Annuler
            </StyledWordEditButton>
          </StyledWordDescForm>
          :
          <>
            <Form onSubmit={
              e => {
                e.preventDefault()

                handleClickAddSynonym()
              }
            }>
              <TextInput
                name="name"
                value={newSynonymValue}
                placeholder="Ajouter un synonyme"
                buttonIcon={AddCircleFilledIcon}
                onChange={setNewSynonymValue}
                onClick={handleClickAddSynonym}
                disabled={!currentDictionary}
                focus={false}
              />
            </Form>
            <StyledSynonymUl>
              {
                synonyms.map((synonym, index) => (
                  <StyledSynonymLi key={`synonym-${synonym.id}`}>
                    {"- " + synonym.value}
                    <StyledSynonymLiOption>
                      <StyledWordEditButton
                        onClick={() => navigator.clipboard.writeText(synonym.value)}
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: '0.8rem', padding: '0 0.5rem' }}
                          icon={faCopy}
                        />
                      </StyledWordEditButton>
                      <StyledWordEditButton
                        danger
                        onClick={
                          () => onClickDelete(synonyms[index])
                        }
                      >
                        <CloseCircleFilledIcon />
                      </StyledWordEditButton>
                    </StyledSynonymLiOption>
                  </StyledSynonymLi>
                ))
              }
            </StyledSynonymUl>
            <Line />
            <StyledWordDescription>{description}</StyledWordDescription>
            <StyledWordEditButton primary onClick={() => setEditing(true)}>
              <EditIcon /> Description
            </StyledWordEditButton>
            <Line />
            <StyledWordEditButton danger onClick={() => onClickDelete(word)}>
              <CloseCircleFilledIcon /> Supprimer
            </StyledWordEditButton>
          </>
      }
    </StyledWordLi>
  )
}

export default () => {
  const nbItemsPerPage = 30
  const [onLoadingProjects, setOnLoadingProjects] = useState(true)
  const [onLoadingDictionary, setOnLoadingDictionary] = useState(true)
  const [query, setQuery] = useState({})
  const [activePageIndex, setActivePageIndex] = useState(1)
  const [currentLetter, setCurrentLetter] = useState(null)
  const [
    { items: projects },
    { resetItems: resetProjects }
  ] = useList()
  const [toDeleteId, setToDeleteId] = useState(null)
  const [currentDictionary, setCurrentDictionary] = useState(null)
  const [
    { items: dictionaries },
    {
      resetItems: resetDictionaries,
      setItem: setDictionary,
      updateItem: updateDictionary,
      deleteItem: deleteDictionary,
    }
  ] = useList()
  const [newWordValue, setNewWordValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  let [
    { items: words1 },
    {
      resetItems: resetWords,
      setItem: setWord,
      updateItem: updateWord,
      deleteItem: deleteWord,
    }
  ] = useList()

  const synonyms = words1.filter(w => w.isSynonymOf)
  const words = (
    words1.filter(w => {
      return (!w.isSynonymOf
        && synonyms
          .filter(synonym =>
            synonym.isSynonymOf === w.id)
          .find(synonym =>
            synonym.value.toLowerCase().includes(searchValue.toLowerCase())))
        || w.value.toLowerCase().includes(searchValue.toLowerCase())
    }).filter(w => !w.isSynonymOf)
      .filter(
        v => currentLetter === null
          || v.value.toLowerCase()[0] === currentLetter
      ))

  useEffect(() => {
    Promise.all([
      services.fetchAllProjects(),
      services.fetchAllDictionaries()
    ]).then(values => {
      resetProjects(values[0].projects.map(p => ({ ...p, projectId: p.id })))
      resetDictionaries(values[1].dictionaries)
      setOnLoadingProjects(false)
    })
  }, [])

  useEffect(() => {
    setOnLoadingDictionary(true)

    if (!currentDictionary) {
      setOnLoadingDictionary(false)
      return
    }

    services
      .fetchAllWords({ dictionary: currentDictionary.id })
      .then(({ words }) => {
        resetWords(words)
        setActivePageIndex(1)
        setOnLoadingDictionary(false)
      })
  }, [currentDictionary])

  const handleClickAddDictionary = project => {
    services
      .createDictionary({
        name: 'Nouveau dictionnaire',
        projects: [project.id]
      })
      .then(({ dictionary }) => {
        setDictionary(dictionary)
        resetProjects(
          projects.map(p => {
            if (p.id === project.id) {
              return {
                ...p,
                canFuseDictionary: true
              }
            }

            return p
          })
        )
      })
  }

  const handleClickUploadDictionary = (project, upload) => {
    const data = new FormData()

    data.append('file', upload)
    data.append('project', project.id)

    services
      .createCSVDictionary(data)
      .then(({ dictionary }) => {
        setDictionary(dictionary)
        resetProjects(
          projects.map(p => {
            if (p.id === project.id) {
              return {
                ...p,
                canFuseDictionary: true
              }
            }

            return p
          })
        )
      })
  }

  const handleClickDictionary = dictionary => {
    setCurrentDictionary(dictionary)
    setCurrentLetter(null)
  }

  const handleClickDeleteDictionary = dictionary => setToDeleteId(dictionary.id)

  const handleConfirmDelete = dictionaryId => {
    const dictionaryProjects = (
      dictionaries
        .find(d => d.id === dictionaryId)
        .projects
    ) || []

    services.deleteDictionary(dictionaryId).then(() => {
      setCurrentLetter(null)
      setCurrentDictionary(null)
      deleteDictionary(dictionaryId)
      resetProjects(
        projects.map(p => {
          if (dictionaryProjects.includes(p.id)) {
            return {
              ...p,
              canFuseDictionary: true
            }
          }

          return p
        })
      )
      setToDeleteId(null)
      resetWords([])
    })
  }

  const handleCancelDelete = () =>
    setToDeleteId(null)

  const handleChangeDictionary = ({ id, ...updates }) => {
    services
      .updateDictionary(id, updates)
      .then(({ dictionary }) => updateDictionary(id, dictionary))
  }

  const handleClickAddWord = () => {
    if (!newWordValue) return

    services
      .createWord({ value: newWordValue, dictionary: currentDictionary.id })
      .then(({ word }) => {
        setWord(word)
        setNewWordValue('')
        setCurrentLetter(word.value.toLowerCase()[0])
        resetProjects(
          projects.map(p => {
            if (currentDictionary.projects.includes(p.id)) {
              return {
                ...p,
                canFuseDictionary: true
              }
            }

            return p
          })
        )
      })
  }

  const handleClickLetter = letter => {
    const index = words.findIndex(w => w.value.toLowerCase()[0] === letter) + 1

    setCurrentLetter(letter)

    setActivePageIndex(1)

    // if (!index) {
    //   setActivePageIndex(1)
    // }

    // setActivePageIndex(Math.floor(index / nbItemsPerPage) + 1)
  }

  const handleChangeWord = ({ id, ...updates }) => {
    services
      .updateWord(id, updates)
      .then(({ word }) => updateWord(id, word))
  }

  const handleClickDeleteWord = word => {
    services
      .deleteWord(word.id)
      .then(() => {
        deleteWord(word.id)
        resetProjects(
          projects.map(p => {
            if (currentDictionary.projects.includes(p.id)) {
              return {
                ...p,
                canFuseDictionary: true
              }
            }

            return p
          })
        )
      })
  }

  const handleClickFuseDictionary = projectId =>
    services
      .reFuseProject(projectId)
      .then(() =>
        resetProjects(
          projects.map(p => {
            if (p.id === projectId) {
              return {
                ...p,
                canFuseDictionary: false
              }
            }

            return p
          })
        )
      )

  let _projects = projects
  if (Object.keys(query).length)
    _projects = projects.filter(project =>
      Object.keys(query).every(k =>
        project[k] && (project[k] + '').toLowerCase().includes(query[k].toLowerCase())
      )
    )
  const offset = (activePageIndex - 1) * nbItemsPerPage

  return (
    <StyledPage>
      <H1>Dictionnaires Métiers</H1>
      <StyledSection>
        <StyledColumns nb={4}>
          {
            onLoadingProjects
              ? (
                <StyledLoading>
                  <SpinnerAnimatedIcon />&nbsp;
                  Chargement ...
                </StyledLoading>
              ) : (
                <>
                  <QuerySearchInput onChange={setQuery} help={false} />
                  <Projects>
                    {
                      projects.length
                        ?
                        <Ul>
                          {_projects
                            .map(project =>
                              <ProjectLi
                                key={`project-${project.id}`}
                                project={project}
                                dictionaries={
                                  dictionaries.filter(dictionary => dictionary.projects.includes(project.id))
                                }
                                onClickDictionary={handleClickDictionary}
                                onClickAddDictionary={handleClickAddDictionary}
                                onClickDeleteDictionary={handleClickDeleteDictionary}
                                onClickUploadDictionary={handleClickUploadDictionary}
                                onClickFuseDictionary={handleClickFuseDictionary}
                              />
                            )}
                        </Ul>
                        :
                        <P>
                          Il semble que vous n'ayez aucun projet.<br />
                          Vous devez <Link to="/manage/projects">créer un projet</Link> avant d'y ajouter des dictionnaires.
                        </P>
                    }
                  </Projects>
                </>
              )
          }
        </StyledColumns>
        <StyledColumns nb={8}>
          <StyledOptions>
            {
              currentDictionary
                ? <DictionaryForm dictionary={currentDictionary} onChange={handleChangeDictionary} />
                : <P>Sélectionner un dictionnaire afin de lui ajouter des mots</P>
            }
            <Form onSubmit={
              e => {
                e.preventDefault()

                handleClickAddWord()
              }
            }>
              <TextInput
                key={"AddATerm"}
                name="name"
                value={newWordValue}
                placeholder="Ajouter un terme"
                buttonIcon={AddCircleFilledIcon}
                onChange={setNewWordValue}
                onClick={handleClickAddWord}
                disabled={!currentDictionary}
                focus={true}
              />
            </Form>
          </StyledOptions>
          {
            onLoadingDictionary
              ? (
                <StyledLoading>
                  <SpinnerAnimatedIcon />&nbsp;
                  Chargement ...
                </StyledLoading>
              ) : (
                <div>
                  <TextInput
                    name="search"
                    value={searchValue}
                    placeholder="Rechercher un terme"
                    onChange={setSearchValue}
                    disabled={!currentDictionary}
                  />
                  <br />
                  <Alphabet
                    currentLetter={currentLetter}
                    enabledLetters={
                      '0123456789abcdefghijklmnopqrstuvwxyz'.split('').filter(l =>
                        !!words1.find(w => w.value.toLowerCase()[0] === l)
                      )
                    }
                    onClick={handleClickLetter}
                  />
                  <StyledWordsUl>
                    {
                      words
                        .sort((a, b) => a.value.toLowerCase() < b.value.toLowerCase() ? -1 : 1)
                        .slice(offset, offset + nbItemsPerPage)
                        .map(word =>
                          <WordLi
                            key={`word-${word.id}`}
                            word={word}
                            projects={projects}
                            dictionaries={dictionaries}
                            resetProjects={resetProjects}
                            setWord={setWord}
                            synonyms={
                              synonyms
                                .filter(
                                  synonym => synonym.isSynonymOf === word.id
                                )
                            }
                            onChange={handleChangeWord}
                            onClickDelete={handleClickDeleteWord}
                            currentDictionary={currentDictionary}
                          />
                        )
                    }
                  </StyledWordsUl>
                  <Pagination
                    nbItemsPerPage={nbItemsPerPage}
                    nbPagesDisplayed={5}
                    activePageIndex={activePageIndex}
                    totalItems={words.filter(
                      v => currentLetter === null || v.value.toLowerCase()[0] === currentLetter).length
                    }
                    onChange={i => setActivePageIndex(i)}
                  />
                </div>
              )
          }
        </StyledColumns>
      </StyledSection>

      {toDeleteId &&
        <ConfirmationModal isOpen danger
          title="Êtes-vous sûr de vouloir faire ça ?"
          description="Supprimer un dictionnaire. Cette action est irreversible."
          onConfirm={() => handleConfirmDelete(toDeleteId)}
          onCancel={() => handleCancelDelete()}
        />
      }

    </StyledPage>
  )
}
