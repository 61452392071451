import React from 'react'
import styled from 'styled-components'

import {
    Section,
    Columns,
    H1,
    SpinnerAnimatedIcon,
} from '../components'

import TemplateCanvas from '../modules/Template/TemplateCanvas'
import TemplateList from '../modules/Template/TemplateList'
import TemplateZoneList from '../modules/Template/TemplateZoneList'
import { createTemplate, getTemplatesList, getTemplate, updateTemplate, deleteTemplate } from '../services/template'

const StyledPage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledSection = styled(Section)`
  padding: 0 4%;
  flex: 1;

  margin-bottom: 2rem;
`

const StyledColumns = styled(Columns)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export default () => {

    const [loading, setLoading] = React.useState(true);
    const [templateList, setTemplateList] = React.useState([]);
    const [currentTemplate, setCurrentTemplate] = React.useState(null);
    const [currentTemplateView, setCurrentTemplateView] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [currentZone, setCurrentZone] = React.useState(null);

    React.useEffect(() => {
        getTemplatesList().then((response) => {
            setTemplateList(response);
            setLoading(false);
        })
    }, [])

    const updateTemplateRequest = (id, template) => {
        setLoading(true);
        if (id) {
            updateTemplate(id, template).then((response => {
                setCurrentTemplate({ ...response })
                setCurrentTemplateView({ ...response })
                getTemplatesList().then((TemplatesList) => {
                    setTemplateList(TemplatesList);
                    setLoading(false);
                })
            }))
        } else {
            createTemplate(template).then((response) => {
                setTemplateList([...templateList, response])
                setCurrentTemplate(response)
                setLoading(false);
            })
        }
    }

    return <StyledPage>
        <H1 style={{ margin: '2rem 0' }}>Gérer les templates</H1>
        <StyledSection style={{ marginBottom: '0', padding: '0.5rem' }}>
            <StyledColumns nb={5} style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                {
                    loading && <StyledLoading>
                        <SpinnerAnimatedIcon />&nbsp;
                        Chargement ...
                    </StyledLoading>
                }
                {!loading
                    && currentTemplate
                    && <TemplateZoneList
                        currentZone={currentZone}
                        setCurrentZone={setCurrentZone}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        template={currentTemplate}
                        setCurrentTemplate={setCurrentTemplate}
                        updateTemplate={updateTemplateRequest}
                    />
                }
                {!loading
                    && !currentTemplate
                    && <TemplateList
                        updateTemplateRequest={updateTemplateRequest}
                        templateList={templateList || []}
                        currentTemplateView={currentTemplateView}
                        setCurrentTemplateView={(template) => {
                            setCurrentTemplateView(template);
                            setCurrentPage(0);
                            setCurrentZone(template.zones[0]);
                        }}
                        setCurrentTemplate={setCurrentTemplate}
                        deleteTemplate={deleteTemplate}
                        setTemplateList={setTemplateList}
                    />
                }
            </StyledColumns>
            <StyledColumns nb={7} style={{ margin: '0', paddingLeft: '0', border: '1px solid #d8d8d8' }}>
                <TemplateCanvas
                    updateTemplateRequest={updateTemplateRequest}
                    currentZone={currentZone}
                    setCurrentZone={setCurrentZone}
                    currentTemplate={currentTemplate}
                    currentTemplateView={currentTemplateView}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage} />
            </StyledColumns>
        </StyledSection>
    </StyledPage>
}