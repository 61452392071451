import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'qs'

import useList from '../hooks/useList'
import * as services from '../services'

import {
  Section,
  H1,
  P,
  Form,
  TextInput,
  Select,
  Option,
  AddCircleFilledIcon,
  Pagination,
  ConfirmationModal,
  SpinnerAnimatedIcon,
  Button,
  BlankButton,
  Icon,
  CheckIcon,
  CloseIcon,
  CloseCircleFilledIcon,
  EditIcon,
  FolderIcon,
  Link,
  Ul,
  Li
} from '../components'

import QuerySearchInput from '../modules/QuerySearchInput'

const StyledProject = styled.div`
display: inline-block;
width: 33.33%;
padding: 1rem;
box-sizing: border-box;
`

const Error = styled.div`
  margin-top: 0.25rem;
  text-align: right;
  font-size: .75rem;
  color: ${({ theme }) => theme.errorRed};
`

const StyledForm = styled.form`
  display: flex;
  align-items: center;
`

const StyledName = styled.strong`
  flex: 1;
  display: inline-flex;
  align-items: center;
  overflow: auto;
`

const StyledInput = styled.input`
  width: 100%;
  padding-left: 0;
  border: 0;
  line-height: 1.5;
  border-bottom: 1px dashed ${({ theme, readOnly }) => !readOnly ? theme.gray : 'transparent'};

  font-family: Blinker;
  font-size: 1.5rem;
  font-weight: bold;
  text-overflow: ellipsis;

  &:focus { outline: 0; }
`

const FolderIconWrapper = styled.span`
  line-height: 0;
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
`

const StyledInfos = styled.div`
  font-size: .75rem;
  color: ${({ theme }) => theme.dustyGray};

  a {
    color: ${({ theme }) => theme.dustyGray};

    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: underline;
    }
  }
`

const StyledButton = styled(BlankButton)`
  color: ${({ theme, primary, danger }) =>
    (primary && theme.primaryColor) ||
    (danger && theme.dangerRed)
  };

  ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const AddTeamZone = styled.div`
  margin: 0.5rem 0;
`

const StyledLi = styled(Li)`
  color: #000;
  font-size: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
`

const AdvanceParam = ({ handleSubmit, project }) => {
  const [display, setDisplay] = useState(false)

  return <div onMouseLeave={() => setDisplay(false)}>
    <span
      style={{
        color: display ? '#92de42' : '#8ab4f8',
        cursor: 'pointer',
        textDecorationLine: 'underline'
      }}
      onClick={() => setDisplay(!display)}
    >
      Paramètres avancés
    </span>
    {display && <div
      style={{
        position: 'absolute',
        backgroundColor: 'white',
        width: '26%',
        padding: '1%',
        borderBottom: 'solid 0.05rem gray',
        borderLeft: 'solid 0.05rem gray',
        borderRight: 'solid 0.05rem gray',
        zIndex: '1'
      }}
    >
      <div>
        <span>
          Détection de la rotation :
        </span>
        <span>
          <Select
            label="Détection de la rotation"
            placeholder="Détection de la rotation"
            onChange={() => { handleSubmit({ ...project, rotationEnabled: !project.rotationEnabled }) }}
            style={{
              marginTop: '1rem', marginBottom: '1rem', marginLeft: 'auto', marginRight: 'auto', minWidth: '100%'
            }}
          >
            <Option value={"Activé"} selected={project.rotationEnabled}>
              Activé
            </Option>
            <Option value={"Désactivé"} selected={!project.rotationEnabled}>
              Désactivé
            </Option>
          </Select>
        </span>
      </div>
      <div>
        <span>
          Détection des groupes de mots :
        </span>
        <Select
          label="Détection des groupes de mots"
          placeholder="Détection des groupes de mots"
          onChange={() => { handleSubmit({ ...project, groupWords: !project.groupWords }) }}
          style={{
            marginTop: '1rem', marginBottom: '1rem', marginLeft: 'auto', marginRight: 'auto', minWidth: '100%'
          }}
        >
          <Option value={"Activé"} selected={project.groupWords}>
            Activé
          </Option>
          <Option value={"Désactivé"} selected={!project.groupWords}>
            Désactivé
          </Option>
        </Select>
      </div>
      <div>
        <span>
          Utilisation du moteur d'IA :
        </span>
        <Select
          label="Utilisation du moteur d'IA"
          placeholder="Utilisation du moteur d'IA"
          onChange={(e) => { handleSubmit({ ...project, preferredEngine: e }) }}
          style={{
            marginTop: '1rem', marginBottom: '1rem', marginLeft: 'auto', marginRight: 'auto', minWidth: '100%'
          }}
        >
          <Option value={"Moteur 1"} selected={project.preferredEngine === "Moteur 1"}>
            Moteur 1
          </Option>
          <Option value={"Moteur 2"} selected={!project.preferredEngine || project.preferredEngine === "Moteur 2"}>
            Moteur 2
          </Option>
          <Option value={"Hybride"} selected={project.preferredEngine === "Hybride"}>
            Hybride
          </Option>
        </Select>
      </div>
    </div>}
  </div>
}

const Project = ({ project, onChange, onClickDelete }) => {
  const [name, setName] = useState(project.name)
  const [editing, setEditing] = useState(false)

  const handleCancelEdit = e => {
    setName(project.name)
    setEditing(false)
  }

  const handleChange = e =>
    setName(e.target.value)

  const handleSubmit = e => {
    e.preventDefault()
    onChange({ ...project, name })
    setEditing(false)
  }

  return (
    <StyledProject>
      <StyledForm as={editing ? 'form' : 'div'} onSubmit={handleSubmit}>
        <StyledName>
          <FolderIconWrapper>
            <FolderIcon />
          </FolderIconWrapper>
          <StyledInput type="text" value={name} onChange={handleChange} autoFocus={editing} readOnly={!editing} />
        </StyledName>
        {
          editing
            ? <>
              <StyledButton primary type="submit">
                <CheckIcon />
              </StyledButton>
              <StyledButton danger onClick={handleCancelEdit}>
                <CloseIcon />
              </StyledButton>
            </>
            : <>
              <StyledButton primary onClick={() => setEditing(true)}>
                <EditIcon />
              </StyledButton>
              <StyledButton danger onClick={onClickDelete}>
                <CloseCircleFilledIcon />
              </StyledButton>
            </>
        }
      </StyledForm>
      <AdvanceParam project={{ ...project }} handleSubmit={(projectUpdate) => { onChange({ ...project, ...projectUpdate }) }} />
      <StyledInfos>
        <Link to={`/manage/documents?projectId=${project.id}`}>
          {project.nbDocuments} documents
        </Link>
        {' / '}
        <Link to={`/manage/dictionaries?projectId=${project.id}`}>
          {project.nbDictionaries} dictionnaires associés
        </Link>
        {/*<AddTeamZone>
          <Select
            placeholder='Inviter une équipe au projet'
            onChange={() => {}}
          >
            {
              project.teams.map(
                ({ id, name }) => (
                  <Option key={id} value={id}>
                    {`Team: ${name}`}
                  </Option>
                )
              )
            }
          </Select>
        </AddTeamZone>
          */}
        <Ul>
          {
            project.teams && project.teams.map(
              team => (
                <StyledLi key={team.name}>
                  <AccountIconWrapper>
                    <AccountIcon />
                  </AccountIconWrapper>
                  {team.name}
                  <DeleteTeamWrapper>
                    <StyledButton
                      danger
                      onClick={() => console.log(team._id)}
                    >
                      <CloseCircleFilledIcon />
                    </StyledButton>
                  </DeleteTeamWrapper>
                </StyledLi>
              )
            )
          }
        </Ul>
      </StyledInfos>
    </StyledProject>
  )
}

Project.propTypes = {
  project: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
}

const StyledSection = styled(Section)`
  padding: 0 4%;
  flex: 1;
  margin-bottom: 1rem;
`

const StyledOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const StyledFilters = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledSearchInput = styled(QuerySearchInput)`
  width: 300px;
  margin-right: 1rem;
`

const EmptyHelp = styled.div`
  display: flex;
  margin: 2rem 0;
  justify-content: center;
  text-align: center;
`

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default () => {
  const nbItemsPerPage = 21
  const location = useLocation()
  const [onLoading, setOnLoading] = useState(true)
  const [query, setQuery] = useState(qs.parse(location.search.replace(/^\?/, '')))
  const [order, setOrder] = useState(['createdAt', 'asc'])
  const [activePageIndex, setActivePageIndex] = useState(1)

  const [onDuplicateError, setOnDuplicateError] = useState(false)

  const formRef = useRef(null)
  const [newProjectName, setNewProjectName] = useState('')
  const [toDeleteId, setToDeleteId] = useState(null)
  const [
    { items: projects },
    {
      setItem: setProject,
      setItems: setProjects,
      updateItem: updateProject,
      deleteItem: deleteProject,
    }
  ] = useList()

  const handleClickCreate = e => {
    if (e.preventDefault) {
      e.preventDefault()
    }

    if (!newProjectName || onDuplicateError) return

    services
      .createProject({ name: newProjectName.replace(' ', '_') })
      .then(({ project }) => {
        setProject(project)
        setNewProjectName('')
      })
  }

  const handleUpdate = (id, updates) => {
    services
      .updateProject(id, updates)
      .then(({ project }) => updateProject(project.id, project))
  }

  const handleClickDelete = id => setToDeleteId(id)

  const handleConfirmDelete = id => {
    services.deleteProject(id).then(() => deleteProject(id))
    setToDeleteId(null)
  }

  const handleCancelDelete = () =>
    setToDeleteId(null)

  useEffect(() => {
    services.fetchAllProjects().then(
      ({ projects }) => {
        setProjects(projects)
        setOnLoading(false)
      }
    )
  }, [])

  let _projects = projects
  if (Object.keys(query).length)
    _projects = projects.filter(project =>
      Object.keys(query).every(k =>
        project[k] && (project[k] + '').toLowerCase().includes(query[k].toLowerCase())
      )
    )

  const offset = (activePageIndex - 1) * nbItemsPerPage

  return (
    <>
      <H1 style={{ margin: '2rem 0' }}>Gérer les projets</H1>
      <StyledSection>
        <StyledOptions>
          <StyledFilters>
            <StyledSearchInput onChange={setQuery} />
            <Select onChange={order => setOrder(order)}>
              <Option value={['name', 'desc']} selected={['name', 'desc'].every((v, i) => v === order[i])}>{'Nom DESC (A -> Z)'}</Option>
              <Option value={['name', 'asc']} selected={['name', 'asc'].every((v, i) => v === order[i])}>{'Nom ASC (Z -> A)'}</Option>
              <Option value={['createdAt', 'asc']} selected={['createdAt', 'asc'].every((v, i) => v === order[i])}>{'Date de création (Récent -> Ancien)'}</Option>
              <Option value={['createdAt', 'desc']} selected={['createdAt', 'desc'].every((v, i) => v === order[i])}>{'Date de création (Ancien -> Récent)'}</Option>
            </Select>
          </StyledFilters>
          <Form onSubmit={handleClickCreate}>
            <TextInput ref={formRef}
              name="name"
              value={newProjectName}
              placeholder="Ajouter un projet"
              buttonIcon={AddCircleFilledIcon}
              onChange={projectName => {
                if (projects.map(({ name }) => name).includes(projectName)) {
                  setOnDuplicateError(true)
                } else {
                  setOnDuplicateError(false)
                }

                setNewProjectName(projectName)
              }}
              onClick={handleClickCreate}
            />
            {
              onDuplicateError && (
                <Error>Ce projet existe déja.</Error>
              )
            }
          </Form>
        </StyledOptions>
        {
          onLoading
            ? (
              <StyledLoading>
                <SpinnerAnimatedIcon />&nbsp;
                Chargement ...
              </StyledLoading>
            ) : (
              !projects.length
                ? (
                  <EmptyHelp>
                    <P>
                      Il semble que vous n'ayez aucun projet.<br />
                      Vous pouvez <StyledButton primary onClick={() => formRef.current.focus()}>ajouter un projet</StyledButton> afin d'y grouper vos documents et vos dictionnaires métiers.
                    </P>
                  </EmptyHelp>
                ) : (
                  _projects
                    .sort((a, b) => (
                      order[1] === 'asc'
                        ? (a[order[0]] > b[order[0]] ? -1 : 1)
                        : (a[order[0]] < b[order[0]] ? -1 : 1)
                    ))
                    .slice(offset, offset + nbItemsPerPage)
                    .map(project =>
                      <Project
                        key={`project-${project.id}`}
                        project={project}
                        onChange={updates => handleUpdate(project.id, updates)}
                        onClickDelete={() => handleClickDelete(project.id)}
                      />
                    )
                )
            )
        }
        <Pagination
          nbItemsPerPage={nbItemsPerPage}
          nbPagesDisplayed={5}
          activePageIndex={activePageIndex}
          totalItems={_projects.length}
          onChange={i => setActivePageIndex(i)}
        />
      </StyledSection>
      {toDeleteId &&
        <ConfirmationModal isOpen danger
          title="Êtes-vous sûr de vouloir faire ça ?"
          description="Supprimer un projet. Cette action est irreversible."
          onConfirm={() => handleConfirmDelete(toDeleteId)}
          onCancel={() => handleCancelDelete()}
        />
      }
    </>
  )
}
