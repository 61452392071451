import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { fabric } from 'fabric'

import * as services from '../services'

import useList from '../hooks/useList'
import FabricContext, { FabricProvider } from '../contexts/FabricContext'

import Canvas from '../modules/OCRCanvas'
import Toolbar from '../modules/OCRToolbar'
import Explorer from '../modules/OCRExplorer'
import Settings from '../modules/OCRSettings'
import Editor from '../modules/OCREditor/OCREditor'
import { upsertProfileNodeDetections } from '../services'

const StyledOCR = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  padding-top: 0;
  padding-left: 50px;

  ${({ orientation }) => orientation === 'horizontal' && `
    padding-top: 50px;
    padding-left: 0;
  `}

`

const OCRPage = () => {
  const [
    { canvas, canvasMode },
    { setCanvasMode, resetCanvas }
  ] = useContext(FabricContext)
  const [drawing, setDrawing] = useState(false)
  const [selectedDetection, setSelectedDetection] = useState(null)
  const [selectedDetectionHover, setSelectedDetectionHover] = useState(null)
  const [orientation, setOrientation] = useState('vertical')
  const [displayPanel, setDisplayPanel] = useState('explorer') // ['explorer', 'settings', null]
  const [displayZones, setDisplayZones] = useState(true)
  const [displayCorrected, setDisplayCorrected] = useState(true)
  const [displayHighScore, setDisplayHighScore] = useState(true)
  const [displayMidScore, setDisplayMidScore] = useState(true)
  const [displayLowScore, setDisplayLowScore] = useState(true)
  const [displayLowScoreFushia, setDisplayLowScoreFushia] = useState(true)
  const [displayPanelEditor, setDisplayPanelEditor] = useState(false)
  const [profiles, setProfiles] = useState([])
  const [canvasLoading, setCanvasLoading] = useState(false)
  const [projectsFetching, setProjectsFetching] = useState(true)
  const [currentProject, setCurrentProject] = useState(null)
  const [currentDocument, setCurrentDocument] = useState(null)
  const [currentProfileId, setCurrentProfileId] = useState(null)
  const [currentPage, setCurrentPage] = useState(null)
  const [deleteProfilNodeDetection, setDeleteProfilNodeDetection] = useState([])
  const [selectedProfilesNodes, setSelectedProfilesNodes] = useState([])
  const [visibleValue, setVisibleValue] = useState(false)
  const [{
    items: detections
  }, {
    setItem: setDetection,
    resetItems: resetDetections,
    updateItem: updateDetection,
    updateItems: updateDetections,
    deleteItem: deleteDetection,
  }] = useList()
  const [{
    items: projects
  }, {
    resetItems: setProjects,
  }] = useList()

  useEffect(() => {
    if (canvas) {
      let objectTexts = canvas.getObjects().filter(objectParam => {
        return objectParam.get("type") === 'textbox'
      })
      objectTexts.map(objectText => objectText.set({
        ...objectText,
        visible: visibleValue
      }));
      canvas.renderAll();
    }
  }, [visibleValue])

  useEffect(() => {
    let localStorageDetectedSelection = JSON.parse(localStorage.getItem("selectedDetection"));
    if (
      selectedDetection
      && currentProject?.id
      && currentDocument?.id
      && currentPage?.id
    ) {
      const localDetection = localStorageDetectedSelection ? localStorageDetectedSelection.findIndex(detectionLocal => {
        return detectionLocal.currentProject_id_local === currentProject.id &&
          detectionLocal.currentDocument_id_local === currentDocument.id &&
          detectionLocal.currentPage_id_local === currentPage.id
      }) : undefined;
      if (localDetection >= 0) {
        localStorageDetectedSelection[localDetection] = {
          currentProject_id_local: currentProject?.id,
          currentDocument_id_local: currentDocument?.id,
          currentPage_id_local: currentPage?.id,
          selectedDetection_local: selectedDetection[0]
        }
      } else {
        localStorageDetectedSelection = [
          ...(localStorageDetectedSelection || []), {
            currentProject_id_local: currentProject?.id,
            currentDocument_id_local: currentDocument?.id,
            currentPage_id_local: currentPage?.id,
            selectedDetection_local: selectedDetection[0]
          }]
      }
      localStorage.setItem("selectedDetection", JSON.stringify(localStorageDetectedSelection))
    }
  }, [selectedDetection])

  useEffect(() => {
    if (projectsFetching === false && projects.length > 0) {
      if (
        currentProject == null
        && currentDocument == null
        && currentPage == null
      ) {
        let localStorageDocumentSelection = JSON.parse(localStorage.getItem("currentDocument"))
        if (
          localStorageDocumentSelection &&
          localStorageDocumentSelection.currentProject &&
          localStorageDocumentSelection.currentDocument &&
          localStorageDocumentSelection.currentPage
        ) {
          const projectLocalStorage = projects.find(project => project.id === localStorageDocumentSelection.currentProject.id)
          const documentLocalStorage = projectLocalStorage && projectLocalStorage.documents?.find(document => document.id === localStorageDocumentSelection.currentDocument.id)
          const pageLocalStorage = documentLocalStorage && documentLocalStorage.pages?.find(page => page.id === localStorageDocumentSelection.currentPage.id)

          if (projectLocalStorage && documentLocalStorage && pageLocalStorage) {
            setCurrents(
              projectLocalStorage,
              documentLocalStorage,
              pageLocalStorage)
          }

        }
      } else {
        localStorage.setItem("currentDocument", JSON.stringify({
          currentProject: { id: currentProject.id },
          currentDocument: { id: currentDocument.id },
          currentPage: { id: currentPage.id }
        }))
      }
    }
  }, [projectsFetching, currentProject, currentDocument, currentPage])

  useEffect(() => {
    services
      .fetchAllProfiles()
      .then(({ profiles }) => setProfiles(profiles))
    // Here
    /*
    services
      .fetchAllDocuments()
      .then(({ documents }) => {
        const projects = documents.reduce((projects, document) => {
          let project = document.project || {}
          projects[project.id || 'undefined'] = {
            ...project,
            documents: [...(((projects[project.id || 'undefined']) || {}).documents || []), document]
          }

          return projects
        }, { 'undefined': { id: undefined, documents: [] } })
        setProjects(Object.keys(projects).map(id => projects[id]))
        setProjectsFetching(false)
      })
      */
    services.fetchAllProjects()
      .then(async ({ projects }) => {
        await Promise.all(projects.map(async (project) => {
          if (
            JSON.parse(localStorage.getItem("currentDocument"))
            && JSON.parse(localStorage.getItem("currentDocument")).currentProject
            && JSON.parse(localStorage.getItem("currentDocument")).currentProject.id
            && JSON.parse(localStorage.getItem("currentDocument")).currentProject.id === project.id
          ) {
            await services.fetchDocumentByAProject(project.id).then((response) => {
              const documentsOfProject = response.documents
              documentsOfProject.map(documentItem => {
                return { ...documentItem, project: null }
              })
              project.documents = documentsOfProject;
            })
          }
          return project
        }))
        setProjects(projects)
        setProjectsFetching(false)
      })
  }, [])

  useEffect(() => {
    let resultSelectedNodeCompute = []
    detections.map(detection => detection.profileNodes.map(pofileNode => {
      if (currentProfileId === pofileNode.profile) {
        resultSelectedNodeCompute.push({
          ...pofileNode,
          id: detection.id,
          nodeId: pofileNode.id,
          profileId: pofileNode.profile,
          value: detection.value.length > 0 && detection.value !== " " ? detection.value : detection.fuseDictionaryValue,
          visible: visibleValue
        });
      }
    }))
    setSelectedProfilesNodes(resultSelectedNodeCompute)
  }, [currentProfileId])

  useEffect(() => {
    FetchAndDrawDetections();
  }, [canvas, currentPage])

  useEffect(() => {
    if (!canvas) return

    canvas.getObjects().filter(objectParam => {
      return objectParam.get("type") != 'textbox'
    }).forEach(object =>
      object.set({
        stroke: displayZones ? object._stroke : '#fff',
        fill: displayZones ? 'transparent' : '#fff',
        selectable: displayZones,
        evented: displayZones
      })
    )
    canvas.requestRenderAll()
  }, [canvas, displayZones])

  useEffect(() => {
    if (!canvas) return

    const settings = { selectable: true, evented: true }
    const hidden = { stroke: 'transparent', selectable: false, evented: false }

    canvas
      .getObjects()
      .forEach(object => {
        if (object.detection.createdBy === 'Fusion')
          // couleur à changer
          object.set(
            displayLowScoreFushia ? { ...settings, stroke: '#ff33e3' } : hidden
          )
        else if (!object.detection || !!object.detection.editedValue)
          object.set(displayCorrected ? { ...settings, stroke: '#0f0' } : hidden)
        else if (object.detection && object.detection.ocrScore >= .75)
          object.set(displayHighScore ? { ...settings, stroke: '#00f' } : hidden)
        else if (object.detection && object.detection.ocrScore >= .50 && object.detection.ocrScore < .75)
          object.set(displayMidScore ? { ...settings, stroke: '#ff8c00' } : hidden)
        else {
          object.set(displayLowScore ? { ...settings, stroke: '#f00' } : hidden);
        }
      })

    canvas.requestRenderAll()
  }, [canvas, displayCorrected, displayHighScore, displayMidScore, displayLowScore, displayLowScoreFushia])

  useEffect(() => {
    if (selectedDetection) {
      setDisplayPanelEditor(true);
    }
  }, [selectedDetection])

  useEffect(() => {
    if (!canvas && !currentPage) return

    canvas.on('mouse:over', handleMouseHoverObject)
    canvas.on('mouse:out', (opt) => handleMouseHoverOutObject(opt, visibleValue))
    canvas.on('mouse:down', handleMouseDown)
    canvas.on('mouse:move', handleMouseMove)
    canvas.on('mouse:up', handleMouseUp)
    canvas.on('object:modified', handleObjectModified)
    canvas.on('selection:created', handleSelectionUpdated)
    canvas.on('selection:updated', handleSelectionUpdated)
    canvas.on('selection:cleared', handleSelectionCleared)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      canvas.off('mouse:over', handleMouseHoverObject)
      canvas.off('mouse:out', (opt) => handleMouseHoverOutObject(opt, visibleValue))
      canvas.off('mouse:down', handleMouseDown)
      canvas.off('mouse:move', handleMouseMove)
      canvas.off('mouse:up', handleMouseUp)
      canvas.off('object:modified', handleObjectModified)
      canvas.off('selection:created', handleSelectionUpdated)
      canvas.off('selection:updated', handleSelectionUpdated)
      canvas.off('selection:cleared', handleSelectionCleared)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [canvas, canvasMode, drawing, visibleValue, projects, projectsFetching, currentProject, currentDocument, currentPage, selectedDetection])

  const objectToDetection = object => ({ ...object.detection, object })

  const convertCoords = ({ top, left, width, height }) => ({
    x: width < 0 ? left - Math.abs(width) : left,
    y: height < 0 ? top - Math.abs(height) : top,
    width: Math.abs(width),
    height: Math.abs(height),
  })

  const FetchAndDrawDetections = (reRender, indexDetection) => {
    if (!canvas) return

    resetCanvas('select')
    if (currentPage && currentPage.id) {
      const top = new fabric.Point(0, 0)

      setCanvasLoading(true)

      if (!reRender) {
        canvas.absolutePan(top)
        canvas.setZoom(.25)
      }

      canvas.setBackgroundImage(
        `/api/pages/${currentPage.id}.${currentPage.ext}`,
        canvas.renderAll.bind(canvas)
      )

      services
        .fetchDetections(currentPage.id)
        .then(({ detections }) => {
          let detectionsListResult = detections.map(detection => {
            const getDetectionPreferredEngine = (detection) => {
              let result = detection.value
              if (detection.editedValue) {
                result = detection.editedValue
              } else {
                if (currentDocument.project.preferredEngine === 'Moteur 1') {
                  result = detection.ocrValueE1
                }
                if (currentDocument.project.preferredEngine === 'Moteur 2') {
                  result = detection.ocrValueE2
                }
                if (currentDocument.project.preferredEngine === 'Hybride') {
                  if (detection.ocrScoreE1 > detection.ocrScoreE2) {
                    result = detection.ocrValueE1
                  } else {
                    result = detection.ocrValueE2
                  }
                }
              }
              return {
                ...detection,
                value: result || " "
              }
            }
            return getDetectionPreferredEngine(detection)
          })

          resetDetections(detectionsListResult)
          drawDetections(detectionsListResult)
          setCanvasLoading(false)
          if (indexDetection) {
            selectNextDetection(indexDetection)
          } else {
            let localStorageDetectedSelection = JSON.parse(localStorage.getItem("selectedDetection"))
            if (
              localStorageDetectedSelection != null &&
              currentProject &&
              currentDocument &&
              currentPage
            ) {
              const listDetections = canvas.getObjects().filter(objectParam => {
                return objectParam.get("type") === 'rect'
              }).sort((a, b) => a.top - b.top)
              const localDetection = localStorageDetectedSelection.find(detectionLocal => {
                return detectionLocal.currentProject_id_local === currentProject.id &&
                  detectionLocal.currentDocument_id_local === currentDocument.id &&
                  detectionLocal.currentPage_id_local === currentPage.id
              })
              const selectedDetectionInList = localDetection ?
                listDetections.find(detection => localDetection.selectedDetection_local && detection.detection.id === localDetection.selectedDetection_local.id)
                : undefined
              if (selectedDetectionInList) {
                setSelectedDetection([selectedDetectionInList])
                canvas.setActiveObject(selectedDetectionInList)
              } else {
                setSelectedDetection([])
                canvas.setActiveObject(null)
              }
            }
          }
        })
    }
  }

  const drawDetections = detections => {
    if (!canvas) return

    let resultSelectedNodeCompute = [];

    detections?.filter(object => object.dictionaryFuse === null)
      .forEach(detection => {
        let stroke

        if (detection.createdBy === 'Fusion') {
          stroke = '#ff33e3'
        } else if (!!detection.editedValue) {
          stroke = '#0f0'
        } else if (detection.ocrScore >= .75) {
          stroke = '#00f'
        } else if (detection.ocrScore >= .50 && detection.ocrScore < .75) {
          stroke = '#ff8c00'
        } else {
          stroke = '#f00'
        }

        canvas.add(new fabric.Textbox(detection.value || detection.fuseDictionaryValue, {
          detection,
          fontFamily: 'Calibri',
          width: detection.width,
          height: detection.height,
          textAlign: 'center',
          originX: 'center',
          originY: 'center',
          backgroundColor: 'white',
          left: detection.x + detection.width / 2,
          top: detection.y - detection.height / 2,
          selectable: false,
          visible: visibleValue,
        }))

        canvas.add(new fabric.Rect({
          detection,
          left: detection.x,
          top: detection.y,
          width: detection.width,
          height: detection.height,
          stroke,
          _stroke: stroke,
          strokeWidth: 2,
          borderScaleFactor: 5,
          strokeUniform: true,
          noScaleCache: false,
          strokeUniform: true,
          fill: 'transparent',
          lockUniScaling: false,
        }))

        detection.profileNodes.map(pofileNode => {
          resultSelectedNodeCompute.push({
            ...pofileNode,
            id: detection.id,
            nodeId: pofileNode.id,
            profileId: pofileNode.profile,
            value: detection.value || detection.fuseDictionaryValue,
            visible: visibleValue
          });
        })
      })

    if (resultSelectedNodeCompute[0]?.profileId) {
      setCurrentProfileId(resultSelectedNodeCompute[0].profileId)
      setSelectedProfilesNodes(resultSelectedNodeCompute)
    } else {
      setCurrentProfileId([])
    }
    canvas.requestRenderAll()
  }

  const handleMouseHoverObject = function (opt) {
    if (canvas && opt.target?.get("type") === 'rect' && opt.target.detection) {
      setSelectedDetectionHover(opt.target.detection);
      let objectText = canvas.getObjects().find(objectParam => {
        return objectParam.get("type") === 'textbox' && opt.target.detection.id === objectParam.detection.id
      })
      objectText.set({
        ...objectText,
        visible: true
      });
      canvas.renderAll();
    }
  }

  const handleMouseHoverOutObject = function (opt, visibleValueParam) {
    setSelectedDetectionHover(null);
    if (canvas && opt.target?.get("type") === 'rect' && opt.target.detection) {
      let objectText = canvas.getObjects().find(objectParam => {
        return objectParam.get("type") === 'textbox' && opt.target.detection.id === objectParam.detection.id
      })
      objectText?.set({
        ...objectText,
        visible: visibleValueParam
      });
      canvas.renderAll();
    }
  }

  const handleMouseDown = function (opt) {
    const e = opt.e

    if (canvasMode === 'nav') {
      this.isDragging = true
      this.selection = false
      this.lastPosX = e.clientX
      this.lastPosY = e.clientY
    } else if (canvasMode === 'edit') {
      if (canvas.getActiveObject()) return

      setDrawing(true)

      this.x1 = opt.absolutePointer.x
      this.y1 = opt.absolutePointer.y
      const object = new fabric.Rect({
        left: this.x1,
        top: this.y1,
        width: 0,
        height: 0,
        stroke: '#0f0',
        _stroke: '#0f0',
        strokeWidth: 2,
        borderScaleFactor: 5,
        strokeUniform: true,
        noScaleCache: false,
        strokeUniform: true,
        fill: 'transparent',
        lockUniScaling: false,
      })

      canvas.add(object)
      canvas.requestRenderAll()
      canvas.setActiveObject(object)
    }
  }

  const handleMouseMove = function (opt) {
    if (canvasMode === 'edit' && drawing) {
      const x2 = opt.absolutePointer.x,
        y2 = opt.absolutePointer.y,
        width = x2 - this.x1,
        height = y2 - this.y1

      var object = canvas.getActiveObject()
      object.set({ width, height }).setCoords()
      canvas.getObjects().map(object => {
        object.setCoords()
      })
      canvas.calcOffset()
      canvas.renderAll()
    }
  }

  const handleMouseUp = function (opt) {

    if (canvasMode === 'edit' && drawing) {
      const x2 = opt.absolutePointer.x,
        y2 = opt.absolutePointer.y
      if (this.x1 != x2 && this.y1 != y2) {
        const width = x2 - this.x1,
          height = y2 - this.y1

        var object = canvas.getActiveObject()
        object.set({ width, height }).setCoords()

        canvas.requestRenderAll()
        handleObjectCreated(opt)
      }
      setDrawing(false)
    }
  }

  const handleObjectCreated = function (opt) {
    const object = opt.target
    const { x, y, width, height } = convertCoords(object.getBoundingRect(true))

    services
      .createDetection({
        page: currentPage.id,
        x, y, width, height
      }).then(({ detection }) => {
        object.detection = detection
        setDetection(detection)
        handleSelectionUpdated(opt)
      })
  }

  const handleObjectModified = function (opt) {
    const objects = opt.target._objects || [opt.target]

    objects.forEach(object => {
      const { x, y, width, height } = object.group ?
        (object => {
          const { tl, br } = object.aCoords
          const matrix = object.group.calcTransformMatrix()

          const { x, y } = fabric.util.transformPoint(tl, matrix)
          const { x: x2, y: y2 } = fabric.util.transformPoint(br, matrix)

          const width = x2 - x, height = y2 - y
          return { x, y, width, height }
        })(object)
        : convertCoords(object.getBoundingRect(true))

      if (objects[0]) {

        setSelectedDetection({
          x: objects[0]?.lineCoords?.bl?.x,
          y: objects[0]?.lineCoords?.bl?.y,
          value: (
            objects[0]?.detection?.fuseDictionaryValue ||
            objects[0]?.detection?.value
          ),
          id: objects[0]?.detection?.id
        })
      }

      let objectText = canvas.getObjects().find(objectParam => {
        if (objectParam.get("type") === 'rect') {

        }
        return objectParam.get("type") === 'textbox' && objectParam.detection?.id === object.detection?.id
      })
      objectText?.set({
        ...objectText,
        width: width,
        height: height,
        left: x + width / 2,
        top: y - height / 2,
        visible: visibleValue
      });

      handleDetectionCoordsChange({
        ...objectToDetection(object),
        x, y, width, height,
      })
    })
  }

  const handleObjectsDelete = objects => {
    objects.forEach(object => {
      services
        .deleteDetection(object.detection.id)
        .then(() => {
          deleteDetection(object.detection.id)
          canvas.remove(object)
          canvas.remove(canvas.getObjects().find(objectParam => {
            return objectParam.get("type") === 'textbox' && objectParam.detection?.id === object.detection?.id
          }))
          setSelectedProfilesNodes(selectedProfilesNodes.filter(node => node.id != object.detection.id))
        })
    })
    if (objects.length === 1) {
      selectNextDetection(null, true)
    }
    canvas.requestRenderAll()
  }

  const handleSelectionUpdated = function (opt) {
    const objects = (opt.target._objects || [opt.target])
      .filter(o => o.detection && o.detection.id)
      .sort((a, b) => a.left - b.left)
    if (objects) {
      setSelectedDetection(objects.map(object => {
        return {
          x: object?.oCoords?.bl?.x,
          y: object?.oCoords?.bl?.y,
          value: object?.detection?.fuseDictionaryValue || object?.detection?.value,
          id: object?.detection?.id
        }
      }))
    } else {
      setDisplayPanelEditor(false)
    }
    updateDetections({ selected: false, object: null })
    objects.forEach(object => {
      updateDetection(object.detection.id, { selected: true, object })
    })
  }

  const handleSelectionCleared = function (opt) {
    setSelectedDetection(null)
    updateDetections({ selected: false, object: null })
  }

  const selectNextDetection = (index, nextOrPrevious) => {
    canvas.absolutePan({
      x: canvas.backgroundImage.aCoords.tl.x,
      y: canvas.backgroundImage.aCoords.tl.y
    })
    canvas.getObjects().map(object => {
      object.setCoords()
    })
    canvas.calcOffset()
    canvas.renderAll()
    let item = canvas.getActiveObject()
    if (item != null && item.id && item?.detection == null) {
      item = { ...item, detection: { id: item.id } }
    }
    const listDetections = canvas.getObjects().filter(objectParam => {
      return objectParam.get("type") === 'rect'
    }).sort((a, b) => a.top - b.top)

    const indexItemInListDetection = index || listDetections.findIndex(objectParam => objectParam.detection.id === item?.detection.id);
    let indexOfNextDetection = 0
    if (nextOrPrevious === true || !nextOrPrevious) {
      indexOfNextDetection = indexItemInListDetection > -1 && indexItemInListDetection + 1 < listDetections.length ? indexItemInListDetection + 1 : 0;
    }
    if (nextOrPrevious === false) {
      indexOfNextDetection = indexItemInListDetection > 0 ? indexItemInListDetection - 1 : listDetections.length - 1;
    }

    if (listDetections.length > 0) {
      canvas.absolutePan({
        x: listDetections[indexOfNextDetection].oCoords.tl.x - canvas.getWidth() * 0.10,
        y: listDetections[indexOfNextDetection].oCoords.tl.y - canvas.getHeight() * 0.20
      })
      canvas.getObjects().map(object => {
        object.setCoords()
      })
      canvas.calcOffset()
      canvas.renderAll()
      canvas.setActiveObject(listDetections[indexOfNextDetection])
    }
    canvas.renderAll()
  }

  const handleKeyUp = function (e) {
    console.log('e.keyCode : ', e.keyCode);
    switch (e.keyCode) {
      case 46:
        if (e.ctrlKey) {
          const group = canvas.getActiveObject()
          handleObjectsDelete(group && group._objects || [group])
        }
        break;
      case 187:
        if (e.ctrlKey) {
          upCanvasZoom(+0.025, { x: canvas.getActiveObject().oCoords.tl.x, y: canvas.getActiveObject().oCoords.tl.y })
        }
        break;
      case 189:
        if (e.ctrlKey) {
          upCanvasZoom(-0.025, { x: canvas.getActiveObject().oCoords.tl.x, y: canvas.getActiveObject().oCoords.tl.y })
        }
        break;
      case 107:
        if (e.altKey) {
          upCanvasZoom(+0.025, { x: canvas.getActiveObject().oCoords.tl.x, y: canvas.getActiveObject().oCoords.tl.y })
        }
        break;
      case 109:
        if (e.altKey) {
          upCanvasZoom(-0.025, { x: canvas.getActiveObject().oCoords.tl.x, y: canvas.getActiveObject().oCoords.tl.y })
        }
        break;
      case 9:
        if (e.shiftKey) {
          selectNextDetection(null, false)
        } else {
          selectNextDetection(null, true);
        }
        break;
      case 80:
        if (e.altKey && !selectedDetection) {
          if (e.shiftKey) {
            console.log('goToPrevPage')
            goToPrevPage()
          } else {
            console.log('goToNextPage')
            goToNextPage()
          }
        }
        break;
      default:
        return false
    }
  }

  const handleDetectionCoordsChange = detection => {
    const { id, x, y, width, height } = detection

    services
      .updateDetection(id, { x, y, width, height })
      .then(({ detection: updates }) => updateDetection(id, updates))
  }

  const handleDetectionValueChange = (detection, indexDetection) => {
    const { id, value } = detection[0]
    services
      .updateDetection(id, { value: detection[0].value, page: currentPage.id })
      .then(({ detection: updates }) => {
        updateDetection(id, updates)
        if (value) {
          if (Array.isArray(detection)) {
            detection.map(detectionItem => {
              canvas.getObjects().find(objectItem => objectItem.detection?.id === detectionItem.id && objectItem.get("type") === 'rect')?.set({
                detection: detectionItem,
                stroke: '#0f0',
                _stroke: '#0f0'
              })
            })
          } else {
            detection?.set({ stroke: '#0f0', _stroke: '#0f0' })
          }
        }
        FetchAndDrawDetections(true, indexDetection)
        canvas.requestRenderAll()
      })
  }

  const fitCanvas = () => {
    const top = new fabric.Point(0, 0)
    canvas.absolutePan(top)
    canvas.setZoom(.25)
  }

  const upCanvasZoom = (v, point) => {
    var zoom = canvas.getZoom()
    zoom += v
    if (zoom > 5) zoom = 5
    if (zoom < .1) zoom = .1
    let center = null
    if (point && point.x && point.y) {
      center = new fabric.Point(point.x, point.y)
    } else {
      center = new fabric.Point(canvas.width / 2, canvas.height / 2)
    }
    canvas.zoomToPoint(center, zoom)
  }

  const goToPrevPage = () => {
    if (!currentDocument) return

    const index = currentDocument.pages.findIndex(p => p.id === currentPage.id) - 1
    if (index >= 0) {
      setCurrentPage(currentDocument.pages[index])
    }
    else {
      const indexCurrentDoc = currentProject.documents.findIndex(item => item.id === currentDocument._id)
      if ((indexCurrentDoc) > 0) {
        setCurrents(currentProject, currentProject.documents[indexCurrentDoc - 1], currentProject.documents[indexCurrentDoc - 1].pages[0])
      }
    }
  }

  const goToNextPage = () => {
    console.log('currentDocument : ', currentDocument)
    if (!currentDocument) return

    const index = currentDocument.pages.findIndex(p => p.id === currentPage.id) + 1
    if (index < currentDocument.pages.length) {
      setCurrentPage(currentDocument.pages[index])
    }
    else {
      const indexCurrentDoc = currentProject.documents.findIndex(item => item.id === currentDocument._id)
      if ((indexCurrentDoc + 1) < currentProject.documents.length) {
        setCurrents(currentProject, currentProject.documents[indexCurrentDoc + 1], currentProject.documents[indexCurrentDoc + 1].pages[0])
      }
    }
  }

  const setCurrents = (project, document, page) => {
    setCurrentProject(project)
    setCurrentDocument(document)
    setCurrentPage(page)
    setSelectedProfilesNodes([])
  }

  const onProfileNodeChange = (profileNode, detections) => {
    setCanvasLoading(true)

    let profileNodeToUpdateList = [];

    profileNode.forEach(profileNodeItem => {
      if (profileNodeItem._id && profileNodeToUpdateList.findIndex(profileNodeToUpdate => profileNodeToUpdate._id === profileNodeItem._id) >= 0) {

        profileNodeToUpdateList[profileNodeToUpdateList.findIndex(profileNodeToUpdate => profileNodeToUpdate._id === profileNodeItem._id)].detections = [
          ...profileNodeToUpdateList.find(profileNodeToUpdate => profileNodeToUpdate._id === profileNodeItem._id).detections || [],
          { id: profileNodeItem.id, index: profileNodeItem.index }
        ]
      }
      if (profileNodeItem._id && !(profileNodeToUpdateList.findIndex(profileNodeToUpdate => profileNodeToUpdate._id === profileNodeItem._id) >= 0)) {
        profileNodeToUpdateList.push({ ...profileNodeItem, id: undefined, detections: [{ id: profileNodeItem.id, index: profileNodeItem.index || 0 }] })
      }
      if (!profileNodeItem._id && profileNodeToUpdateList.findIndex(profileNodeToUpdate => profileNodeToUpdate.profileId === profileNodeItem.profileId && profileNodeToUpdate.nodeId === profileNodeItem.nodeId) >= 0) {
        profileNodeToUpdateList[profileNodeToUpdateList.findIndex(profileNodeToUpdate => profileNodeToUpdate.profileId === profileNodeItem.profileId && profileNodeToUpdate.nodeId === profileNodeItem.nodeId)].detections = [
          ...profileNodeToUpdateList.find(profileNodeToUpdate => profileNodeToUpdate.profileId === profileNodeItem.profileId && profileNodeToUpdate.nodeId === profileNodeItem.nodeId).detections || [],
          { id: profileNodeItem.id, index: profileNodeItem.index || profileNodeToUpdateList.find(profileNodeToUpdate => profileNodeToUpdate.profileId === profileNodeItem.profileId && profileNodeToUpdate.nodeId === profileNodeItem.nodeId).detections.length || 0 }
        ]
      }
      if (!profileNodeItem._id && !(profileNodeToUpdateList.findIndex(profileNodeToUpdate => profileNodeToUpdate.profileId === profileNodeItem.profileId && profileNodeToUpdate.nodeId === profileNodeItem.nodeId) >= 0)) {
        profileNodeToUpdateList.push({ ...profileNodeItem, id: undefined, detections: [{ id: profileNodeItem.id, index: profileNodeItem.index || 0 }] })
      }
    })

    profileNodeToUpdateList = profileNodeToUpdateList.map(profileNodeItem => {
      return {
        ...profileNodeItem,
        page: currentPage.id,
        profileNode: profileNodeItem.nodeId
      }
    })
    profileNodeToUpdateList = [{ detections }, ...(profileNodeToUpdateList || [])];
    let updatedSelectedProfilesNodes = [];
    Promise.all(profileNodeToUpdateList?.map((profileNodeToUpdateItem) => {
      return upsertProfileNodeDetections(profileNodeToUpdateItem)
    })).then((response) => {
      detections = response[response.length - 1].detections
      if (detections) {
        resetDetections(detections)
        drawDetections(detections)

        detections
          .filter(object => object.dictionaryFuse === null)
          .forEach(detection => {

            let resultSelectedNodeCompute = [];
            detection.profileNodes.map(pofileNode => {
              resultSelectedNodeCompute.push({
                ...pofileNode,
                id: detection.id,
                nodeId: pofileNode.id,
                profileId: pofileNode.profile,
                value: detection.value
              });
            })

            if (resultSelectedNodeCompute[0]?.profileId) {
              setCurrentProfileId(resultSelectedNodeCompute[0].profileId)
              updatedSelectedProfilesNodes = [...updatedSelectedProfilesNodes, resultSelectedNodeCompute]
              setSelectedProfilesNodes([...selectedProfilesNodes, ...updatedSelectedProfilesNodes])
            }
          })
      }
    })
    setDeleteProfilNodeDetection([])
    setCanvasLoading(false)
  }

  return (
    <StyledOCR orientation={orientation}>
      <Canvas
        loading={canvasLoading}
        orientation={orientation}
        displayPanel={!!displayPanel}
        currentDocument={currentDocument}
        currentPage={currentPage}
        selectedDetection={selectedDetection}
        detections={detections}
        handleDetectionValueChange={handleDetectionValueChange}
        profile={profiles.find(profile => profile.id === currentProfileId)}
        selectedProfilesNodes={selectedProfilesNodes}
        setSelectedProfilesNodes={setSelectedProfilesNodes}
        handleSelectionCleared={handleSelectionCleared}
        FetchAndDrawDetections={() => FetchAndDrawDetections(true)}
        setDisplayPanelEditor={setDisplayPanelEditor}
        displayPanelEditor={displayPanelEditor}
        deleteProfilNodeDetection={deleteProfilNodeDetection}
        setDeleteProfilNodeDetection={setDeleteProfilNodeDetection}
        selectNextDetection={selectNextDetection}
      />
      <Editor
        selectedDetectionHover={selectedDetectionHover}
        selectedDetection={selectedDetection}
        setSelectedDetection={setSelectedDetection}
        canvasLoading={canvasLoading}
        setCanvasLoading={setCanvasLoading}
        canvas={canvas}
        active={displayPanelEditor}
        orientation={orientation}
        profiles={profiles}
        onSetCurrentProfileId={setCurrentProfileId}
        currentProfileId={currentProfileId}
        onProfileNodeChange={onProfileNodeChange}
        onClickClose={() => { setDisplayPanelEditor(false); canvas.discardActiveObject() }}
        profile={profiles.find(profile => profile.id === currentProfileId)}
        setSelectedProfilesNodes={setSelectedProfilesNodes}
        selectedProfilesNodes={selectedProfilesNodes}
        deleteProfilNodeDetection={deleteProfilNodeDetection}
        setDeleteProfilNodeDetection={setDeleteProfilNodeDetection}
      />
      <Toolbar
        canvasMode={canvasMode}
        orientation={orientation}
        displayExplorer={displayPanel === 'explorer'}
        displaySettings={displayPanel === 'settings'}
        displayZones={displayZones}
        displayCorrected={displayCorrected}
        displayHighScore={displayHighScore}
        displayMidScore={displayMidScore}
        displayLowScore={displayLowScore}
        displayLowScoreFushia={displayLowScoreFushia}
        visibleValue={visibleValue}
        onClickOrientation={() => setOrientation(orientation === 'horizontal' ? 'vertical' : 'horizontal')}
        onClickExplorer={() => setDisplayPanel(displayPanel === 'explorer' ? null : 'explorer')}
        onClickSettings={() => setDisplayPanel(displayPanel === 'settings' ? null : 'settings')}
        onClickEdit={() => setCanvasMode('edit')}
        onClickSelect={() => setCanvasMode(canvasMode === 'select' ? 'edit' : 'select')}
        onClickNaviguate={() => setCanvasMode(canvasMode === 'nav' ? 'edit' : 'nav')}
        onClickFit={() => fitCanvas()}
        onClickZoomIn={() => upCanvasZoom(+0.025)}
        onClickZoomOut={() => upCanvasZoom(-0.025)}
        onClickPrevPage={() => goToPrevPage()}
        onClickNextPage={() => goToNextPage()}
        onClickDisplayZones={() => setDisplayZones(!displayZones)}
        onClickDisplayCorrected={() => setDisplayCorrected(!displayCorrected)}
        onClickDisplayHighScore={() => setDisplayHighScore(!displayHighScore)}
        onClickDisplayMidScore={() => setDisplayMidScore(!displayMidScore)}
        onClickDisplayLowScore={() => setDisplayLowScore(!displayLowScore)}
        onClickDisplayLowScoreFushia={() => setDisplayLowScoreFushia(!displayLowScoreFushia)}
        onClickVisibleValue={() => setVisibleValue(!visibleValue)}
      />
      <Explorer
        loading={projectsFetching}
        active={displayPanel === 'explorer'}
        orientation={orientation}
        projects={projects}
        setProjects={setProjects}
        currentProject={currentProject}
        currentDocument={currentDocument}
        currentPage={currentPage}
        onClickPage={setCurrents}
        onClickClose={() => setDisplayPanel(null)}
      />
      <Settings
        active={displayPanel === 'settings'}
        orientation={orientation}
        onClickClose={() => setDisplayPanel(null)}
        onChangeOrientation={orientation => setOrientation(orientation)}
      />
    </StyledOCR>
  )
}

export default () => (
  <FabricProvider>
    <OCRPage />
  </FabricProvider>
)
