import React, { useState } from 'react'
import styled from 'styled-components'
import { getAssetsRootURL } from '../utils'

import {
  P,
  Button
} from '../components'

const Children = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const Advert = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
`

const StyledVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: auto;

  ${({ advert }) => `
    ${Advert} { display: ${advert ? 'flex' : 'none'}; }
    ${Children} { display: ${advert ? 'none' : 'flex'}; }

    @media (min-width: 768px) {
      ${Advert} { display: none; }
      ${Children} { display: flex; }
    }
  `}
`

const StyledP = styled(P)`
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  margin-bottom: 1rem;
`

export default ({ children }) => {
  const [advert, setAdvert] = useState(true)

  return (
    <Wrapper advert={advert}>
      <Advert>
        <StyledVideo
          autoPlay
          loop
          muted
          id='background-video'
          poster={getAssetsRootURL(true) + '/images/video-poster.jpg'}
        >
          <source
            src={getAssetsRootURL(true) + '/videos/NeurOCR.mp4'}
            type='video/mp4'
          />
        </StyledVideo>
        <StyledP>
          Cette page n'est pas optimisé pour cette taille d'écran.
        </StyledP>
        <Button primary onClick={() => setAdvert(false)}>Afficher quand même !</Button>
      </Advert>

      <Children>
        {children}
      </Children>
    </Wrapper>
  )
}
