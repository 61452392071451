import React, { useContext } from 'react'
import styled from 'styled-components'
import { getAssetsRootURL } from '../utils'

import AuthContext from '../contexts/AuthContext'

import { Link, H3 } from '../components'

const StyledVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`

const DashboardContainer = styled.div`
  padding: 0 4%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const StyledH3 = styled(H3)`
  text-align: center;
  color: white;
`

const StyledLink = styled(Link)`
  text-align: center;
`

const Logo = styled.img`
  margin: 0 auto;
  margin-bottom: 2.5rem;
  width: 9.375rem;
  filter: invert(100%);
  user-select: none;
`

export default () => {
  const [{ user }, { logout }] = useContext(AuthContext)

  return (
    <>
      <StyledVideo
        autoPlay
        loop
        muted
        id='background-video'
        poster={getAssetsRootURL(true) + '/images/video-poster.jpg'}
      >
        <source
          src={getAssetsRootURL(true) + '/videos/NeurOCR.mp4'}
          type='video/mp4'
        />
      </StyledVideo>
      <DashboardContainer>
        <Logo style={{ filter: 'brightness(100%)', width: '20rem' }} src='/images/neurOCR-logo-only.svg' />
        <StyledH3 style={{ margin: '2rem 0' }}>
          Bonjour {user.firstname}
        </StyledH3>
        <StyledLink to="/logout">Ce n'est pas moi</StyledLink>
      </DashboardContainer>
    </>
  )
}
