import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'

import useList from '../hooks/useList'
import * as services from '../services'

import {
  Section,
  Row,
  H1,
  H4,
  H5,
  P,
  Vr,
  Form,
  Select,
  Option,
  Checkbox,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
  TdForm,
  Icon,
  EyeIcon,
  CrossIcon,
  CloseCircleFilledIcon,
  SpinnerAnimatedIcon,
  TrashIcon,
  Pagination,
  Link,
  Button,
  BlankButton,
  ConfirmationModal,
} from '../components'

import QuerySearchInput from '../modules/QuerySearchInput'

const StyledPage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`

const StyledSection = styled(Section)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding: 0 4%;
  margin-bottom: ${({ addMarginBottom }) => addMarginBottom};
`

const StyledFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const StyledTable = styled(Table)`
  width: 100%;

  a {
    color: ${({ theme }) => theme.black};

    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: underline;
    }
  }
`

const LTh = styled(Th)`
  width: 100%;
`

const LTd = styled(Td)`
  width: 100%;
`

const TdEyeIcon = styled(Td)`
  padding-left: .5rem;
  padding-right: .5rem;
`

const RoundedEyeIcon = styled(EyeIcon)`
  border-radius: 50%;
  padding: .25rem;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primaryColor};
`

const DeleteButton = styled(BlankButton)`
  color: ${({ theme }) => theme.dangerRed};
`

const StyledEditor = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  box-shadow: 0px -8px 10px 0px rgba(170,170,170,1);
  background-color: ${({ theme }) => theme.white};
`

const CloseButton = styled(BlankButton)`
  position: absolute;
  top: .75rem;
  right: .75rem;
  color: ${({ theme }) => theme.primaryColor};

  ${Icon} {
    width: .75rem;
    height: .75rem;
  }
`

const StyledH4 = styled(H4)`
  text-align: center;
`

const StyledH5 = styled(H5)`
  margin-bottom: .75rem;
`

const StyledRow = styled(Row)`
  display: flex;
`

const StyledColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmptyHelp = styled.div`
  display: flex;
  margin: 2rem 0;
  justify-content: center;
  text-align: center;
`

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledFiltersOption = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;
  margin: 0 0 0 1rem;
`

const StyledSelect = styled(Select)`
  margin-left: 0.5rem;
`

export default () => {
  const ref = useRef(null)
  const [editorHeight, setEditorHeight] = useState(0)
  const [onLoading, setOnLoading] = useState(true)
  const [query, setQuery] = useState({})
  const [filterOptionProject, setFilterOptionProject] = useState('all')
  const [filterOptionExtension, setFilterOptionExtension] = useState('all')
  const [order, setOrder] = useState(['createdAt', 'asc'])
  const [activePageIndex, setActivePageIndex] = useState(1)
  const [nbItemsPerPage, setNbItemsPerPage] = useState(20)
  const [toDeleteIds, setToDeleteIds] = useState([])
  const [projects, setProjects] = useState([])
  const [
    { items: documents },
    {
      setItems: setDocuments,
      updateItem: updateDocument,
      updateItems: updateDocuments,
      deleteItem: deleteDocument,
    }
  ] = useList()

  const selectDocuments = selected =>
    updateDocuments({ selected })

  const selectDocument = (id, selected) =>
    updateDocument(id, { selected })

  const toggleOrder = by => {
    if (order[0] === by) {
      return setOrder([by, order[1] === 'asc' ? 'desc' : 'asc'])
    }

    setOrder([by, 'desc'])
  }

  const handleNameUpdate = (id, name) => {
    services
      .updateDocument(id, { name })
      .then(({ document }) => updateDocument(document.id, document))
  }

  const handleChangeProjectSelection = project => {
    _selections.forEach(({ id }) =>
      services
        .updateDocument(id, { project })
        .then(({ document }) => updateDocument(document.id, document))
    )
  }

  const handleClickDelete = ({ id }) =>
    setToDeleteIds([id])

  const handleClickDeleteSelection = () =>
    setToDeleteIds(_selections.map(({ id }) => id))

  const handleConfirmDelete = ids => {
    ids.forEach(id => services.deleteDocument(id).then(() => deleteDocument(id)))
    setToDeleteIds([])
  }

  const handleCancelDelete = () =>
    setToDeleteIds([])

  useEffect(() => {
    Promise.all([
      services.fetchOCRizedDocuments(),
      services.fetchAllProjects()
    ]).then(values => {
      setDocuments(values[0].documents)
      setProjects(values[1].projects)
      setOnLoading(false)
    })
  }, [])

  useEffect(() => {
    if (ref.current)
      setEditorHeight(ref.current.clientHeight)
  })

  let _documents = documents
  if (Object.keys(query).length)
    _documents = documents.filter(doc =>
      Object.keys(query).every(k =>
        query[k] ? doc[k] && (doc[k] + '').toLowerCase().includes(query[k].toLowerCase()) : doc[k]
      )
    )

  let _selections = _documents.filter(doc => doc.selected)
  const offset = (activePageIndex - 1) * nbItemsPerPage

  const getProjectOption = (filterOptionProject) => {
    return [
      <Option key={'keyAll'} value={'all'} selected={!filterOptionProject || filterOptionProject === 'all'}>
        Tous les projets
      </Option>,
      ...(projects
        .map(project => <Option
          key={'key-' + project.name}
          value={project.name}
          selected={project.name === filterOptionProject}
        >
          {project.name}
        </Option>
        ))
    ]
  }

  const getDocumentExtOption = (filterOptionExtension, documents) => {
    let extentionList = []
    documents.map(document => {
      if (!extentionList.find(extention => extention === document.ext)) {
        extentionList.push(document.ext)
      }
    })
    return [
      <Option key={'keyAll'} value={'all'} selected={!filterOptionExtension || filterOptionExtension === 'all'}>Toutes les extensions</Option>,
      ...(extentionList
        .map(extention => (
          <Option
            key={'key-' + extention}
            value={extention}
            selected={filterOptionExtension === extention}
          >
            {extention.toUpperCase()}
          </Option>
        )
        )
      )
    ]
  }

  return (
    <>
      <StyledPage>
        <H1 style={{ margin: '2rem 0' }}>Gérer les documents</H1>
        <StyledSection
          addMarginBottom={_selections.length > 0 ? editorHeight + 'px' : '2rem'}
        >
          <StyledFilters>
            <QuerySearchInput onChange={setQuery} />
            <StyledFiltersOption>
              <StyledSelect
                onChange={project => {
                  if (project != 'all') {
                    setQuery({ ...query, projectName: project })
                    setFilterOptionProject(project)
                  } else {
                    setQuery({ ...query, projectName: undefined })
                    setFilterOptionProject('all')
                  }
                }}
              >
                {[...getProjectOption(filterOptionProject)]}
              </StyledSelect>
              <StyledSelect
                onChange={documentExt => {
                  if (documentExt != 'all') {
                    setQuery({ ...query, ext: documentExt })
                    setFilterOptionExtension(documentExt)
                  } else {
                    setQuery({ ...query, ext: undefined })
                    setFilterOptionExtension('all')
                  }
                }}
              >
                {[...getDocumentExtOption(filterOptionExtension, documents)]}
              </StyledSelect>
              <StyledSelect placeholder={'Autre'} onChange={order => setOrder(order)}>
                <Option value={['name', 'desc']} selected={['name', 'desc'].every((v, i) => v === order[i])}>{'Nom DESC (A -> Z)'}</Option>
                <Option value={['name', 'asc']} selected={['name', 'asc'].every((v, i) => v === order[i])}>{'Nom ASC (Z -> A)'}</Option>
                <Option value={['createdAt', 'asc']} selected={['createdAt', 'asc'].every((v, i) => v === order[i])}>{'Date de création (Récent -> Ancien)'}</Option>
                <Option value={['createdAt', 'desc']} selected={['createdAt', 'desc'].every((v, i) => v === order[i])}>{'Date de création (Ancien -> Récent)'}</Option>
              </StyledSelect>
              <StyledSelect onChange={v => setNbItemsPerPage(v)}>
                <Option value={20} selected={nbItemsPerPage === 20}>Afficher 20 lignes</Option>
                <Option value={50} selected={nbItemsPerPage === 50}>Afficher 50 lignes</Option>
                <Option value={100} selected={nbItemsPerPage === 100}>Afficher 100 lignes</Option>
              </StyledSelect>
            </StyledFiltersOption>
          </StyledFilters>
          {
            onLoading
              ? (
                <StyledLoading>
                  <SpinnerAnimatedIcon />&nbsp;
                  Chargement ...
                </StyledLoading>
              ) : (
                <>
                  <StyledTable>
                    <THead>
                      <Tr>
                        <Th>
                          <Checkbox
                            checked={_documents.every(d => d.selected)}
                            onChange={selected => selectDocuments(selected)}
                          />
                        </Th>
                        <Th><EyeIcon /></Th>
                        <LTh
                          onClickOrder={() => toggleOrder('name')}
                          order={order[0] === 'name' ? order[1] : null}
                        >
                          Nom
                        </LTh>
                        <Th
                          onClickOrder={() => toggleOrder('projectName')}
                          order={order[0] === 'projectName' ? order[1] : null}
                        >
                          Projet
                        </Th>
                        <Th
                          onClickOrder={() => toggleOrder('ext')}
                          order={order[0] === 'ext' ? order[1] : null}
                        >
                          Type
                        </Th>
                        <Th
                          onClickOrder={() => toggleOrder('createdAt')}
                          order={order[0] === 'createdAt' ? order[1] : null}
                        >
                          Ajouté le
                        </Th>
                        <Th
                          onClickOrder={() => toggleOrder('updatedAt')}
                          order={order[0] === 'updateAt' ? order[1] : null}
                        >
                          Modifié le
                        </Th>
                        <Th
                          onClickOrder={() => toggleOrder('ocrScore')}
                          order={order[0] === 'ocrScore' ? order[1] : null}
                        >
                          % OCR
                        </Th>
                        <Th><CloseCircleFilledIcon /></Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {
                        _documents
                          .sort((a, b) => (
                            order[1] === 'asc'
                              ? (a[order[0]] > b[order[0]] ? -1 : 1)
                              : (a[order[0]] < b[order[0]] ? -1 : 1)
                          ))
                          .slice(offset, offset + nbItemsPerPage)
                          .map(document => (
                            <Tr key={document.id}>
                              <Td>
                                <Checkbox
                                  checked={document.selected || false}
                                  onChange={selected => selectDocument(document.id, selected)}
                                />
                              </Td>
                              <TdEyeIcon>
                                <a
                                  href={'/api/documents/' + document.id + '/preview'}
                                  target='_blank'
                                >
                                  <RoundedEyeIcon />
                                </a>
                              </TdEyeIcon>
                              <TdForm value={document.name} onSubmit={value => handleNameUpdate(document.id, value)} />
                              <LTd empty={!document.project}>
                                {
                                  !!document.project
                                    ? <Link to={`/manage/projects?id=${document.project.id}`}>
                                      {document.project.name}
                                    </Link>
                                    : 'Aucun'
                                }
                              </LTd>
                              <Td>{document.ext}</Td>
                              <Td>{strftime('%d/%m/%Y', new Date(document.createdAt))}</Td>
                              <Td>{strftime('%d/%m/%Y', new Date(document.updatedAt))}</Td>
                              <Td>{Math.round(document.score)}%</Td>
                              <Th>
                                <DeleteButton onClick={() => handleClickDelete(document)}>
                                  <CloseCircleFilledIcon />
                                </DeleteButton>
                              </Th>
                            </Tr>
                          )
                          )
                      }
                    </TBody>
                  </StyledTable>
                  {
                    !documents.length &&
                    <EmptyHelp>
                      <P>
                        Il semble que vous n'ayez aucun document.<br />
                        Vous pouvez importer vos documents <Link to="/import">ici</Link>.
                      </P>
                    </EmptyHelp>
                  }
                </>
              )
          }
          <Pagination
            nbItemsPerPage={nbItemsPerPage}
            nbPagesDisplayed={5}
            activePageIndex={activePageIndex}
            totalItems={_documents.length}
            onChange={i => setActivePageIndex(i)}
          />
        </StyledSection>
      </StyledPage>
      {
        _selections.length > 0 &&
        <StyledEditor ref={ref}>
          <CloseButton onClick={() => selectDocuments(false)}><CrossIcon /></CloseButton>
          <StyledH4>{_selections.length} Documents sélectionnés</StyledH4>
          <StyledRow>
            <StyledColumn>
              <Form>
                <StyledH5>Déplacer vers :</StyledH5>
                <Select placeholder="Déplacer vers le projet..." dropup onChange={handleChangeProjectSelection}>
                  {projects.map(({ id, name }) =>
                    <Option
                      key={`project-${id}`}
                      value={id}
                      selected={_selections.every(doc => doc.project && doc.project.id === id)}
                    >
                      {name}
                    </Option>
                  )}
                </Select>
              </Form>
            </StyledColumn>
            <Vr />
            <StyledColumn>
              <Button leftIcon={<TrashIcon />} danger onClick={handleClickDeleteSelection}>
                Supprimer
              </Button>
            </StyledColumn>
          </StyledRow>
        </StyledEditor>
      }
      {
        toDeleteIds.length > 0 &&
        <ConfirmationModal isOpen danger
          title="Êtes-vous sûr de vouloir faire ça ?"
          description="Supprimer un document. Cette action est irreversible."
          onConfirm={() => handleConfirmDelete(toDeleteIds)}
          onCancel={() => handleCancelDelete()}
        />
      }
    </>
  )
}