import { fetchAllWords } from '../../services';
import { getTemplate } from '../../services/template';

export const OCREditorServices = {
    getAutoProfilNodeDetection: async (profile, canvas, setSelectedProfilesNodes, setCanvasLoading) => {
        let result = [];

        /* -------- REGEX -------- */
        result = [...result, ...gettAllDetectionsMatchWithNodesRegex(
            profile.nodes.filter(node => node.regex && !node.template && (!node.dictionaries || node.dictionaries.length === 0)),
            canvas.getObjects().filter(objectParam => {
                return objectParam.get("type") === 'rect'
            }),
            profile
        )]
        /* -------- REGEX -------- */

        /* -------- TEMPLATE -------- */
        let allDetectionInEachZone = []
        await Promise.all(
            profile.nodes
                .filter(node => node.template)
                .map(nodeTemplate => getTemplate(nodeTemplate.template))
        ).then(async response => {

            let zoneList = []
            response.forEach(template => {
                zoneList = [...zoneList, ...template.zones]
            })
            profile.nodes = profile.nodes.map(node => {
                return { ...node, zone: node.zone && !node.zone.id ? zoneList.find(zoneItem => zoneItem.id === node.zone) : node.zone }
            })

            canvas.getObjects().filter(objectParam => {
                return objectParam.get("type") === 'rect'
            }).map(detection => {
                profile.nodes
                    .filter(node => node.template).map(nodeWithTemplate => {
                        const objectNode = new fabric.Rect({
                            top: nodeWithTemplate.zone.y * canvas.backgroundImage.height,
                            left: nodeWithTemplate.zone.x * canvas.backgroundImage.width,
                            width: nodeWithTemplate.zone.width * canvas.backgroundImage.width,
                            height: nodeWithTemplate.zone.height * canvas.backgroundImage.height,
                            stroke: '#0f0',
                            _stroke: '#0f0',
                            strokeWidth: 2,
                            borderScaleFactor: 5,
                            strokeUniform: true,
                            noScaleCache: false,
                            strokeUniform: true,
                            fill: 'transparent',
                            lockUniScaling: false,
                        })
                        canvas.add(objectNode);
                        if (detection.isContainedWithinObject(objectNode)) {
                            allDetectionInEachZone.push({ detection: detection.detection, node: nodeWithTemplate })
                        }
                        canvas.remove(objectNode);

                    })
            })
            allDetectionInEachZone.forEach(detectionInEachZone => {
                if (detectionInEachZone.node.regex) {
                    const regex = new RegExp(detectionInEachZone.node.regex)
                    if (regex.test(detectionInEachZone.detection.value || detectionInEachZone.detection.fuseDictionaryValue)) {
                        result.push({
                            detection: detectionInEachZone.detection,
                            node: detectionInEachZone.node,
                            profile
                        })
                    }
                }
                if (detectionInEachZone.node.dictionaries) {
                    let allDetectionDictionnaryToTest = [detectionInEachZone]
                    let allDictionnary = {};
                    let allDictionnaryId = detectionInEachZone.node.dictionaries;

                    allDictionnaryId.map(DictionnaryId => fetchAllWords({ dictionary: DictionnaryId }).then((response) => {
                        allDictionnaryId.map((dictionnaryId, index) => {
                            allDictionnary[dictionnaryId] = response[index];
                        })
                        let profileNodesWithTemplateAndDictionary = profile.nodes.filter(node => node.template && node.dictionaries)
                        profileNodesWithTemplateAndDictionary.map(profileNodesWithTemplateAndDictionaryItem => {
                            allDetectionDictionnaryToTest.map((detectionDictionnaryToTest, index) => {
                                if (profileNodesWithTemplateAndDictionaryItem
                                    .dictionaries
                                    .find(
                                        dictionnary => detectionDictionnaryToTest.detection.fromDictionary === dictionnary
                                    )) {
                                    if (profileNodesWithTemplateAndDictionaryItem.regex) {
                                        if (new RegExp(profileNodesWithTemplateAndDictionaryItem.regex).test(detectionDictionnaryToTest.detection.value || detectionDictionnaryToTest.detection.fuseDictionaryValue)) {
                                            if (!result.find(resultItem => resultItem.profile.id === profile.id && resultItem.node.id === profileNodesWithTemplateAndDictionaryItem.id && resultItem.detection.id === detectionDictionnaryToTest.detection.id)) {
                                                result.push({
                                                    profile: profile,
                                                    node: profileNodesWithTemplateAndDictionaryItem,
                                                    detection: detectionDictionnaryToTest.detection,
                                                })
                                            }
                                        }
                                    } else {
                                        if (!result.find(resultItem => {
                                            return resultItem.profile.id === profile.id && resultItem.node.id === profileNodesWithTemplateAndDictionaryItem.id && resultItem.detection.id === detectionDictionnaryToTest.detection.id
                                        })) {
                                            result.push({
                                                profile: profile,
                                                node: profileNodesWithTemplateAndDictionaryItem,
                                                detection: detectionDictionnaryToTest.detection,
                                            })
                                        }
                                    }
                                }
                            })
                        })



                    }))
                }
                if (!detectionInEachZone.node.regex && detectionInEachZone.node.dictionaries) {
                    result.push({
                        detection: detectionInEachZone.detection,
                        node: detectionInEachZone.node,
                        profile
                    })
                }
            })
        })
        /* -------- TEMPLATE -------- */

        /* -------- DICTIONNARIES -------- */
        let allDetectionDictionnaryToTest = []
        let allDictionnary = {};
        let allDictionnaryId = [];

        profile.nodes.filter(node => !node.template && node.dictionaries?.length > 0).map(node => {
            allDictionnaryId = [...allDictionnaryId, ...node.dictionaries]
        })
        await Promise.all(
            allDictionnaryId.map(DictionnaryId => fetchAllWords({ dictionary: DictionnaryId }))
        ).then((response) => {
            allDictionnaryId.map((dictionnaryId, index) => {
                allDictionnary[dictionnaryId] = response[index];
            })
            allDetectionDictionnaryToTest = canvas.getObjects().filter(objectParam => {
                return objectParam.get("type") === 'rect' && objectParam.detection && objectParam.detection.fromDictionary
            })
            let profileNodesWithoutTemplateAndRegex = profile.nodes.filter(node => !node.template && node.dictionaries?.length > 0)

            profileNodesWithoutTemplateAndRegex.map(profileNodesWithoutTemplateAndRegexItem => {
                allDetectionDictionnaryToTest.map((detectionDictionnaryToTest) => {
                    if (profileNodesWithoutTemplateAndRegexItem
                        .dictionaries
                        .find(
                            dictionnary => detectionDictionnaryToTest.detection.fromDictionary === dictionnary
                        )) {

                        if (profileNodesWithoutTemplateAndRegexItem.regex) {
                            if (new RegExp(profileNodesWithoutTemplateAndRegexItem.regex).test(detectionDictionnaryToTest.detection.fuseDictionaryValue)) {
                                if (!result.find(resultItem => resultItem.profile.id === profile.id && resultItem.node.id === profileNodesWithoutTemplateAndRegexItem.id && resultItem.detection.id === detectionDictionnaryToTest.detection.id)) {
                                    result.push({
                                        profile: profile,
                                        node: profileNodesWithoutTemplateAndRegexItem,
                                        detection: detectionDictionnaryToTest.detection,
                                    })
                                }
                            }
                        } else {

                            if (!result.find(resultItem => {
                                return resultItem.profile.id === profile.id
                                    && resultItem.node.id === profileNodesWithoutTemplateAndRegexItem.id
                                    && resultItem.detection.id === detectionDictionnaryToTest.detection.id
                            })) {

                                result.push({
                                    profile: profile,
                                    node: profileNodesWithoutTemplateAndRegexItem,
                                    detection: detectionDictionnaryToTest.detection,
                                })
                            }
                        }
                    }
                })
            })
            setSelectedProfilesNodes(result.map((detectionNode, index) => {
                return {
                    profileId: profile.id,
                    nodeId: detectionNode.node.id,
                    value: detectionNode.detection.value?.length > 0 && detectionNode.detection.value !== " " ? detectionNode.detection.value : detectionNode.detection.fuseDictionaryValue,
                    id: detectionNode.detection.id,
                    index
                }
            }));
            setCanvasLoading(false);
        })

        /* -------- DICTIONNARIES -------- */
    }
}

function gettAllDetectionsMatchWithNodesRegex(nodesWithRegex, detections, profile) {
    const result = [];
    nodesWithRegex.map(node => {
        const regex = new RegExp(node.regex)
        detections.map(detection => {
            const detectionParam = { ...detection.detection };
            if (detectionParam.value?.trim().length > 0) {
                if (typeof detectionParam.value === 'string' && detectionParam.value != '' && regex.test(detectionParam.value)) {
                    result.push({ detection: detectionParam, node, profile })
                }
            } else {
                if (typeof detectionParam.fuseDictionaryValue === 'string' && detectionParam.fuseDictionaryValue != '' && regex.test(detectionParam.fuseDictionaryValue)) {
                    result.push({ detection: detectionParam, node, profile })
                }
            }

        })

    })
    return result;
}
