import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { theme as DesignSystemTheme } from './components/design-system/Theme'

const theme = {
  ...DesignSystemTheme,
  primaryColor: '#92de42',
  primaryHover: '#7dc82e',
  dangerColor: '#f61a1a',
  gray2: '#e5e5e5',
  gray3: '#999999',
  gray4: '#595959',
  blue5: '#50c5f3',
  blue4: '#7ccef4',
  blue3: '#b4e3f9',
  blue2: '#ecf8fd',
  navbar: {
    ...DesignSystemTheme.navbar,
    link: {
      ...DesignSystemTheme.navbar.link,
      activeColor: '#92de42',
      hoverColor: '#92de42',
      color: '#ffffff'
    }
  },
  statusColors: {
    todo: '#000000',
    doing1: '#ff9f00',
    doing2: '#50C5F3',
    done: '#92de42',
    error: '#f61a1a'
  },
  scoreColors: {
    _4: '#0f0',    // = 100
    _3: '#50c5f3', // < 75
    _2: '#ff9f00', // < 50
    _1: '#e33131', // < 25
    _0: '#e33131'
  },
  scoreColor: function (value) {
    return this.scoreColors[`_${Math.round(value * 100 / 25)}`]
  }
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Blinker;
    font-weight: 300;
    src: url("/fonts/blinker/Blinker-Light.ttf");
  }
  @font-face {
    font-family: Blinker;
    font-weight: 500;
    src: url("/fonts/blinker/Blinker-SemiBold.ttf");
  }
  @font-face {
    font-family: Blinker;
    font-weight: 700;
    src: url("/fonts/blinker/Blinker-Bold.ttf");
  }
  @font-face {
    font-family: Arimo;
    font-weight: 400;
    src: url("/fonts/arimo/Arimo-Regular.ttf");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 400;
    src: url("/fonts/roboto/Roboto-Regular.ttf");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 700;
    src: url("/fonts/roboto/Roboto-Bold.ttf");
  }
  @font-face {
    font-family: Saira;
    font-weight: 400;
    src: url("/fonts/saira/Saira-Regular.ttf");
  }
  @font-face {
    font-family: Saira;
    font-weight: 900;
    src: url("/fonts/saira/Saira-ExtraBold.ttf");
  }
  :root, body {
    height: 100%;
    font-size: 16px;
  }
  body {
    line-height: 1.6;
    font-weight: normal;
    font-family: Roboto, "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #000;
  }
  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export {
  theme,
  ThemeProvider,
  GlobalStyle
}
