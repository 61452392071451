import axios from 'axios';

export const createTemplate = body => { return axios.post(`/api/templates`, body).then(({ data }) => data) }

export const getTemplatesList = body => { return axios.get(`/api/templates`, body).then(({ data }) => data) };

export const getTemplate = id => { return axios.get(`/api/templates/${id}`).then(({ data }) => data) }

export const updateTemplate = (id, body) => { return axios.put(`/api/templates/${id}`, body).then(({ data }) => data) }

export const deleteTemplate = id => { return axios.delete(`/api/templates/${id}`).then(({ data }) => data) }
