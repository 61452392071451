import React, { useEffect } from 'react';
import styled from 'styled-components'
import {
    SpinnerAnimatedIcon,
    Option,
    Select
} from '../../components'
import { TemplateCanvasServices } from './TemplateCanvasServices';

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TemplateCanvas = ({ updateTemplateRequest, currentTemplateView, currentTemplate, setCurrentPage, currentPage, currentZone, setCurrentZone }) => {

    const refElementParentCanvas = React.createRef();
    const [loading, setLoading] = React.useState(false);
    const [canvas, setCanvas] = React.useState(null);
    const [imgId, setImgId] = React.useState();

    useEffect(() => {
        setLoading(true);
        let currentCanvas;

        if (!canvas) {
            const canvasCreated = TemplateCanvasServices.initCanvas(
                refElementParentCanvas.current.offsetWidth,
                refElementParentCanvas.current.offsetHeight,
            );
            currentCanvas = canvasCreated;
        } else {
            currentCanvas = canvas;
        }

        currentCanvas.on('mouse:wheel', (e) => {
            TemplateCanvasServices.handleMouseWheel(e, currentCanvas)
        })
        if (currentTemplate) {
            currentCanvas.on('mouse:down', (e) => {
                if (currentTemplate) {
                    TemplateCanvasServices.handleMouseDown(e, currentCanvas, setCurrentZone, !!currentTemplate)
                } else {
                    e.preventDefault();
                    e.stopPropagation();
                }
            })
            currentCanvas.on('object:moved', (e) => {
                TemplateCanvasServices.objectMoved(e, currentTemplateView, updateTemplateRequest, currentCanvas)
            })
            currentCanvas.on('object:scaled', (e) => {
                TemplateCanvasServices.objectSclaed(e, currentTemplateView, updateTemplateRequest, currentCanvas)
            })
        }

        if (currentTemplateView && currentTemplateView.document && !currentTemplate && canvas.backgroundImage) {
            currentCanvas.discardActiveObject().remove(...currentCanvas.getObjects().filter((object => object.zone?.id)));
            TemplateCanvasServices.DrawZones(currentTemplateView, currentCanvas, currentPage, currentTemplate);
            currentCanvas.renderAll();
        }

        if (currentTemplateView && currentTemplateView.document) {
            const currentPageToDisplay = currentTemplateView.document.pages[currentPage || 0]
            if (currentPageToDisplay.id + currentTemplateView.id != imgId) {
                currentCanvas.remove(...currentCanvas.getObjects());
                currentCanvas.setBackgroundImage(null);
                currentCanvas.discardActiveObject().renderAll();
                TemplateCanvasServices.setDocument(
                    currentPageToDisplay,
                    currentCanvas,
                    () => {
                        setImgId(currentPageToDisplay.id + currentTemplateView.id)
                        TemplateCanvasServices.DrawZones(currentTemplateView, currentCanvas, currentPage, currentTemplate);
                        currentCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
                        currentCanvas.discardActiveObject().renderAll();
                    }
                )
            } else {
                TemplateCanvasServices.DrawZones(currentTemplateView, currentCanvas, currentPage, currentTemplate);
            }
        } else {
            setCurrentPage(null)
            setCurrentZone(null)
            setImgId(null)
            currentCanvas.discardActiveObject()
            currentCanvas.remove(...currentCanvas.getObjects());
            currentCanvas.setBackgroundImage(null);
            currentCanvas.renderAll();
        }

        if (currentZone) {
            currentCanvas.getObjects().map(object => {
                if (object.zone &&
                    object.zone.id === currentZone.id &&
                    (currentCanvas.getActiveObject()?.zone.id != currentZone.id)
                ) {
                    currentCanvas.setActiveObject(object)
                    if (!object.isOnScreen()) {
                        const vpw = currentCanvas.width / 1
                        const vph = currentCanvas.height / 1
                        const x = (object.left - vpw / 2)
                        const y = (object.top - vph / 2)
                        currentCanvas.absolutePan({ x: x, y: y })
                    }
                }
            })
        }

        if (!currentTemplate) {
            setCurrentZone(null)
            currentCanvas.discardActiveObject()
        }

        currentCanvas.renderAll();
        setLoading(false);
        setCanvas(currentCanvas);
        return () => {
            currentCanvas.__eventListeners['mouse:wheel'] && currentCanvas.off('mouse:wheel', currentCanvas.__eventListeners['mouse:wheel'][0])
            currentCanvas.__eventListeners['mouse:down'] && currentCanvas.off('mouse:down', currentCanvas.__eventListeners['mouse:down'][0])
            currentCanvas.__eventListeners['mouse:moved'] && currentCanvas.off('object:moved', currentCanvas.__eventListeners['mouse:moved'][0])
            currentCanvas.__eventListeners['mouse:scaled'] && currentCanvas.off('object:scaled', currentCanvas.__eventListeners['mouse:scaled'][0])
            currentCanvas.__eventListeners = {}
        }

    }, [currentTemplateView, currentPage, currentTemplate, currentZone])

    return (<>
        {!currentTemplateView && <div style={{ margin: 'auto' }}>{"Pas de template sélectionné"}</div>}
        <div style={{
            display: 'flex',
            'textAlign': 'center',
            visibility: `${currentTemplateView ? 'inherit' : 'hidden'}`,
            padding: '0.5rem',
            backgroundColor: 'black',
            color: 'white'
        }}>
            <div style={{ margin: 'auto' }}>
                {
                    loading && <StyledLoading style={{
                        display: 'inline-block'
                    }}>
                        <SpinnerAnimatedIcon />&nbsp;
                        Chargement ...
                    </StyledLoading>
                }
                {currentTemplateView && !currentTemplateView.document && "Aucun document"}
                {
                    currentTemplateView && currentTemplateView.document && <>
                        {
                            !loading && <div style={{
                                display: 'inline-block'
                            }}>
                                Page :{' '}
                                {
                                    currentTemplateView.document.pages.length === 1 ? <>1</> : <>
                                        <Select
                                            onChange={(value) => {
                                                setCurrentPage(value - 1)
                                            }}
                                        >
                                            {
                                                currentTemplateView.document.pages
                                                    .map((page, index) => {
                                                        return <Option
                                                            key={'key-' + page.id}
                                                            value={(index + 1).toString()}
                                                            selected={index === currentPage}
                                                        >
                                                            {index + 1}
                                                        </Option>
                                                    })
                                            }
                                        </Select>
                                    </>
                                }
                            </div>
                        }
                    </>
                }
            </div>
        </div>
        <div ref={refElementParentCanvas} sx={{ position: "relative" }} style={{ 'width': '99%', 'height': '99%' }}>
            <canvas id="canvas" />
        </div>
    </>
    );
}

export default TemplateCanvas;