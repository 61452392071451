import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  H4,
  Select,
  Option,
  BlankButton,
  Button,
  Icon,
  CrossIcon,
  CloseCircleFilledIcon,
  ArrowRightIcon,
  AngleDownIcon,
  AngleUpIcon,
  EyeIcon,
  SpinnerAnimatedIcon,
} from '../../components'
import { OCREditorServices } from './OCREditorServices'
import { fetchAllWords } from '../../services'

const ExportContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ButtonContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: end;
  display: flex;
`

const ButtonHolder = styled.div`
  justify-content: flex-end;
  display: flex;
`

const SubmitButton = styled(Button)`
  display: block;
  margin: 0 auto 2rem;
`

const StyledEditor = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  width: 350px;
  height: 100%;
  padding: 2rem;
  box-shadow: 0px 0 10px 0px rgba(170,170,170,1);
  background-color: ${({ theme }) => theme.white};
  overflow: scroll;

  ${({ active }) => !active && `
    display: none;
  `}

  ${({ orientation }) => `
    top: ${orientation === 'vertical' ? '0' : '3rem'};
    right: 0;
  `}
`
const CloseButton = styled(BlankButton)`
  position: absolute;
  top: .75rem;
  right: .75rem;
  color: ${({ theme }) => theme.primaryColor};

  ${Icon} {
    width: .75rem;
    height: .75rem;
  }
`

const StyledH4 = styled(H4)`
  margin: 1rem 0;
`

const ProfilItem = styled.div`
  display: flex;
`

const ProfileNode = styled.div`
  margin: 1rem 0;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  border: 1px solid black;
`

const ProfilValue = styled.div`
  flex: 1;
`

const RemoveButton = styled(BlankButton)`
  margin-left: .5rem;
  color: red;
  align-self: center;

  ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const OptionButton = styled(BlankButton)`
  margin-left: .5rem;
  color: ${({ theme }) => theme.primaryColor};
  align-self: center;

  ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const ProfileNodeList = ({
  canvas,
  setSelectedProfilesNodes,
  selectedProfilesNodes,
  deleteProfilNodeDetection,
  setDeleteProfilNodeDetection,
  selectedDetection,
  setSelectedDetection,
  selectedDetectionHover,
  updateIndexUp,
  updateIndexDown,
  sortByIndex,
  profile,
  node
}) => {

  const [dictionariesSynonyms, setDictionariesSynonyms] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const replaceDictionariesSynonyms = [];
    node.dictionaries?.map(dictionary => {
      node.replaceDictionariesSynonyms.map(DictionarySynonyms => {
        if (DictionarySynonyms === dictionary) {
          replaceDictionariesSynonyms.push(dictionary)
        }
      })
    })
    if (replaceDictionariesSynonyms) {
      const aggregateDictionariesSynonyms = dictionariesSynonyms || {};
      Promise.all(
        replaceDictionariesSynonyms.map(DictionnaryId => fetchAllWords({ dictionary: DictionnaryId }))
      ).then((response) => {
        replaceDictionariesSynonyms.map((dictionnaryId, index) => {
          aggregateDictionariesSynonyms[dictionnaryId] = response[index];
        })
        setDictionariesSynonyms(aggregateDictionariesSynonyms);
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [])



  return <>
    <ProfileNode>
      {node.name}
    </ProfileNode>

    {
      loading ? <SpinnerAnimatedIcon style={{ margin: 'auto' }} /> : <ProfileNodeDetectionList
        sortByIndex={sortByIndex}
        canvas={canvas}
        setSelectedProfilesNodes={setSelectedProfilesNodes}
        selectedProfilesNodes={selectedProfilesNodes}
        deleteProfilNodeDetection={deleteProfilNodeDetection}
        setDeleteProfilNodeDetection={setDeleteProfilNodeDetection}
        selectedDetection={selectedDetection}
        setSelectedDetection={setSelectedDetection}
        selectedDetectionHover={selectedDetectionHover}
        updateIndexUp={updateIndexUp}
        updateIndexDown={updateIndexDown}
        profile={profile}
        node={node}
        dictionariesSynonyms={dictionariesSynonyms}
      />
    }


  </>
}

const ProfileNodeDetectionList = ({
  canvas,
  setSelectedProfilesNodes,
  selectedProfilesNodes,
  deleteProfilNodeDetection,
  setDeleteProfilNodeDetection,
  selectedDetection,
  setSelectedDetection,
  selectedDetectionHover,
  updateIndexUp,
  updateIndexDown,
  sortByIndex,
  profile,
  node,
  dictionariesSynonyms
}) => {
  return <>{
    selectedProfilesNodes?.sort(sortByIndex).map(
      (selectedProfilesNode) => (
        selectedProfilesNode.profileId === profile.id &&
        selectedProfilesNode.nodeId === node.id && (
          <ProfilItem
            key={
              'profil-' + profile.id +
              '-node-' + node.id +
              '-detection-' + selectedProfilesNode.id
            }
          >
            <EyeIcon style={{
              margin: "auto",
              marginRight: "1rem",
              cursor: "pointer"
            }} onClick={() => {
              let bojectToSelect = canvas.getObjects().find(object => object.detection.id === selectedProfilesNode.id && object.get("type") === 'rect');
              if (bojectToSelect.detection) { setSelectedDetection([bojectToSelect.detection]) }
              canvas.setActiveObject(bojectToSelect)
              canvas.renderAll()
            }} />
            <ProfilValue>
              <ProfileNodeDetectionValue selectedDetection={selectedDetection}
                selectedProfilesNode={selectedProfilesNode}
                selectedDetectionHover={selectedDetectionHover} dictionariesSynonyms={dictionariesSynonyms} />
            </ProfilValue>
            <OptionButton onClick={() => updateIndexUp(setSelectedProfilesNodes, selectedProfilesNodes, node.id, selectedProfilesNode.id)}>
              <AngleUpIcon />
            </OptionButton>
            <OptionButton onClick={() => updateIndexDown(setSelectedProfilesNodes, selectedProfilesNodes, node.id, selectedProfilesNode.id)}>
              <AngleDownIcon />
            </OptionButton>
            <RemoveButton
              danger
              onClick={() => {
                setDeleteProfilNodeDetection([...deleteProfilNodeDetection, { detectionId: selectedProfilesNode.id, nodeId: node.id }]);
                setSelectedProfilesNodes([
                  ...selectedProfilesNodes.filter(
                    ({ profileId, nodeId, id }) => !(
                      profileId === profile.id &&
                      nodeId === node.id &&
                      id === selectedProfilesNode.id
                    )
                  )
                ])
              }}
            >
              <CloseCircleFilledIcon />
            </RemoveButton>
          </ProfilItem>
        )
      )
    )
  }</>
}

const ProfileNodeDetectionValue = ({
  selectedDetection,
  selectedProfilesNode,
  selectedDetectionHover,
  dictionariesSynonyms
}) => {
  const getValue = (value, dictionariesSynonyms) => {
    let result = null;
    const dictionnarySynonyms = Object.values(dictionariesSynonyms);
    if (dictionnarySynonyms.length > 0) {
      let valueToReplace = [];
      dictionnarySynonyms.map(dictionarySynonyms => {
        let dictionarySynonymsResult = dictionarySynonyms.words.filter(dictionarySynonymsWord => {
          return dictionarySynonymsWord.value === value && dictionarySynonymsWord.isSynonymOf
        })
        if (dictionarySynonymsResult) {
          valueToReplace.push(dictionarySynonyms.words.find(dictionarySynonymsWord => {
            return dictionarySynonymsWord.id === dictionarySynonymsResult[0]?.isSynonymOf
          }))
        }
        valueToReplace = valueToReplace.filter(function (element) {
          return element !== undefined;
        });
        result = valueToReplace[0]?.value
      })
    }
    return result ? result : value
  }

  const valueToDisplay = getValue(selectedProfilesNode.value, dictionariesSynonyms);

  return <>{
    (selectedDetection && Array.isArray(selectedDetection) ? selectedDetection : [selectedDetection]).find(selectedDetectionItem => selectedDetectionItem?.id === selectedProfilesNode?.id)
      || selectedDetectionHover?.id === selectedProfilesNode.id ?
      <b>{valueToDisplay}</b>
      : valueToDisplay
  }</>
}

const OCREditor = ({
  canvas,
  active,
  orientation,
  profiles,
  onClickClose,
  onProfileNodeChange,
  onSetCurrentProfileId,
  currentProfileId,
  setSelectedProfilesNodes,
  selectedProfilesNodes,
  deleteProfilNodeDetection,
  setDeleteProfilNodeDetection,
  setCanvasLoading,
  selectedDetection,
  setSelectedDetection,
  selectedDetectionHover,
}) => {

  const updateIndexUp = (setSelectedProfilesNodes, selectedProfilesNodes, nodeId, selectedProfilesNodeId) => {
    let updateSelectedProfilesNodes = [];
    let indexToUpdate = selectedProfilesNodes
      .find(selectedProfilesNodesItem =>
        selectedProfilesNodesItem.id === selectedProfilesNodeId
        && selectedProfilesNodesItem.nodeId === nodeId
      ).index

    updateSelectedProfilesNodes = selectedProfilesNodes.filter(selectedProfilesNodesItem => {
      return selectedProfilesNodesItem.nodeId === nodeId;
    }).sort(sortByIndex).map((selectedProfilesNodesItem, index) => {
      return {
        ...selectedProfilesNodesItem,
        index: index
      }
    })

    updateSelectedProfilesNodes = [...updateSelectedProfilesNodes, ...selectedProfilesNodes.filter(selectedProfilesNodesItem => {
      return selectedProfilesNodesItem.nodeId != nodeId;
    })]

    if (indexToUpdate > 0) {
      updateSelectedProfilesNodes = updateSelectedProfilesNodes.map((selectedProfilesNodes) => {
        if (selectedProfilesNodes.nodeId === nodeId && selectedProfilesNodeId != selectedProfilesNodes.id) {
          return { ...selectedProfilesNodes, index: selectedProfilesNodes.index + 1 };
        } else if (selectedProfilesNodes.nodeId === nodeId && selectedProfilesNodeId === selectedProfilesNodes.id) {
          return { ...selectedProfilesNodes, index: selectedProfilesNodes.index - 1 };
        } else {
          return selectedProfilesNodes;
        }
      })
      setSelectedProfilesNodes(updateSelectedProfilesNodes)
    }
  }

  const updateIndexDown = (setSelectedProfilesNodes, selectedProfilesNodes, nodeId, selectedProfilesNodeId) => {
    let updateSelectedProfilesNodes = [];
    let indexToUpdate = selectedProfilesNodes
      .find(selectedProfilesNodesItem =>
        selectedProfilesNodesItem.id === selectedProfilesNodeId
        && selectedProfilesNodesItem.nodeId === nodeId
      ).index

    updateSelectedProfilesNodes = selectedProfilesNodes.filter(selectedProfilesNodesItem => {
      return selectedProfilesNodesItem.nodeId === nodeId;
    }).sort(sortByIndex).map((selectedProfilesNodesItem, index) => {
      return {
        ...selectedProfilesNodesItem,
        index: index
      }
    })

    updateSelectedProfilesNodes = [...updateSelectedProfilesNodes, ...selectedProfilesNodes.filter(selectedProfilesNodesItem => {
      return selectedProfilesNodesItem.nodeId != nodeId;
    })]

    if (indexToUpdate < selectedProfilesNodes.filter(selectedProfilesNodesItem => {
      return selectedProfilesNodesItem.nodeId === nodeId;
    }).length) {
      updateSelectedProfilesNodes = updateSelectedProfilesNodes.map((selectedProfilesNodes) => {
        if (selectedProfilesNodes.nodeId === nodeId && selectedProfilesNodeId != selectedProfilesNodes.id) {
          return { ...selectedProfilesNodes, index: selectedProfilesNodes.index - 1 };
        } else if (selectedProfilesNodes.nodeId === nodeId && selectedProfilesNodeId === selectedProfilesNodes.id) {
          return { ...selectedProfilesNodes, index: selectedProfilesNodes.index + 1 };
        } else {
          return selectedProfilesNodes;
        }
      })
      setSelectedProfilesNodes(updateSelectedProfilesNodes)
    }
  }

  const sortByIndex = (a, b) => {
    return a.index - b.index;
  }

  return (
    <StyledEditor
      active={active}
      orientation={orientation}
    >
      <CloseButton onClick={onClickClose}><CrossIcon /></CloseButton>
      <ExportContainer>
        <StyledH4>Profil d'export: </StyledH4>
        <Select
          placeholder="Sélectionner un profil d'export"
          onChange={onSetCurrentProfileId}
        >
          {
            profiles.map((profile, i) => (
              <Option
                key={'profile-' + i}
                value={profile.id}
                selected={currentProfileId ? currentProfileId === profile.id : selectedProfilesNodes[0]?.profileId === profile.id}
              >
                {profile.name}
              </Option>
            ))
          }
        </Select>
        {
          profiles
            .filter(profile => profile.id === currentProfileId)
            .map(profile => (
              profile.nodes.map(
                node => {
                  return (
                    selectedProfilesNodes.find(selectedProfilesNodeItem => {
                      return selectedProfilesNodeItem.profileId === profile.id && selectedProfilesNodeItem.nodeId === node.id
                    }) && <ProfileNodeList
                      key={
                        'profil-' + profile.id +
                        '-node-' + node.id
                      }
                      sortByIndex={sortByIndex}
                      canvas={canvas}
                      setSelectedProfilesNodes={setSelectedProfilesNodes}
                      selectedProfilesNodes={selectedProfilesNodes}
                      deleteProfilNodeDetection={deleteProfilNodeDetection}
                      setDeleteProfilNodeDetection={setDeleteProfilNodeDetection}
                      selectedDetection={selectedDetection}
                      setSelectedDetection={setSelectedDetection}
                      selectedDetectionHover={selectedDetectionHover}
                      updateIndexUp={updateIndexUp}
                      updateIndexDown={updateIndexDown}
                      profile={profile}
                      node={node}
                    />
                  )
                }
              )
            ))
        }
        <ButtonContainer>
          <ButtonHolder>
            {
              (
                selectedProfilesNodes
                  .filter(selectedProfilesNode => selectedProfilesNode.profileId === currentProfileId)
                  .length != 0
                || deleteProfilNodeDetection.length != 0
              )
              && <SubmitButton
                primary
                leftIcon={<ArrowRightIcon />}
                onClick={() => {
                  onProfileNodeChange(
                    selectedProfilesNodes,
                    deleteProfilNodeDetection)
                }
                }
              >
                Valider
              </SubmitButton>}
            {
              selectedProfilesNodes
                .filter(selectedProfilesNode => selectedProfilesNode.profileId === currentProfileId)
                .length === 0
              && deleteProfilNodeDetection.length === 0
              && profiles
                .find(profile => profile.id === currentProfileId)
              && <Button
                primary
                leftIcon={<ArrowRightIcon />}
                onClick={() => {
                  setCanvasLoading(true)
                  OCREditorServices.getAutoProfilNodeDetection(profiles
                    .find(profile => profile.id === currentProfileId), canvas, setSelectedProfilesNodes, setCanvasLoading)
                }}
              >
                Remplissage automatique
              </Button>}
          </ButtonHolder>
        </ButtonContainer>
      </ExportContainer>
    </StyledEditor >
  )
}

OCREditor.propTypes = {
  active: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  onProfileNodeChange: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
}

export default OCREditor
